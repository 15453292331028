class MultiAccountManager {

    constructor() {
        this.url = "/multiaccount/ajax.php"; // the url of all actions
        this.formId = ""; // a form id; the content of the form will be send to the controller
        this.resultId = ""; // a div that holds a result information, in case of error for example
        this.displayId = ""; // a div that holds  dispaly information for a concrete action
        this.itemId = ""; // a div that holds the whole item
    }

    showCreateUserForm() {
        var self = this;
        var hasData = this.hasMultiAccountRequiredData();

        if (!hasData) {
            return false;
        }

        jQuery('#form-box-data').show();
        jQuery('#show-box-data').hide();
    }

    /**
     * Checks if the user has already a company data and a profile.
     */
    hasMultiAccountRequiredData() {
        var returnValue = true;

        jQuery.ajax({
            url: this.url,
            type: 'POST',
            data: "action=has_multiaccount_required_data",
            async: false,
            success: function (result) {
                var response = new AjaxResponse(result);
                if (response.error) {
                    returnValue = false;
                    modal.setType('string');
                    modal.setSource(response.html);
                    modal.setTitle('Daten erforderlich');
                    modal.show();
                }
            }
        });

        return returnValue;
    }

    /**
     * Updates the required data.
     * Needs formId.
     */
    updateMultiAccountRequiredData() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        jQuery.post(this.url, "action=update_multiaccount_required_data&" + form.serialize(), function (result) {
            var response = new AjaxResponse(result);
            if (response.error) {
                modal.setType('string');
                modal.setSource(response.html);
                modal.setTitle('Daten erforderlich');
                modal.show();
            } else {
                modal.show_message(response.html, 5000, 'success');
                window.setTimeout(function () {
                    modal.hide();
                    self.showCreateUserForm();
                }, 1000);
            }
        });
    }

    setFormId(formId) {
        this.formId = formId;
    }

    setResultId(resultId) {
        this.resultId = resultId;
    }

    setItemId(itemId) {
        this.itemId = itemId;
    }

    setDisplayId(displayId) {
        this.displayId = displayId;
    }

    /**
     * Adds a new multiaccount user to the database.
     * formId should be set.
     * @return void
     */
    createUser() {
        var self = this;

        jQuery.post('/multiaccount/ajax.php',
            'action=create_company_user&' + jQuery('#user_create_form').serialize(),
            function (result) {

                var j = result;

                if (j['error'] == 0) {
                    var timeToShowMessage = 5000; // 5 seconds
                    jQuery("#new_staff_member").html(j['html']);
                    modal.show_message('Daten erfolgreich gespeichert.', timeToShowMessage, 'success', null, null, 'self.refreshList();');
                    setTimeout(
                        function()
                        {
                            self.refreshList();
                        }, timeToShowMessage);
                } else {
                    // Show the error
                    jQuery("#new_staff_member").html(j['html']);
                    initBootstrapSelect();
                    flScrollTo('profile_personaldata');
                }
            }, 'json');
    }


    /**
     * Animate a some html.
     */
    animate(html) {
        var div = jQuery(html).first("div");
        if (div.length) {
            div.fadeOut();
            div.fadeIn();
            div.fadeOut();
            div.fadeIn();
        }
    }

    /**
     * Updates user;
     * Needs formId, resultId, displayId
     */
    updateUser() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        jQuery.post(this.url, "action=save_company_user&" + form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                jQuery("#" + self.resultId).hide();
                jQuery("#" + self.displayId).html(response.html);
                modal.show_message('Daten erfolgreich gespeichert.', '5000', 'success');
            }
        });
    }

    /**
     * Updates the rights (overrides) for a staffuser;
     * Needs formId, resultId, displayId
     */
    updateUserRights() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        // Is a confirmation of the set rights needed?
        jQuery.post(this.url, form.serialize() + "&check_for_conformation=1", function (result) {
            var response = new AjaxResponse(result);

            if (response.params["confirmation_needed"]) {
                if (confirm(response.params["confirmation_text"])) {
                    jQuery.post(this.url, form.serialize(), function (result) {
                        var response = new AjaxResponse(result);

                        jQuery("#" + self.resultId).hide();
                        jQuery("#" + self.displayId).html(response.html);
                    });
                }
            } else {
                jQuery.post(this.url, form.serialize(), function (result) {
                    var response = new AjaxResponse(result);

                    jQuery("#" + self.resultId).hide();
                    modal.show_message('Ihre Änderungen wurden erfolgreich gespeichert.', 5000, 'success');
                });
            }
        });
    }

    /**
     * Upgrades a user;
     * Needs formId, resultId
     */
    upgradeUser() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        jQuery.post(this.url, form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                modal.show_message('Lizenz erfolgreich zugewiesen. Dieser Recruiter ist ab sofort PREMIUM-Mitglied.', '5000', 'success');
                document.location.href = document.location.href;
            }
        });
    }

    /**
     * Shows a confirmation dialog before an user is going to be deleted.
     * Need formId and resultId
     */
    confirmDeleteUser() {
        var self = this;
        var form = jQuery("#" + this.formId);

        jQuery.post(this.url, "action=confirm_delete_user&" + form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                modal.setType('string');
                modal.setSource(response.html);
                modal.setTitle('Benutzer löschen');
                modal.show();
            }
        });
    }

    /**
     * Deletes an user.
     * Need formId and resultId
     */
    deleteUser() {
        var self = this;
        var form = jQuery("#" + this.formId);

        jQuery.post(this.url, "action=delete_user&" + form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                modal.hide();
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                var timeToShowMessage = 5000; // 5 seconds
                modal.show_message('Der Benutzer wurde erfolgreicht gelöscht.', timeToShowMessage, 'success', null, null, 'self.refreshList();');
                setTimeout(
                    function()
                    {
                        self.refreshList();
                    }, timeToShowMessage);
            }
        });
    }

    /**
     * Cancels the subscription of a user.
     * Needs formId, resultId
     */
    cancelUserSubscription() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        jQuery.post(this.url, form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                self.refreshList();
            }
        });
    }

    /**
     * Reverts a canceled subscription of a user.
     * Needs formId, resultId
     */
    revertUserSubscriptionCancelation() {
        var self = this;
        var form = jQuery("#" + this.formId);
        form.prop("disabled", true);

        jQuery.post(this.url, form.serialize(), function (result) {
            var response = new AjaxResponse(result);

            if (response.error) {
                jQuery("#" + self.resultId).show();
                jQuery("#" + self.resultId).html(response.html);
            } else {
                self.refreshList();
            }
        });
    }

    /**
     * Refreshes the list with staff members.
     */
    refreshList() {
        // refresh the page or the list
        document.location.href = document.location.href;
    }

    /**
     * Needs resultId
     */
    toggleSubscriptionLicenseCancellation(id) {
        var self = this;
        jQuery.post(this.url, "action=toggle_subscription_license&license_id=" + id, function (result) {
            var response = new AjaxResponse(result);

            jQuery("#" + self.resultId).show();
            jQuery("#" + self.resultId).html(response.html);
        });
    }

    /**
     * Toggles the visibility of the user in the companies profile.
     * Needs resultId
     */
    toggleUserVisibility(id) {
        var self = this;
        jQuery.post(this.url, "action=toggle_user_visibility&user_id=" + id, function (result) {
            var response = new AjaxResponse(result);

            jQuery("#" + self.resultId).show();
            jQuery("#" + self.resultId).html(response.html);
        });
    }

    resetFieldIds() {
        this.formId = "";
        this.displayId = "";
        this.itemId = "";
        this.resultId = "";
    }
}