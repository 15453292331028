function documents_form_cancel() {
    jQuery('#documents_list').show();
    jQuery('#documents_form-ajax').hide();

    return false;
}

function load_documents_list() {
    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: {
            action: 'load'
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_documents_ajax').html(json['result_html']);
        }
    });
}

function add_document() {
    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: {
            action: 'add'
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#documents_list').hide();
            jQuery('#documents_form-ajax').html(json['result_html']).show();
        }
    });
}

function upload_document() {
    var formData = new FormData(jQuery('#document_upload-form')[0]);

    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error_code'] === 1) {
                jQuery('#upload_form').html(json['result']['error_html']);
            } else {
                jQuery('#panel_documents_ajax').html(json['result']['documents_html']);
                jQuery('#header_personal_space_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_personal_space"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);

                if (json['message'] === "mark_as_cv_done") {
                    jQuery('#panel_cv_upload_form').html('');
                    var message = 'Ihr CV wird automatisch Ihrem "Persönlichen Portfolio" hinzugefügt. Damit Anbieter von Projekten Sie besser finden können, ist er für diese sichtbar geschaltet. Sie können die Einstellung zur Sichtbarkeit im "Persönlichen Portfolio" anpassen.';
                    freelancer_form_save('panel_documents', json['result']['profile_complete'], message);
                } else {
                    // freelancer_form_save('panel_documents', json['result']['profile_complete']);
                    modal.showFLPopup('request','/profile_freelancer/documents.php','action=choose_thumbnail&document_id='+json['result']['document_id']+'&message=Ihre Daten wurden erfolgreich gespeichert.','Vorschaubild ändern','');
                }
            }
        }
    });
}

function no_document() {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/documents.php',
        data: {
            action: 'no_document'
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_documents').collapse('hide');
            jQuery('#panel_documents_ajax').html(json['result_html']);
            
            freelancer_form_save('panel_documents', json['profile_complete']);
        }
    });
}

/**
 * saves an edited document
 */
function save_document(id) {
    var formData = new FormData(jQuery('#edit_form-document_' + id)[0]);

    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 0) {
                modal.show_message(json['result_message'], 5000, 'success');
                jQuery('#document_' + id).html(json['result_html']);
            } else if (json['error'] === 1) {
                jQuery('#document_' + id).html(json['result_html']);
                jQuery('#edit_document_' + id).show();
            } else if (json['error'] === 2) {
                jQuery('#alert_area-document_' + id).html(json['result_html']).show();
            }
        }
    });
}

/**
 * deletes a document
 */
function delete_document(id) {
    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: {
            action: 'delete',
            id: id
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] === 0) {
                var reference_document = jQuery('#reference_document_' + id);
                if (reference_document) {
                    reference_document.remove();
                }

                var document = jQuery('#document_' + id);
                if (document) {
                    document.remove();
                }

                jQuery('#documents_alert_area').html(json['result']['result_html']);
                jQuery('#header_personal_space_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_personal_space"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);

                modal.show_message(json['result']['result_message'], 5000, 'success');
            } else {
                modal.show_message(json['result']['result_message'], 5000, 'error');
            }
        }
    });
}

function show_delete_document_confirmation_modal(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=document_confirm_delete&document_id=' + id,
        'Löschung bestätigen',
        '');
}

function show_photo_form_modal(profile_id, type, image_src) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=document_photo_form&i_profile_id=' + profile_id + '&s_type=' + type + '&s_image_src=' + image_src,
        'Foto hochladen',
        ''
    );
}

function show_photo_delete_confirm_modal(profile_id, type) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=document_confirm_photo_delete&i_profile_id=' + profile_id + '&s_type=' + type,
        'Löschung bestätigen',
        ''
    );
}

function delete_photo(profile_id, type) {
    jQuery.ajax({
        url: '/documents/photo_edit.php',
        type: 'POST',
        data: {
            action: 'delete_photo',
            profile_id: profile_id,
            type: type
        },
        dataType: 'json',
        success: function (json) {
            if (json['error'] === 0) {
                if (type === 'company') {
                    if (jQuery('.js-company-logo').length > 0) {
                        jQuery('.js-company-logo').parent('.photo-edit').replaceWith(json['result_html']);
                    }
                } else {
                    if (jQuery('.js-small-thumbnail').length > 0) {
                        jQuery('.js-small-thumbnail').attr('src', json['result_small_thumbnail']);
                    }
                    if (jQuery('.js-thumbnail').length > 0) {
                        jQuery('.js-thumbnail').parent('.photo-edit').replaceWith(json['result_html']);
                    }

                    jQuery('#header_about_container').html(json['section_progress']);
                    jQuery('a.section-tags[href="#panel_about"]').html(json['section_tags']);
                    jQuery('#profile_edit_header').html(json['profile_edit_header']);
                }
            }

            modal.hide();
        }
    });
}
