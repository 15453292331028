window.addEventListener("load", function() {
    
    jQuery('.nav_user_notification').on("click", '.notif-box.dropdown-menu', function (e) {
        e.stopPropagation();
    });

    jQuery('.nav_user_notification').on("click", '.notifications-link', function () {

        var nav_user_notification = jQuery(this).closest('.nav_user_notification');
        nav_user_notification.find('span.notifcircle').hide(); //hide notifications
        unregisterJqueryResponder();

        if (!nav_user_notification.hasClass('open')){
            jQuery.post('/notification/ajax.php',
                {
                    action: 'get_last'
                },
                function (result) {
                    var j = result;
                    nav_user_notification.find('.notif-box-container').html(j['result']);
                    
                    console.log(nav_user_notification.find('.notif-box-container'));
                },'json');
        }
    });  
    
    
    jQuery('.nav_user_notification').on("click", '.js-single-notification', function () {

        unregisterJqueryResponder();
        var id = jQuery(this).attr('id');
        var url = jQuery(this).attr('href');
        
        jQuery.post('/notification/ajax.php',
            {
                action: 'mark_read',
                id: id
            },
            function (result) {
                var j = result;
                if (j['error'] == 0) {
                    // console.log('single notification mark as read: ' + id);
                    window.location = url;
                }
            }, 'json');
    });

    
    jQuery('.nav_user_notification').on("click", '.fa-times', function () {

        var nav_user_notification = jQuery(this).closest('.nav_user_notification');

        unregisterJqueryResponder();
        var id = jQuery(this).attr('id');
        jQuery.post('/notification/ajax.php',
            {
                action: 'remove_message',
                id: id
            },
            function (result) {
                var j = result;
                nav_user_notification.html(j['result']);
            }, 'json');
    });

    
    jQuery('.nav_user_notification').on("click", '#js-notification-all-read', function () {

        var nav_user_notification = jQuery(this).closest('.nav_user_notification');

        unregisterJqueryResponder();
        jQuery.post('/notification/ajax.php',
            {
                action: 'mark_all_read',
            },
            function (result) {
                var j = result;
                nav_user_notification.html(j['result']);
            }, 'json');
    });

    jQuery('.nav_user_notification').on("click", '#js-notification-show-more', function () {

        var nav_user_notification   = jQuery(this).closest('.nav_user_notification');
        var offset = nav_user_notification.find('#user_notification_list .singleNote').length;

        unregisterJqueryResponder();
        jQuery.post('/notification/ajax.php',
            {
                action: 'show_more',
                offset: offset
            },
            function (result) {
                var j = result;
                if (j['result'] != ""){
                    nav_user_notification.find('#user_notification_list').append(j['result']);
                } else {
                    nav_user_notification.find('#js-notification-show-more').hide();
                }
            }, 'json');
    });    
    
});