category_typing_time = new Date().getTime();
category_search_value = '';
category_chosen_data_category_index = []; // contains on position data-array-index the category ID
category_chosen_category_index = []; // contains on position category ID the data-array-index 
category_chosen_data_index = []; // contains on position data-array-index the name of the category
category_chosen_name_index = []; // contains on position name the data-array-index
category_experience_values = []; // contains the experience selected by the user; these are retrieved by AJAX
category_experience_tpl = ""; // contains the template which we are going to use and overwritte the chosen html
category_type = -1; // what context do we have? 1: freelancers (I'm offering); 2: projects; 3: freelancer profile reference
category_selected_category_ids = [];

number_of_max_selectable_categories = 0;
function category(init, type, selected_category_ids, list_id)
{
	this.list_id = list_id;
	this.list_element_id = "category_front_" + list_id;
	this.list_chosen_id = "category_front_" + list_id + "_chosen";
	var self = this;

	this.check_alowed_number_selectable_items = function () {
		var element = jQuery("#search_for_categories_hint");

		if (element.get() != null) {
			if (number_of_max_selectable_categories > 0 && jQuery("#" + self.list_element_id + " option:selected").length > number_of_max_selectable_categories) {
				element.show();
			} else {
				element.hide();
			}
		}
	};

	this.category_get_category_experience = function () {
		var result = [];
		jQuery.ajax('/profile_freelancer/ajax.php', {
			async: false,
			dataType: 'json',
			data: {
				action: 'category_experience_values'
			},
			type: 'POST',
			success: function (data) {
				if (data["result"] != "") {
					result = data["result"];
				}
			}
		});

		return result;
	};

	this.category_update_selected_values = function () {
		for (var i = 0; i < category_selected_category_ids.length; i++) {
			var categoryId = category_selected_category_ids[i];
			var option = self.category_find_chosen_option_by_category_id(categoryId);
			if (option.length) {
				option.attr('selected', 'selected');
			}
		}

		self.category_update_chosen_view();
	};

	this.category_initialize_components = function () {
		// Build the data index: the index equals the data-array-index in the chosen plugin.
		self.category_build_chosen_data_index();
		self.category_initialize_selected_items();

		self.check_alowed_number_selectable_items();

		jQuery("#" + self.list_element_id + " + .chosen-select").hide();
	};

	this.category_initialize_selected_items = function () {
		// Initialize any selected items.
		var dataArray = [];

		// 1. get all indicies
		jQuery("#" + self.list_chosen_id + " .chosen-choices li").each(function (index, li) {
			var dataArrayIndex = jQuery(li).find("a").attr("data-option-array-index");
			if (dataArrayIndex != null && dataArrayIndex != "") {
				// Insert our template.
				dataArray.push(dataArrayIndex);
			}
		});

		for (var i = 0; i < dataArray.length; i++) {
			self.category_insert_template_by_data_array_index(dataArray[i]);
		}

		// Sort only for freelancers view.
		// If any other view needs sorting, you should insert a jQuery plugin.
		// /js/lib/jquery/jquery.sortElements.js
		if (category_type == 1) {
			jQuery("#" + self.list_chosen_id + " .chosen-choices li").sortElements(function (a, b) {
				var categoryIdA = category_chosen_data_category_index[jQuery(a).find("a").attr("data-option-array-index")];
				var experienceA = 0.0;
				if (typeof category_experience_values[categoryIdA] !== 'undefined') {
					experienceA = category_experience_values[categoryIdA];
				}

				var categoryIdB = category_chosen_data_category_index[jQuery(b).find("a").attr("data-option-array-index")];
				var experienceB = 0.0;
				if (typeof category_experience_values[categoryIdB] !== 'undefined') {
					experienceB = category_experience_values[categoryIdB];
				}

				return parseFloat(experienceB) - parseFloat(experienceA);
			});
		}
	};

	this.category_find_chosen_option_by_category_id = function (categoryId) {
		var option = jQuery("#" + self.list_element_id + " option[value='" + categoryId + "']");
		if (option == null || option.html() == null) {
			option = jQuery("#" + self.list_element_id + " option[value^='" + categoryId + ",']");
		}
		if (option == null || option.html() == null) {
			return null;
		}
		return option;
	};

	this.category_insert_template_by_data_array_index = function (data_array_index) {
		// Find the corresponding element in the user's choices.
		var li = jQuery(self.category_find_chosen_element(data_array_index));
		if (li == null) {
			return false;
		}

		// Check if we've already manipulated this item.
		var template = li.find("div").html();
		var manipulateTemplate = true;
		if (template != null) {
			return false;
			//manipulateTemplate = false;
		} else {
			template = jQuery("<div/>").html(category_experience_tpl).contents();
		}

		// Get our HTML template as jQuery object: category_experience_tpl is a global variable.
		if (manipulateTemplate) {

			// Manipulate the template; add some additional infos.
			template.find("div").each(function (index, div) {
				if (index == 0) {
					var categoryId = category_chosen_data_category_index[li.find("a").attr("data-option-array-index")];
					var experience = category_experience_values[categoryId];
					jQuery(div).html(li.find("span").append(experienceAsText(experience)).html());
				}
				if (index == 1) {
					jQuery(div).find("span").html("aus: " + self.category_find_parent_category_name(data_array_index));
				}
			});
		}

		// Attach a handler to remove the item on a click of the X button.
		template.find("a").on("click", function () {
			// Remove from the list.
			jQuery(this).parent().parent().remove();
			// Deselect this item in the select element.
			var option = self.category_find_chosen_option_by_category_id(category_chosen_data_category_index[data_array_index]);
			if (option != null) {
				// Deselect the option.
				option.prop("selected", false);
				self.category_update_chosen_view();
			}
		});
		template.find("a").attr("data-option-array-index", data_array_index);

		// Finally assign our template to the list element.
		if (manipulateTemplate) {
			li.html(template);

			jQuery('.chosen-container').css('width', '100%');
		}
	};

	this.category_update_chosen_view = function () {
		// Update the chosen plugin.
		jQuery("#" + self.list_element_id).trigger("chosen:updated");
		jQuery("#" + self.list_element_id).trigger("change");
	};

	this.category_find_parent_category_name = function (data_array_index) {
		var categoryId = category_chosen_data_category_index[data_array_index];
		var element = self.category_find_chosen_option_by_category_id(categoryId);
		if (element == null) {
			return "";
		}
		return element.parent().attr('label');
	};

	this.category_find_chosen_element = function (data_array_index) {
		var result = null;

		jQuery("#" + self.list_chosen_id + " .chosen-choices").children().each(function (index, li) {
			if (jQuery(li).find("a").attr("data-option-array-index") == data_array_index) {
				result = li;
				return false;
			}
		});

		return result;
	};

	this.category_is_selected = function (category_id) {
		var result = false;
		jQuery("#" + self.list_element_id + " option:selected").each(function (index, option) {
			if (jQuery(option).val() == category_id) {
				result = true;
				return false;
			}
		});
		return result;
	};

	this.category_build_chosen_data_index = function () {
		var currentIndex = 1;
		jQuery('#' + self.list_element_id + ' optgroup').each(function (optionGroupIndex, optionGroup) {

			var name = copyString(jQuery(optionGroup).attr("label")).toString();

			category_chosen_data_index[currentIndex] = name; // copy the value of the variable name (no reference)
			category_chosen_name_index[name] = currentIndex;
			category_chosen_data_category_index[currentIndex] = copyInteger(jQuery(optionGroup).attr("value")).valueOf();
			currentIndex++;

			jQuery(optionGroup).find("option").each(function (optionIndex, option) {

				name = copyString(jQuery(option).html()).toString();
				var categoryId = category_clear_comma(copyInteger(jQuery(option).val()).valueOf());
				category_chosen_data_index[currentIndex] = name;
				category_chosen_name_index[name] = currentIndex;
				category_chosen_data_category_index[currentIndex] = categoryId;
				category_chosen_category_index[categoryId] = currentIndex;
				currentIndex++;
			});
		});
	};

	this.category_show_children_selection = function (parent_ids, random_number) {
		// Get id of the selected parent
		selected_parent_id = jQuery('#category_parent_' + random_number).val();

		// Hide all children
		jQuery.each(parent_ids, function (index, value) {
			jQuery("#category_children_of_parent_" + value + "_" + random_number).hide();
			jQuery("#category_children_of_parent_" + value + "_" + random_number).prop("disabled", true);
		});

		// Show cild of selected parent
		jQuery("#category_children_of_parent_" + selected_parent_id + "_" + random_number).show();
		jQuery("#category_children_of_parent_" + selected_parent_id + "_" + random_number).prop("disabled", false);
	};

	this.category_remove = function (random_number_category_group, random_number_category, category_id, object_id, object_type) {
		// Remove from DOM
		self.remove_category_from_DOM(random_number_category, random_number_category_group);
	};

	this.remove_category_from_DOM = function (random_number_category, random_number_category_group, number_of_max_selectabel_categories) {
		// Remove from DOM
		jQuery('#category_box_' + random_number_category).remove();
		category_update_counter(random_number_category_group, number_of_max_selectabel_categories);
	};

	this.category_add = function (random_number_category_group, type, object_id, object_type, overwrite_max_number_of_selectable_categories) {
		// Count number of category selection boxes
		number_of_categories = jQuery("#category_main_box_" + random_number_category_group + " .category-box").length;

		jQuery.post('/category/ajax.php',
			{
				action: 'add_category',
				type: type,
				object_id: object_id,
				object_type: object_type,
				number_of_categories: number_of_categories,
				random_number_category_group: random_number_category_group,
				overwrite_max_number_of_selectable_categories: overwrite_max_number_of_selectable_categories
			}, function (result) {
				var json = result;
				if (json.error) {
					alert(json.error);
				} else if (json.response) {
					// Add new ITEM
					jQuery('.category-box-end-' + random_number_category_group).before(json.new_item);
					category_update_counter(random_number_category_group, json.number_of_max_selectabel_categories);
				}
			}, 'json');
	};


	this.category_init = function(init, type, selected_category_ids, list_id) {

		if (jQuery(".search-field input").get(0) != null && jQuery(".search-field input").attr("placeholder") == null) {
			jQuery(".search-field input").attr("placeholder", $T("form:please_select/category_search"));
		}
		jQuery(".search-field input").css("width", "550px");

		if (init) {
			category_type = type;
			category_selected_category_ids = selected_category_ids;
			self.category_update_selected_values();

			jQuery('.search-field input').on('keyup', function () {
				category_typing_time = new Date().getTime();
			});

			jQuery("#" + self.list_element_id).bind("chosen:maxselected", function () {
				modal_show($T("form:please_select/category_max_selectable_categories"));
			});

			//jQuery(".chosen-select").chosen().change(function() {
			jQuery("#" + self.list_element_id).chosen().change(function () {
				if (category_type == 1) {
					// The number of selectable items is limited only in the freelancers view.
					self.check_alowed_number_selectable_items();
				}
				self.category_initialize_selected_items();
			});

			// init the template for all types
			jQuery.ajax('/profile_freelancer/ajax.php', {
				dataType: 'json',
				data: {
					action: 'category_experience_template'
				},
				type: 'POST',
				success: function (data) {
					if (data && data["html"] != "") {
						category_experience_tpl = data["html"];
						number_of_max_selectable_categories = data["number_of_max_selectable_categories"];
						if (category_type == 1) {
							category_experience_values = self.category_get_category_experience();
						}
						self.category_initialize_components();
					}
				}
			});
		}
	};

}

function category_update_counter(random_number, number_of_max_selectabel_categories) {
	if(number_of_max_selectabel_categories != null)
	{
		category_update_counter_elements(random_number, number_of_max_selectabel_categories);
	}
	else
	{
		get_number_of_max_selectabel_categories_and_update_counter_elements(random_number);
	}
}

//no useage found - can we remove it ?
function search_categories(type, form_id, div_id, category_input_type) {
	if(type == 1 || type == 'profile')
	{
		if(type == 'profile')
		{
			type = 1;
		}
		url = '/profile_freelancer/ajax.php';
	}
	else if(type == 2 || type == 'project')
	{
		if(type == 'project')
		{
			type = 2;
		}
		url = '/project/ajax.php';
	}

	jQuery.post(url,
		'action=search_for_categories&category_input_type=' + category_input_type + '&' + jQuery("#" + form_id).serialize(),
		function (transport) {
			var json = transport;

			if (json['error'] == 0) {
				jQuery("#" + div_id).html(json['html_result']);
				category_update_counter(json.outer_random_number, json.number_of_max_selectabel_categories);
			} else {
				alert(json['error_message']);
			}
		}, 'json');
}

// If the number of the max selectabel categories is not known, get them via ajax and call the function to update the counter
function get_number_of_max_selectabel_categories_and_update_counter_elements(random_number) {
	jQuery.post('/category/ajax.php',
		{
			action: 'get_number_of_max_selectabel_categories'
		}, function (result) {
			var json = result;
			if (json.error) {
				// Do not show if you are not debugging!
				//alert(json.error_msg);
			} else {
				// Call the function to update the elements
				category_update_counter_elements(random_number, json.response);
			}
		}, 'json'
	);
}

function category_update_counter_elements (random_number, number_of_max_selectabel_categories) {
	// Count number of category selection boxes
	number_of_categories = jQuery("#category_main_box_" + random_number + " .category-box").length;

	// Update number
	jQuery("#category_number_of_categories_counter_" + random_number + " .category-number-of-categories-counter-dynamic-part").html(number_of_categories);

	// Change color
	// Error
	if(number_of_categories > number_of_max_selectabel_categories || number_of_categories <= 0)
	{
		jQuery("#category_number_of_categories_counter_" + random_number).addClass("text-negative");
		jQuery("#category_number_of_categories_counter_" + random_number).removeClass("text-positive");
	}
	// Ok
	else
	{
		jQuery("#category_number_of_categories_counter_" + random_number).addClass("text-positive");
		jQuery("#category_number_of_categories_counter_" + random_number).removeClass("text-negative");
	}
}

function category_show_children_selection (parent_ids, random_number)
{
	// Get id of the selected parent
	selected_parent_id = jQuery('#category_parent_'+random_number).val();

	// Hide all children
	jQuery.each(parent_ids, function(index, value){
		jQuery("#container_category_children_of_parent_" + value + "_" + random_number).hide();
		jQuery("#category_children_of_parent_" + value + "_" + random_number).hide();
		jQuery("#category_children_of_parent_" + value + "_" + random_number).prop("disabled", true);
	});

	// Show cild of selected parent
	jQuery("#container_category_children_of_parent_" + selected_parent_id + "_" + random_number).show();
	jQuery("#category_children_of_parent_" + selected_parent_id + "_" + random_number).show();
	jQuery("#category_children_of_parent_" + selected_parent_id + "_" + random_number).prop("disabled", false);
	jQuery('.selectarea').selectpicker('refresh');
}

function remove_category_from_DOM (random_number_category, random_number_category_group, number_of_max_selectabel_categories) {
	// Remove from DOM
	jQuery('#category_box_'+random_number_category).remove();
	category_update_counter(random_number_category_group, number_of_max_selectabel_categories);
}

function category_add(random_number_category_group, type, object_id, object_type, overwrite_max_number_of_selectable_categories) {
	// Count number of category selection boxes
	number_of_categories = jQuery("#category_main_box_" + random_number_category_group + " .category-box").length;

	jQuery.post('/category/ajax.php',
		{
			action: 'add_category',
			type: type,
			object_id: object_id,
			object_type: object_type,
			number_of_categories: number_of_categories,
			random_number_category_group: random_number_category_group,
			overwrite_max_number_of_selectable_categories: overwrite_max_number_of_selectable_categories
		},

		function (result) {
			var json = result;
			if (json.error) {
				alert(json.error);
			} else if (json.response) {
				// Add new ITEM
				jQuery('.category-box-end-' + random_number_category_group).before(json.new_item);
				category_update_counter(random_number_category_group, json.number_of_max_selectabel_categories);
				jQuery('.selectarea').selectpicker('refresh');
			}
		}, 'json');
}

this.category_remove = function (random_number_category_group, random_number_category, category_id, object_id, object_type) {

	// Remove from DOM
	remove_category_from_DOM(random_number_category, random_number_category_group);
};


function category_clear_comma(value)
{
	var commaPosition = -1;
	if ((commaPosition = value.indexOf(",")) >= -1) {
		value = value.substring(0, commaPosition);
	}
	return value;
}

function experienceAsText(experience)
{
	var result = ""; 
	if(category_type == "1") {
		if(experience && experience > 0) {
			result = " - ";
			var years = parseInt(experience);
			var months = Math.round((experience - years)*12);
			var hasYears = false;
			if(years > 0) {
				result += years + " ";
				if(years == 1) {				
					result += "Jahr";
				} else {
					result += "Jahre";
				}
				hasYears = true;
			}
			
			if(months >= 1) {
				if(hasYears) {
					result += ", ";
				}
				result += months+" ";
				if(months == 1) {
					result += "Monat";			
				} else if(months > 1) {
					result += "Monate";
				}
			}
			
			result += " Erfahrung";
		}
	}
	
	return result;
}


/**
 * Returns a new object representing the given integer;
 * the value of the integer can be get by calling valueOf() 
 * @param integer
 * @returns object
 */
function copyInteger(integer)
{
	return {
		integer: integer,
		valueOf: function() {
			return integer;
		}
	};
}

/**
 * Returns a new object representing the given string;
 * the value of the string can be get by calling toString() 
 * @param integer
 * @returns object
 */
function copyString(string)
{
	return {
		string: string,
		toString: function() {
			return string;
		}
	};
}


function dumpObject(object) {
	for (var key in object) {
		if (object.hasOwnProperty(key)) {
		  console.log(key + " -> " + object[key]);
		}
	}
}

function initialize_category_tagbox(id, tags, translations, category_box_func) {
	var shortInput = "";
	if (translations.shortInput) {
		shortInput = translations.shortInput;
	}

	var longInput = "";
	if (translations.longInput) {
		longInput = translations.longInput;
	}

	var placeholder = "";
	if (translations.placeholder) {
		placeholder = translations.placeholder;
	}

	jQuery('#' + id).select2({
		tags: tags,
		language: {
			inputTooLong: function () {
				return longInput;
			},
			inputTooShort: function () {
				return shortInput;
			}
		},
		maximumInputLength: 50,
		templateSelection: function (category) {

			let top_skill_pin_icon = '';
			if (category.element.dataset.pinned == 'yes') {
				top_skill_pin_icon = 'top_skills_pin fas fa-thumbtack cursor-pointer';
			} else if (category.element.dataset.pinned == 'no') {
				top_skill_pin_icon = 'top_skills_pin far fa-thumbtack rotate30 cursor-pointer';
			} else if (category.element.dataset.pinned == 'not_available') {
				top_skill_pin_icon = 'top_skills_pin far fa-thumbtack rotate30 cursor-pointer not_available';
			}

			let pin_attributes = '';
			if (category_box_func === 'updateSelect2FreelancerFormSkillsCategories' && category.element.dataset.pinned != 'not_available') {
				pin_attributes = '<i onclick="topSkillsPin(jQuery(this))" class="' + top_skill_pin_icon + '" data-id="' + category.id + '"></i>';
			} else {
				pin_attributes = '<i class="' + top_skill_pin_icon + '" data-toggle="tooltip" data-original-title="Als EXPERT Mitglied verfügbar"></i>';
			}

			return jQuery('<div class="margin-right-md" onclick="event.stopPropagation()">' + pin_attributes + category.text + '</div>');

		},
		placeholder: placeholder,
		matcher: function (params, data) { return data; }, // we do matching in sorter
		sorter: function(data) {

			var self = $('#' + id).data('select2');
			var filter = self.$container.find(".select2-search__field").val().trim();
			var tags = self.options.options.tags;

			if (filter == "") {
				// no search, return whole set
				return data;
			} else {
				// search ...
				var words = splitTextToWords(filter);
				var filteredDataStartWith = [];
				var filteredDataContains = [];
				var filteredDataWords = [];

				for (var i = 0; i < data.length; i++) {
					// skip nodes without children
					if (!data[i].children) {
						continue;
					}

					// for every children of this node
					for (var j = 0; j < data[i].children.length; j++) {

						// skip already selected
						if (data[i].children[j].selected) {
							continue;
						}

						// get name of the skill
						var childText = data[i].children[j].text.trim();

						// search 1
						// if name of the skill starts with searched term, push it to filteredDataStartWith and skip to next
						if (childText.toUpperCase().indexOf(filter.toUpperCase()) == 0) {
							filteredDataStartWith.push(data[i].children[j]);
							continue;
						}

						// search 2
						// if name of the skill contains searched term, push it to filteredDataContains and skip to next
						if (childText.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
							filteredDataContains.push(data[i].children[j]);
							continue;
						}

						// search 3
						// if name of the skill contains any word of searched term, push it to filteredDataWords
						var score = 0;
						var childWords = splitTextToWords(childText);
						// check every word of search term
						for (var k = 0; k < words.length; k++) {
							if (childWords.includes(words[k])) {
								// if child text contains this word count its score and add it to the score for whole skill
								score += words.length - k; // score is position of the world in search term
							}
						}
						// if score is not 0, then skill name contains at least one of searched words, push it to the results with it score
						if (score > 0) {
							filteredDataWords.push({data: data[i].children[j], score: score});
							continue;
						}
					}
				}

				// if there are no exact matches, push also search term to the results (allowing free text entries)
				if (tags && filteredDataStartWith.length == 0 && filteredDataContains .length == 0) {
					filteredDataWords.push({data: data[0], score: Infinity});
				}

				// sort results of word search by its score
				filteredDataWords.sort(function (a,b) { return (a.score < b.score) ? 1 : (a.score > b.score) ? -1 : 0; });

				// concat results of all searches in order 1,2,3, filter out scores from search 3
				var result = [].concat(filteredDataStartWith,filteredDataContains,filteredDataWords.map(function (item) { return item.data; }));

				return result;
			}
		}

	}).on('change', function () {

		jQuery('.select2-search.select2-search--inline input').attr('placeholder', placeholder);

		if (category_box_func === 'updateSelect2FreelancerFormSkillsCategories') {
			updateSelect2FreelancerFormSkillsCategories();
		} else if (category_box_func === 'updateSelect2FreelancerFormReferencesCategories') {
			updateSelect2FreelancerFormReferencesCategories();
		}
	});

	jQuery(document).off('keyup', '.select2-search__field').on('keyup', '.select2-search__field', function (e) {
		// scroll skill selection to the top when key is pressed, except arrows
		if (![37,38,39,40].includes(e.keyCode)) {
			jQuery('#select2-skills_categories-results').scrollTop(0);
		}
	});

	jQuery('.select2-search.select2-search--inline input').attr('placeholder', placeholder);
}

function updateSelect2FreelancerFormSkillsCategories(ignore_max_number_skills = 'false'){

	let form_element = jQuery('select[name="skills_categories[]"]').parents('form');
	let data_form = form_element.serializeArray();
	form_element.find('i.top_skills_pin.fas.fa-thumbtack').each(function (index, element) {
		data_form.push({name: 'pin_skills[]', value: element.dataset.id});
	});
	data_form.push({name: 'ignore_max_number_skills', value: ignore_max_number_skills});

	jQuery.post(
		'/profile_freelancer/edit/skills.php',
		data_form,
		function (json) {
			jQuery('#panel_skills_ajax').fadeOut(500);
			jQuery('select[name="skills_categories[]"]').select2('destroy'); //destroy select2
			jQuery('#panel_skills_ajax').html(json['result']['html']).fadeIn(500); //reinitialise select2

			jQuery('#header_skills_container').html(json['result']['section_progress']);
			jQuery('a.section-tags[href="#panel_skills"]').html(json['result']['section_tags']);
			jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
			showDataLayerAfterProfileUpdate();
		},
		'json'
	);
}

function updateSelect2FreelancerFormReferencesCategories(){

	let form_element = jQuery('select[name="reference_categories[]"]').parents('form');
	let data_form = form_element.serializeArray();

	jQuery.post(
		'/profile_freelancer/edit/references.php',
		data_form,
		function (json) {
			jQuery('#panel_references_ajax').fadeOut(500);
			jQuery('select[name="reference_categories[]"]').select2('destroy'); //destroy select2
			jQuery('#panel_references_ajax').html(json['result_reference']).fadeIn(500); //reinitialise select2
		},
		'json'
	);
}


function topSkillsPin(element) {

	let pin_skills = [];
	jQuery('#panel_skills_ajax .top_skills_pin.fas.fa-thumbtack').each(function (index) {
		pin_skills.push(index);
	});

	let unpin_skills = [];
	jQuery('#panel_skills_ajax .top_skills_pin.far.fa-thumbtack').each(function (index) {
		unpin_skills.push(index);
	});

	if (element.hasClass('far') && pin_skills.length >= 3) {
		jQuery('#top_pin_alert').html("<div class='margin-top-md alert alert-danger no-margin-bottom'><span>Sie haben bereits drei Qualifikationen ausgewählt. Bitte lösen Sie einen Pin um eine andere Qualifikation zu markieren.</span></div>");
	} else {
		element.toggleClass('fas').toggleClass('far').toggleClass('rotate30');
		updateSelect2FreelancerFormSkillsCategories(true);
	}
}

function splitTextToWords(text) {
	return [...new Set(text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9]+/g, " ").trim().toUpperCase().split(" "))];
}
