/**
 * Simple session object.
 */
class FieldManager {

    constructor() {
        this.url = '/ajax.php';
    }

    /**
     * Updates a customer's opinion; if error occurs false will be returned.
     */
    update(formId, hideBoxId, resultId) {
        var self = this;
        var result = true;
        jQuery.ajax({
            type: "POST",
            url: this.url,
            data: jQuery("#" + formId).serialize(),
            async: false,
            success: function (ajaxResult) {
                var response = new AjaxResponse(ajaxResult);
                result = !response.error;
                if (result) {
                    if (jQuery("#" + hideBoxId).length) {
                        jQuery("#" + hideBoxId).hide();
                    }
                    if (jQuery("#" + resultId).length) {
                        jQuery("#" + resultId).show();
                    }
                }
            }
        });

        return result;
    }
}