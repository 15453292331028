/**
 * creates a new search agent
 */
function searchagent_add_searchagent(type) {
	jQuery.post('/search/ajax.php',
		{
			action: 'add_new_searchagent',
			type: type
		},
		function (t) {
			j = t;

			if (j["error"] == 0) {
				jQuery('#' + 'searchagent_new_agent').html(j["result"]);
				jQuery('#searchagent_new_agent_panel').show();
				jQuery('#searchagent_0').hide();
				jQuery('#project_panel_searchagent_0').collapse('show');
				searchagent_availibility_check_visible(0);

				auto_city_0 = new AutocompletionCity('__search_city_0', 'city_autocompletion_0', 'search', '__search_city_country_0', '__search_city_perimeter_0', '__search_city_hint_0', '__search_city_location_id_0');
			} else {
				if (j['upgradeable'] == 1) {
					var onclick = "location.href='/account/create.php'; modal.hide();";
					modal.show_message(j['error_message'], 100000, 'error', "Abbrechen", {'label': 'Jetzt upgraden', 'onclick': onclick});
				} else {
					modal.show_message(j['error_message'], 100000, 'error');
				}
			}
		}, 'json');
}

function searchagent_ask_user_to_activate(i_object_id) {
	var onclick = "searchagent_toggle_active('" + i_object_id + "'); modal.hide();";
	modal.show_message($T('searchagent:activate_confirmation'), '1000000', 'info', 'Nein', {'label': 'Ja', 'onclick': onclick});
}
 
function searchagent_toggle_active(searchagent_id,element)
{
	jQuery.post('/search/ajax.php',
		{
			action: 'searchagent_toggle_active',
			searchagent_id: searchagent_id
		},
		function (t) {
			window.location.reload();
		});
}


/**
 * Save search as searchagent
 */
function show_save_as_searchagent_form(request)
{
	jQuery.post('/search/ajax.php',
	{
		action: 'show_save_as_searchagent_form',
		asynchronous: false
	},
	function(t) {
		j = t;
		if (j["error"] == 0)
		{
			modal_show(j['result']);
		}
		else if (j['error'] == 2)
		{
			window.location.href=j['error_message'];
		}
		else
		{
			modal_show(j['error_message']);
		}
		return false;
	}, 'json');
	return false;
}

 
 
 /**
  * Save search as searchagent
  */
 function search_as_searchagent_save(request, searchagent_form)
 {
	 if(jQuery('#' + searchagent_form))
	 {
		 form = jQuery('#' + searchagent_form).serialize();

		 jQuery.post('/search/ajax.php',
			 form,
			 function (t) {
				 j = t;

				 if (j['error'] == 0) {
					 modal.show_message(j["result_message"], null, "success");
				 } else if (j['error'] == 3) {
					 modal.update(j["content"]);
				 } else {
					 modal.update(j["error_message"]);
				 }

				 return false;
			 }, 'json');
	 }
	 else
	 {
		 jQuery.post('/search/ajax.php',
			 {
				 action: 'search_as_searchagent_save',
				 asynchronous: false
			 },
			 function (t) {
				 j = t;
				 if (j["error"] == 0) {
					 modal_show(j['result']);
				 } else if (j['error'] == 2) {
					 window.location.href = j['error_message'];
				 } else {
					 modal_show(j['error_message']);
				 }
				 return false;
			 }, 'json');
	 }

	 return false;
 }


 /**
  *  Save searchagent settings
  *  
  * @param searchagent_id
  * @param form_box_element
  * @param form_element
  * @param searchagent_main_element
  * @return
  */
 function searchagent_save(searchagent_id, form_box_element, form_element, searchagent_main_element)
 {
 	var form = form_element.serialize();

 	var o_element = form_box_element;
 	var i_object_id = searchagent_id;
 	var o_object = searchagent_main_element;

	 jQuery.post('/search/ajax.php',
		 form + '&action=searchagent_save&searchagent_id=' + i_object_id,
		 function (t) {
			 if (o_object.length) {

				 // Check if the searchagent is not activ
				 if (t == 0) {
					 jQuery('#' + 'searchagent_settings_' + searchagent_id).hide();
					 // Ask the user if he wants to activate the searchagent
					 searchagent_ask_user_to_activate(i_object_id);
				 } else if (t != "0" && !isNaN(parseFloat(t)) && isFinite(t)) {
					 jQuery('#searchagent_new_agent_panel').hide();
					 o_element.hide();
					 // Search
					 if (i_object_id == 0) {
						 o_object.id = 'searchagent_main_' + t;
						 jQuery('#' + 'searchagent_created_agent').html(jQuery('#' + 'searchagent_new_agent'));
						 jQuery('#' + 'searchagent_new_agent').html('');
						 searchagent_search(t, jQuery('#' + 'searchagent_main_' + t), 'open_results');

						 modal.show_message("Ihr neuer Suchagent wurde erfolgreich angelegt", 5000, "success");

						 window.location = "/search/searchagent.php";

					 } else {
						 searchagent_search(i_object_id, o_object, 'open_results');
					 }
				 } else {
					 jQuery('#' + 'searchagent_settings_form_' + searchagent_id).html(t);
				 }
			 } else {
				 modal.show_message("Projektvorschläge wurde erfolgreich gespeichert", null, "success");
				 jQuery('#searchagent_settings_' + searchagent_id).hide();
			 }
		 });
 }



/**
 * Update the searchagent item
 * 
 * @param searchagent_id
 * @param element
 * @param open_box_id
 * @return
 */
function searchagent_refresh_searchagent(searchagent_id, element, open_box_id) {
	var o_element = element;
	jQuery.post('/search/ajax.php',
		{
			action: 'refresh_searchagent_item',
			searchagent_id: searchagent_id,
			open_box_id: open_box_id,
			asynchronous: false
		},

		function (t) {

			var res = t;

			if (res["error"] == 0) {

				o_element.html(res["result"]);

				jQuery('input[type="checkbox"].searchagent_toggle_js').bootstrapSwitch().on('switchChange.bootstrapSwitch', function (event, state) {

					var link = jQuery(this).attr("data-link");
					if (link) {

						jQuery.post('/search/ajax.php',
							{
								action: 'searchagent_toggle_active',
								searchagent_id: link
							}
						);
					}
				});

				jQuery('#project_panel_searchagent_' + searchagent_id).collapse('show');

				var html_search_project = null;
				if (res["type"] == 1) {
					show_item_options_for_profile();
					html_search_project = new FL_ItemParserForSearchProfile();
					html_search_project.display();
				} else if (res["type"] == 2) {
					show_item_options_for_project();
					html_search_project = new FL_ItemParserForSearchProject();
					html_search_project.display();
				}
			} else {
				console.log('got error ');
			}
			jQuery('.tooltip').tooltip('hide');
		}, 'json');
}




/**
 * Search
 * 
 * @param searchagent_id
 * @param element
 * @param open_box_id
 * @return
 */
function searchagent_search(searchagent_id, element, open_box_id)
{
	var i_object_id = searchagent_id;
	var o_object = element;
	var s_open_box_id = open_box_id;

	jQuery.post('/search/ajax.php',
		{
			action: 'searchagent_search',
			searchagent_id: searchagent_id
		},
		function (t) {
			var res = t;

			if (res["error"] == 0) {
				searchagent_refresh_searchagent(i_object_id, o_object, s_open_box_id);
			}
			jQuery('.tooltip').tooltip('hide');
		}, 'json');

	jQuery('#busy').show();
    jQuery('.tooltip').tooltip('hide');
}




/**
 * Delete a searchagent
 * 
 * @param searchagent_id
 * @param element
 * @return
 */
function searchagent_delete(searchagent_id)
{
	modal.show_message('Möchten Sie den Suchagenten wirklich löschen?', '1000000', 'info', 'Nein',
		{'label' : 'Ja', 'onclick' : "searchagent_delete_data(" + searchagent_id + "); modal.hide();"});
}

function searchagent_delete_data(searchagent_id){

	jQuery.post('/search/ajax.php',
		{
			action: 'searchagent_delete',
			searchagent_id: searchagent_id
		},
		function(t) {
			jQuery('#searchagent_main_' + searchagent_id).hide();
		});
}

function searchagent_availibility_check_visible(searchagent_id) {
    var form = jQuery('#searchagent_settings_form_' + searchagent_id);
    var type;

    if (form.find("input[type='radio'][name='__search_type']").length > 0) { // check if __search_type input is radiobutton
		type = form.find("input[name='__search_type']:checked").val(); // find value of checked radiobutton
	} else {
		type = form.find("input[name='__search_type']").val(); // find value of other type of input
	}

    if (type == '2') {
        form.find('.freelancer_availability_check').show();
    } else {
        form.find('.freelancer_availability_check').hide();
        form.find('#availability').prop('checked', false);
    }
}
