function updateDocumentsInBackground()
{
    jQuery.post('/profile_freelancer/documents.php', 'action=update_documents_in_backgroud');
}

/**
 * saves the settings for a freelancer profile
 */
function profile_freelancer_save_settings()
{
	var profileVisibility = jQuery('form[id="settings_form"] select[name="visibility"]').val();
	var contactDataVisibility = jQuery('form[id="settings_form"] select[name="visible_to"]').val();
	var documentVisibility = jQuery('form[id="settings_form"] select[name="documents_visible_to"]').val();
	if(profileVisibility == "REGISTERED" && (contactDataVisibility == "2" || documentVisibility == "2")) {
		modal_show(jQuery('#profile_freelancer_confirm_save').html(), null, '');
		return;
	}

    jQuery.post('/profile_freelancer/settings.php',
        'action=save&' + jQuery('#settings_form').serialize(),
        function () {
            modal.show_message('Ihre Angaben wurden gespeichert.', 5000, 'success');
        });
}

function profile_freelancer_save_settings_alias()
{
	jQuery.post('/profile_freelancer/settings.php',
        'action=save_alias&'+jQuery('#alias_form').serialize(),
		function(result) {
			var j = result;
			if(j['error'] == 1) {
				jQuery('#alias-form-container').html(j['result_html']);
			} else {
                jQuery('#alias-form-container').html(j['result_html']);
			    modal.show_message('Ihre Angaben wurden gespeichert.', 5000, 'success');
			}
            toggle_freelancer_alias_input();
		}, 'json');
}

function toggle_freelancer_alias_input() {
    let elm = jQuery("#show_alias");
    let showed = elm.data("showed");
    showed = (typeof showed == "undefined" ? false : showed);
    let checked = elm.prop("checked");
    let error = elm.parents(".form-group").hasClass("error");
    if (checked || showed || error) {
        // if checkbox is checked or we have already shown text input to user or we got error (which means this is response to ajax call), show it (or leave it visible)
        jQuery("#alias").show();
        jQuery("#save").show();
        elm.data("showed",true); // We have already shown this to user
    } else {
        jQuery("#alias").hide();
        jQuery("#save").hide();
    }
}

/**
 * Shows the errorbox when the user hasn t seleceted the profile type to download
 */
function manage_error_box()
	{
		var index = document.getElementById("type").selectedIndex;

		if((document.getElementById("type")[index].value) != 0)
		{
			document.getElementById("error_Box").style.display = 'none';
		}
		else
		{
			document.getElementById("error_Box").style.display = 'inline';
		}
	}


/**
 * Checks the country. If it is Germany, limit the zipcode length to 5.
 *
 * @param country_element
 * @param zipcode_element
 * @param county_element
 * @return
 */
function check_country(country_element_selector, zipcode_element_selector, county_element_selector)
{
	// If country is Germany (76)
	if(jQuery(country_element_selector).val() == 76)
	{
		if(zipcode_element_selector != '')
		{
			jQuery(zipcode_element_selector).attr('maxlength', 5);
		}

		jQuery(county_element_selector).prop("disabled", false);
		jQuery(county_element_selector).removeClass("disabled");
		jQuery(county_element_selector).removeAttr("fl-skip-enable", true);
		jQuery('.selectarea').selectpicker('refresh');
	}
	else
	{
		if(zipcode_element_selector != '')
		{
			jQuery(zipcode_element_selector).attr('maxlength', 9);
		}

		jQuery(county_element_selector).val("");
		jQuery(county_element_selector).prop("disabled", true);
		jQuery(county_element_selector).attr("fl-skip-enable", true);
		jQuery('.selectarea').selectpicker('refresh');

	}
}

function show_internal_mail_modal(receiver_id) {
    modal.showFLPopup(
    	'request',
		'/ajax/popup_template.php',
		'action=profile_internal_mail&i_receiver_id=' + receiver_id,
		'Neue Nachricht erstellen'
	);
}

function show_availability_alert_modal(profile_id) {

    jQuery.ajax({
        url: '/profile_freelancer/ajax.php',
        type: 'POST',
        data: {
            action: 'availability_alert',
            profile_id: profile_id
        },
        dataType: 'json',
        success: function (json) {
            if (json['error'] === 1) {

            } else {
                modal.setType('string');
                modal.setSource(json["result"]);
                modal.setTitle('Verfügbarkeits-Alert');
                modal.show();

                jQuery('#availability_alert_container_'+ profile_id).html(json['availability_alert_html']);

                if (jQuery('.action-icon i.fa-bookmark').length) {
                    jQuery('#container_mark_as_favorite_' + profile_id ).html(json['favorite_icon_class_html']);
                }

            }
        }
    });
}

function availability_alert_remove_profileId_from_favouriteList(profile_id){
    jQuery.post('/network/ajax.php',
        'action=watchlist_remove_profile&profile_id='+profile_id,
            function (json){

                if (jQuery('.action-icon i.fa-bookmark').length) {
                    jQuery('#container_mark_as_favorite_' + profile_id ).html(json['favorite_icon_class_html']);
                }

                if (jQuery('#profile_favorite_list').length) {
                    jQuery('#profile_favorite_list').html(json['html_watchlist_profile']);
                }

            },'json');
}

function send_modal_internal_mail()
{
    var formData = new FormData(jQuery('#internal_mail-form')[0]);

    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 1) {
				modal.update(json['html']);
            } else {
                modal.show_message(json["message"], 5000, 'success');
                jQuery('#container_menu_contact_data_views').html(json["html_contact_data_views"]);
            }
        }
    });
}

function show_document_upgrade_account_modal() {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=document_upgrade_account',
        'Profil Upgrade erforderlich');
}

function open_print_view(profile_id) {
    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: {
            action: 'open_print_view',
            profile_id: profile_id
        },
        dataType: 'json',
        success: function (json) {
            if (json['error'] === 0) {
                jQuery('#container_menu_contact_data_views').html(json["contact_data_views"]);
                window.open(json["url"], "_blank");
            } else if (json['error'] === 1) {
                modal.showFLPopup(
                    'request',
                    '/ajax/popup_template.php',
                    'action=provider_upgrade_account',
                    'Profil drucken');
            } else {
                modal.showFLPopup(
                    'request',
                    '/ajax/popup_template.php',
                    'action=contacts_limit',
                    'Profil drucken');
            }
        }
    });
}

function recommend_freelancer_profile()
{
    var formData = new FormData(jQuery('#recommend_freelancer_profile-form')[0]);

    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 1) {
                modal.update(json['html']);
            } else {
                modal.show_message(json["message"], 5000, 'success');
            }
        }
    });
}


function report_freelancer_profile()
{
    var formData = new FormData(jQuery('#report_freelancer_profile-form')[0]);

    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 1) {
                modal.update(json['html']);
            } else {
                modal.show_message(json["message"], 5000, 'success');
            }
        }
    });
}

/**
 * This function counts how often the contact data was shown
 */
function count_shown_contactdata_for_profile(profile_id) {
    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: {
            action: "count_shown_profile_contact_data",
            profile_id: profile_id
        },
        dataType: 'json',
        success: function (json) {
            if (json["error"] === 0) {
                jQuery('#contact_data').html(json["result"]);
                jQuery('#container_menu_contact_data_views').html(json["contact_data_views"]);
            } else {
                jQuery('#contact_data').html(json["result"]);
            }
        }
    });
}

function contact_data_upgrade() {

    window.location.href = '/account/create.php';
}

/**
 * Send project to freelancer modal

 * @param modal_title
 * @param profile_id
 */
function show_send_project_to_freelancer_modal(modal_title, profile_id, project_id) {

    if (project_id === undefined) {
        project_id = 0;
    }

    var project_arguments = "";
    if (project_id > 0) {
        project_arguments = "&project_id=" + project_id;
    }

    ajaxHideLoadingForNextCall();

    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=send_project&profile_id=' + profile_id + project_arguments,
        modal_title,
        'modal-gray-body'
    );
}

/**
 * Init send project to freelancer modal
 */
function init_send_project_to_freelancer_modal(url_back_encoded, is_error) {
    window.addEventListener("load", function() {
        var offline_message_default = ""; // load on first usage;)
        var already_selected_message_default = ""; // load on first usage;)
        var message_preview_text_default = jQuery("#message_preview_text").html(); // get the value from the box

        jQuery("select#project_id").selectpicker({
            liveSearch: true,
            size: 8,
            showSubtext: true
        }).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            if (this.value) {
                var selected_option = jQuery(this).find("option:selected");
                var project_name = selected_option.text().trim();
                var message_preview_text = message_preview_text_default.replace("FL_PROJECT_TITLE", project_name);

                if (selected_option.data("offline")) {
                    if (!offline_message_default) {
                        offline_message_default = $T("profile_freelancer:send_project_to_freelancer/selected_project_is_offline_message");
                    }
                    var offline_message = offline_message_default.replace("FL_PROJECT_EDIT_LINK", "/project/create.php?overview&id=" + selected_option.val() + "&r=" + url_back_encoded);
                    jQuery("#send_project_warning p").html(offline_message);
                    jQuery("#send_project_warning").show();
                    jQuery("button#send_project_button").prop("disabled", true);
                    jQuery("#send_project_budget").slideUp();
                } else if (selected_option.data("already-sent")) {
                    if (!already_selected_message_default) {
                        already_selected_message_default = $T("profile_freelancer:send_project_to_freelancer/selected_project_is_already_sent_message");
                    }
                    jQuery("#send_project_warning p").html(already_selected_message_default);
                    jQuery("#send_project_warning").show();
                    jQuery("button#send_project_button").prop("disabled", true);
                    jQuery("#send_project_budget").slideUp();
                } else {
                    jQuery("#send_project_warning").hide();
                    jQuery("button#send_project_button").prop("disabled", false);
                    jQuery("#send_project_budget").slideDown();
                    // populate budget fields
                    jQuery("input#project_budget").val(selected_option.data("budget"));
                    jQuery("select#project_budget_unit").val(selected_option.data("budget-unit")).change();
                }
                jQuery('#message_preview_text').html(message_preview_text);
            } else {
                jQuery("#send_project_budget").slideUp();
            }
        });
        // show budget part on form error
        if (jQuery("select#project_id").val()) {
            jQuery("#send_project_budget").show();
            if (!is_error) {
                var selected_option = jQuery(this).find("option:selected");
                jQuery("input#project_budget").val(selected_option.data("budget"));
                jQuery("select#project_budget_unit").val(selected_option.data("budget-unit")).change();
            }
        }
        jQuery("select#project_id").change();
    });
}

/**
 * The form in send project modal submit
 */
function submit_send_project_to_freelancer_form() {
    var form_obj = jQuery('form#send_project_to_freelancer-form')[0];
    var form_data = new FormData(form_obj);
    form_data.append("action", "send_project_to_freelancer");

    jQuery.ajax({
        url: '/profile_freelancer/index.php',
        type: 'POST',
        data: form_data,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json.error === 1) {
                modal.update(json.html);
            } else if (json.error === 2) { // gen. error
                // that is not very friendly, but it this error is shown only if generally you dont have right to send the project
                modal.show_message(json.message, 5000, 'danger');
            } else {
                // update the button on the profile
                jQuery('button.send_project_button span').text(json.button_text);
                jQuery('span.send_project_button_info').html(json.button_hint);
                // show message
                modal.show_message(json.message, 5000, 'success');
            }
        }
    });
}


function show_match_similar_profiles_modal(profile_id) {

    jQuery.ajax({
        url: '/profile_freelancer/ajax.php',
        type: 'POST',
        data: {
            action: 'match_similar_profiles',
            profile_id: profile_id
        },
        dataType: 'json',
        success: function (json) {
            if (json.error != 1) {

                modal.showFLPopup(
                    'string',
                    json.result,
                    null,
                    'Twin Profile'
                );
            }
        }
    });
}

function show_confirm_delete_freelancer_schedule(schedule_id, modal_header) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=confirm_delete_schedule&schedule_id=' + schedule_id,
        modal_header,
        '');
}

function delete_freelancer_schedule(id, message_template_deleted, please_select) {
    jQuery.ajax({
        url: '/profile_freelancer/edit.php',
        type: 'POST',
        data: {
            action: 'delete_schedule',
            id: id
        },
        dataType: 'json',
        success: function (json) {
            if (json["error_code"] == 0) {
                modal.show_message(json["message"], 5000, 'success');
                jQuery('#block_availability').html(json['result']['availability']);
                jQuery('#header_business_data_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_business_data"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            } else {
                modal.show_message(json["message"], null, 'error');
            }

            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function toggleShortFullNote(){

    if (jQuery('#profile_view #noteShort').is(":visible")) {
        jQuery('#profile_view #noteShort').hide();
        jQuery('#profile_view #noteFull').show();

    } else {
        jQuery('#profile_view #noteFull').hide();
        jQuery('#profile_view #noteShort').show();
    }
}

function copyText(id){
    var copiedText = jQuery("#" + id).text();
    navigator.clipboard.writeText(copiedText).then(function() {
        jQuery('#copySuccess').show().delay(3000).fadeOut();
    });
}
