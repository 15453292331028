function load_next_stats_projects(limit, offset)
{
	jQuery.post('/myfreelance/ajax.php',
		'action=load_next_stats_projects&offset=' + offset,
		function (t) {
			var j = t;
			if (j['error'] == 0) {
				jQuery('#project_favorite_list').append(j['content']['list']);
				jQuery('#more_favorite_projects').html(j['content']['link']);
			}
		}, 'json');
}

function load_next_stats_profiles(limit, offset)
{
	jQuery.post('/myfreelance/ajax.php',
		'action=load_next_stats_profiles&offset=' + offset + '&limit=' + limit,
		function (t) {
			var j = t;
			if (j['error'] == 0) {
				jQuery('#statistics').append(j['content']['list']);
				jQuery('#more_statistics').html(j['content']['link']);
			}
		}, 'json');
}