function faq_toggle(id)
{
	jQuery('#faq_' + id + ' div.hideDiv').each(function(e){

		if(jQuery('#faq_show_' + id).css('display') == 'none')
		{
			jQuery(e).css({display:'none'});
			i_id = jQuery(e).id.replace("a_", "");
			jQuery("#i_" + i_id).src = "/images/page_elements/element-open.png";
		}
		else
		{
			jQuery(e).css({display:''});
			i_id = jQuery(e).id.replace("a_", "");
			jQuery("#i_" + i_id).src = "/images/page_elements/element-close.png";
		}
	});

	if(jQuery('#faq_show_' + id).css('display') == 'none')
	{
		jQuery('#faq_show_' + id).css({display:''});
		jQuery('#faq_hide_' + id).css({display:'none'});
	}
	else
	{
		jQuery('#faq_show_' + id).css({display:'none'});
		jQuery('#faq_hide_' + id).css({display:''});
	}
}

function faq_toggle_single(id)
{
	if(jQuery('#a_' + id).css('display') == 'none')
	{
		jQuery('#a_' + id).show();
		jQuery('#i_' + id).src = "/images/page_elements/element-close.png";
	}
	else
	{
		jQuery('#a_' + id).hide();
		jQuery('#i_' + id).src = "/images/page_elements/element-open.png";
	}
}

function faq_search()
{
	jQuery("#faq_search_result").load('/faq/ajax.php',
		urlParamsToObject('action=search&' + jQuery('#faq_search_form').serialize()));
}