function tryToGoToAssistantStep(step_number, profile_id) {

    unregisterJqueryResponder();
    jQuery.post('/profile_freelancer/ajax.php',
        'action=is_allowed_to_go_to_assistant_step&step_number=' + step_number + '&profile_id=' + profile_id,
        function (result) {
            var json = result;

            if (json.response) {
                window.location.href = '/profile_freelancer/edit.php?ftgtas=' + step_number;
            }
        }, 'json');
}


function updateFreelancerProfileAssistantStepStatisticAndGoToNextStep(step_id, profile_id, skip_if_not_completed, set_online) {

    unregisterJqueryResponder();
    jQuery.post('/profile_freelancer/ajax.php',
        'action=update_freelancer_profile_assistant_step_statistic&step_id=' + step_id + '&profile_id=' + profile_id + '&skip_if_not_completed=' + skip_if_not_completed + '&set_online=' + set_online,
        function (result) {
            var json = result;

            if (json.response) {
                if (json.next_step_number > 0) {
                    tryToGoToAssistantStep(json.next_step_number, profile_id);
                } else {
                    window.location.href = '/profile_freelancer/edit.php?ad';
                }
            } else if (json.error) {
                alert(json.error);
            }
        }, 'json');
}

function updateFreelancerProfileAssistantStepStatisticAndGoToNextStepForReferences(step_id, profile_id, skip_if_not_completed) {

    jQuery.post('/profile_freelancer/references.php',
        'action=save_no_reference&' + jQuery('#refs_form_no_reference').serialize(),
        function (result) {
            var json = result;

            if (json.error) {
                // update
                jQuery('#marker_refs').html(json.result_html);
            } else {
                updateFreelancerProfileAssistantStepStatisticAndGoToNextStep(step_id, profile_id, skip_if_not_completed);
            }
        }, 'json');
}

