
function setStrengthMeter(password_element) {

    let meter_element = "strength_meter";
    let info_element = "strength_info_element";
    let info_container = "strength_info_container";

    //create meter element
    jQuery("#"+password_element).after(
        jQuery("<div>")
            .attr("id",meter_element)
            .addClass()
            .css({height: "4px", position: "relative", top: "3px"})
    );

    jQuery("#"+password_element).after(
        jQuery("<small>")
            .attr("id",info_container)
            .addClass("text-secondary pull-right margin-top-xs")
            .css("display", "none")
            .append(["Passwortstärke ", jQuery("<b>").attr("id",info_element).addClass("text-color")])
    ).focusin(function() {
        jQuery("#"+info_container).fadeIn();
    }).focusout(function() {
        jQuery("#"+info_container).fadeOut();
    });

    // create meter object
    let strength_meter = new FL_PasswordStrengthMeter(password_element, meter_element, info_element);

    // set levels
    // strength_meter.addClassification(name, score, color, reset);
    strength_meter.addClassification("Schwach", 0, "#D53333", true);
    strength_meter.addClassification("Mittel", 12, "#EB643D", false);
    strength_meter.addClassification("Gut", 18, "#FFBE0B", false);
    strength_meter.addClassification("Stark", 22, "#698E49", false);

    // show meter
    strength_meter.show();
}
