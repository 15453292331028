function dataLayer_survey_from_internal_mail24(){
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ event_data: null });
	dataLayer.push({
		'event': "freelance.dataLayer_survey2024_from_internal_mail",
		'event_type': "custom",
		'event_data': {
			'name': 'Freelancer-Studie 2024',
			'url' : 'mail/dialog.php'
		}
	});
}

function dataLayer_survey_from_marktstudien24(){
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ event_data: null });
	dataLayer.push({
		'event': "freelance.dataLayer_survey2024_from_marktstudien",
		'event_type': "custom",
		'event_data': {
			'name': 'Freelancer-Studie 2024',
			'url' : 'marktstudien.html'
		}
	});
}

function dataLayer_survey_from_internal_mail(){
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ event_data: null });
	dataLayer.push({
		'event': "freelance.dataLayer_survey2023_from_internal_mail",
		'event_type': "custom",
		'event_data': {
			'name': 'Freelancer-Studie 2023',
			'url' : 'mail/dialog.php'
		}
	});
}

function dataLayer_survey_from_marktstudien(){
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ event_data: null });
	dataLayer.push({
		'event': "freelance.dataLayer_survey2023_from_marktstudien",
		'event_type': "custom",
		'event_data': {
			'name': 'Freelancer-Studie 2023',
			'url' : 'marktstudien.html'
		}
	});
}