// a unix timestamp that shows when was the last user input
/**
 * @category: voucher
 */
var account_typing_time = null;

/**
 * checks if a given voucher code is valid
 * @category: voucher
 */
function account_check_voucher(show_immediately, reload_prices)
{
    unregisterJqueryResponder();
    if(jQuery('#' + 'voucher_code').length)
    {
        jQuery('#' + 'voucher_ok').hide();
        jQuery('#' + 'voucher_hint').hide();
        jQuery('#' + 'voucher_error').hide();
        jQuery('#' + 'voucher_checking').show();

        var voucher_code = jQuery('#' + 'voucher_code').val();
        var subscription_type = jQuery('input[name="package"]:checked').val();
        if(voucher_code != "" && subscription_type === undefined)
        {
			jQuery('#' + 'voucher_checking').hide();
			jQuery('#' + 'voucher_hint').show();
			jQuery('#' + 'voucher_hint').html('Bitte wählen Sie eine Mitgliedschaft aus, um einen Gutschein zu verwenden.');

		}
		else if(voucher_code != "" && subscription_type != null && subscription_type > 0)
		{
            jQuery.post('/account/ajax.php',
                'action=check_voucher&voucher_code=' + voucher_code + '&package=' + subscription_type,
                function (r) {
                    if (account_typing_time == null) {
                        // we receive here a json response
                        var result_array = r;
                        var result = result_array["result"];
                        var message = result_array["message"];

                        if (reload_prices) {
							jQuery.post('/account/create.php',
								'action=package&voucher_code=' + voucher_code,
								function (s) {
									var subscription_prices = s["html"];
									jQuery('#subscription_prices').html(subscription_prices);
								}, 'json');
						}

                        // everything is ok
                        if (result == 2) {
							jQuery('#' + 'voucher_checking').hide();
							jQuery('#' + 'voucher_error').hide();
							jQuery('#' + 'voucher_ok').show();
							jQuery('#' + 'voucher_hint').show();
							jQuery('#' + 'voucher_hint').html(message);
                        }
                        // either the voucher was not found or the user can't use it
                        else if (result == 1) {
                            //if (show_immediately)
                            {
                                jQuery('#' + 'voucher_checking').hide();
                                jQuery('#' + 'voucher_ok').hide();
                                jQuery('#' + 'voucher_error').show();
                                jQuery('#' + 'voucher_hint').show();
                                jQuery('#' + 'voucher_hint').html(message);
                            }
                            //else
                            {
                                //setTimeout("account_check_voucher(" + true + ")", 10);
                            }
                        }
                    }
                }, 'json');
        }
        else
        {
            jQuery('#' + 'voucher_checking').hide();
            jQuery('#' + 'voucher_error').hide();
            jQuery('#' + 'voucher_ok').hide();
            jQuery('#' + 'voucher_hint').hide();

			jQuery.post('/account/ajax.php',
				'action=delete_voucher',
				function (r) {
					check_automatic_promotion_code('voucher_code', 'promo_description_');
				});
        }
    }
}

/**
 * @category: voucher
 */
function account_set_voucher_hint(message)
{
	jQuery('#' + 'voucher_hint').show();
	jQuery('#' + 'voucher_hint').html(message);
}

/**
 * handler for onkeyup, saves the last time when the user has anything typed
 *
 * @category: voucher
 */
function account_voucher_typing()
{
    unregisterJqueryResponder();
	if(jQuery('#' + 'voucher_code').length) {
		var start = false;
		jQuery('#' + 'voucher_checking').show();
		jQuery('#' + 'voucher_error').hide();
		jQuery('#' + 'voucher_ok').hide();

		if(account_typing_time == null)
		{
			start = true;
		}

		// refresh the time that shows that the user is still typing
		account_typing_time = (new Date()).getTime();

		if(start)
		{
			account_is_voucher_typing();
		}
	}
}

/**
 * checks if the user is still typing
 *
 * @category: voucher
 */
function account_is_voucher_typing()
{
	// time to wait for the user after he has finished typing
	var timeToWait = 750;

	if(account_typing_time != null)
	{
		// the time now as unix timestamp
		var now = (new Date()).getTime();

		if(now - account_typing_time > timeToWait)
		{
			// check what the user has typed
			account_check_voucher(false, true);
			account_typing_time = null;
		}
		else
		{
			// execute myself after 1/2 sec
			setTimeout(function() {
                account_is_voucher_typing();
			}, 150);
		}
	}
}

/**
 * @category: voucher
 */
function clear_automatic_promotion_description(id_prefix)
{
	if(id_prefix == null) {
		return;
	}

	jQuery('#' + 'body [id^='+id_prefix+']').each(function() {
		jQuery(this).html('');
	});
}

/**
 * @category: voucher
 */
function get_automatic_promotion_code(target_field, duration, discount_target_desc_prefix)
{
	if(jQuery('#' + target_field).length == 0) {
		return;
	}

	jQuery.post('/account/ajax.php',
		'action=get_automatic_promotion_code&duration=' + duration,
		function (transport) {
			j = transport;
			if (j['error'] == 0) {
				clear_automatic_promotion_description(discount_target_desc_prefix);

				if (jQuery('#' + target_field).length) {
					jQuery('#' + target_field).val(j['value']);
				}

				for (i = 0; i < j['discounts'].length; i++) {
					var oldprice = 'price_' + j['discounts'][i]['type'];
					var oldprice_month = 'price_month_' + j['discounts'][i]['type'];
					var fieldname = discount_target_desc_prefix + j['discounts'][i]['type'];
					var fieldvalue = j['discounts'][i]['discount'];
					var fieldpricevalue = j['discounts'][i]['price'];
					var fieldprice = 'promo_price_' + j['discounts'][i]['type'];

					if (jQuery('#' + fieldprice).length) {
						jQuery('#' + fieldprice).val(fieldpricevalue).change();
					}
					if (jQuery('#' + fieldname).length) {
						jQuery('#' + fieldname).html(fieldpricevalue + '€');
					}
					if (jQuery('#' + oldprice).length && jQuery('#' + oldprice).hasClass('line-through') === false) {
						jQuery('#' + oldprice).addClass('line-through');
						jQuery('#' + oldprice_month).addClass('line-through');
					}
				}
				if (jQuery('#' + 'single_license_count').length) {
					update_package_summary();
				}

				var voucher_code = jQuery('#' + 'voucher_code').val();
				var subscription_type = jQuery('input[name="package"]:checked').val();
				if (voucher_code != "" && subscription_type != null && subscription_type > 0) {
					account_check_voucher(true, false);
				}
			}
		}, 'json');
}

/**
 * @category: voucher
 */
function check_automatic_promotion_code(target_field, discount_target_desc_prefix)
{
	jQuery('[id^=' + discount_target_desc_prefix + ']').each(function () {
		let abo_id = jQuery(this).attr('id').substring(discount_target_desc_prefix.length);
		get_automatic_promotion_code('voucher_code', abo_id, discount_target_desc_prefix);
	});
}
