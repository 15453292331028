var GeneralTermsManager = function (classname) {
    this.classname = classname;

    this.highlightChanges = function () {
        jQuery('.' + this.classname).each(function () {
            jQuery(this).css("color", "red");
        });
    };

    this.removeHighligths = function () {

    };
};