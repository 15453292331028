class SnippetUpdater {
    constructor(data) {
        this.data = data;
    }

    updateSnippet() {
        if (this.data.type == "template") {
            let item = jQuery('#' + this.data.identifier);
            if (item.length > 0) {
                item.html(this.data.item);
            }
        } else if (this.data.type == "popup") {
            let self = this;
            setTimeout(function() {
                modal.show_message({
                    text: self.data.item,
                    attr: {id: "fl_visible"}
                }, 15000, 'success');
            }, 500);
        }

        initBootstrapSwitch();
    }
}
