var breakpoint = {};
var width;

breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
};

window.addEventListener("load", function() {

    // init bootstrap tooltips
    if (jQuery('[data-toggle="tooltip"]').length > 0) {
        initTooltip();
    }

    // init sidebar toggle
    if (jQuery('.js-minimized-toggle').length > 0) {
        initSidebarToggle();
    }

    // init panel-group
    if (jQuery('.panel-group').length > 0) {
        initPanelGroup();
    }

    // init bootstrap select
    if (jQuery('select').length > 0) {
        initBootstrapSelect();
    }

    // init textarea max length
    if (jQuery('textarea[maxlength]').length > 0) {
        initMaxLengthTextArea();
    }

    // init input max length
    if (jQuery('input[maxlength]').length > 0) {
        initMaxLengthInput();
    }

    // init extended search (limitation to one submit button)
    if (jQuery('#project-search-extended').length > 0) {
        initSubmitButtonLimitation();
    }

    // init editable form
    if (jQuery('.form-editable').length > 0) {
        initEditableForm();
    }

    initNavAside();

    width = jQuery(window).width(), height = jQuery(window).height();
});

window.addEventListener("load", function() {

    // check for breakpoints
    breakpoint.refreshValue();

    // check if .js-panels-equal is set
    if (breakpoint.value != 'default') {
        checkPanelsEqualHeight();
    }

    // keep checking for equal height on window resize
    jQuery(window).on('resize', function() {
        breakpoint.refreshValue();

        if (breakpoint.value != 'default') {
            checkPanelsEqualHeight();
        }

        if (jQuery('#mobile-nav').length > 0) {
            if(jQuery(window).width() != width && jQuery(window).height() != height) {
                jQuery('#mobile-nav').modal('hide');
            }
        }
    });
});

function initNavAside() {

    jQuery('.nav-aside-small-link').on('mouseenter', function(e) {
        jQuery('.nav-aside-small-sub').hide();
        jQuery(this).find('.nav-aside-small-sub').show();
    });

    jQuery('.nav-aside-small-sub').on('mouseleave', function(e) {
       jQuery(this).hide();
    });
}

/**
 * initialize textarea max length plugin
 */
function initMaxLengthTextArea() {

    jQuery('textarea[maxlength]').maxlength({
        alwaysShow: true,
        appendToParent: true,
        placement: 'bottom',
        message: 'Noch max. %charsRemaining% Zeichen übrig.',
        twoCharLinebreak: false
    });
}

function initMaxLengthInput() {

    jQuery('input[maxlength]').maxlength({
        alwaysShow: true,
        appendToParent: true,
        placement: 'bottom',
        twoCharLinebreak: false
    });
}
/**
 * initialize editable form
 */
function initEditableForm() {

    jQuery('.js-set-editable').on("click", function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        var formName = jQuery(this).data('form');
        var form = jQuery('form[name="' + formName + '"]');

        form.addClass('editable');
        form.find('.form-control').removeAttr('disabled');
        form.find('.bootstrap-select').removeClass('disabled').find('.btn').removeClass('disabled');
        form.find('select').removeAttr('disabled');
        form.find('.js-invisible').removeClass('js-invisible').addClass('js-visible');

        //[OB] Added support for checkboxes
        form.find('input[type="checkbox"]').removeAttr('disabled').removeClass('disabled');

        //[RT] Added to let disabled fields in form, otherwise all fields are whether enabled or disabled.
        ///profile_freelancer/edit.php Kontaktdaten -> Bundesland field has to be read-only all the time
        form.find('[fl-skip-enable]').attr('disabled', 'disabled');
        jQuery('.selectarea').selectpicker('refresh');
    });

    jQuery('.js-unset-editable').on("click", function(e) {
        e.preventDefault();

        var formName = jQuery(this).data('form');
        var form = jQuery('form[name="' + formName + '"]');

        form.removeClass('editable');
        form.find('.form-control').attr('disabled', 'disabled');
        form.find('.bootstrap-select').addClass('disabled').find('.btn').addClass('disabled');
        form.find('select').attr('disabled', 'disabled');
        form.find('.js-visible').removeClass('js-visible').addClass('js-invisible');

        //[OB] Added support for checkboxes
        form.find('input[type="checkbox"]').attr('disabled', 'disabled');
    });
}

/**
 * initialize limitation to one submit button when external search is shown
 */
function initSubmitButtonLimitation() {

    var toggle = jQuery('a[href="#project-search-extended"]');

    toggle.on('click', function(e) {
        if (jQuery(this).hasClass('collapsed')) {
            // jQuery(this).parents('form').find('button[type="submit"]').first().hide();
            // jQuery('#search_simple').prop("onclick", set_extended_search()).on("click", set_extended_search());
            jQuery('#search_simple').attr("onclick", "set_extended_search();");
        } else {
            // jQuery(this).parents('form').find('button[type="submit"]').first().show();
            // jQuery('#search_simple').prop("onclick", null).off("click");
            jQuery('#search_simple').attr("onclick", "return true;");
        }
    });
}

/**
 * initialize bootstrap select plugin
 */
function initBootstrapSelect() {

    // jQuery('select').selectpicker();
    jQuery('select').not('.no-selectpicker select').selectpicker();
}

/**
 * initialize panel group active-class
 */
function initPanelGroup() {

    jQuery('.panel-group').find('.panel').on('hidden.bs.collapse', function(e) {
        jQuery(this).find('.panel-heading').removeClass('active');
    });

    jQuery('.panel-group').find('.panel').on('shown.bs.collapse', function(e) {
        jQuery(this).find('.panel-heading').addClass('active');
    });
}

/**
 * initialize sidebar toggle (minimized)
 */
function initSidebarToggle() {
    var renew_preferences_needed = false;
    var tooltip = "";

    if (typeof Cookiebot != "undefined") {
        if (Cookiebot.consent.preferences) {
            defineSidebarToggle();
        } else {
            window.addEventListener('CookiebotOnDialogDisplay',CookiebotOnDialogDisplayCallback);
            var menu_toggle = jQuery(".js-minimized-toggle");
            tooltip = menu_toggle.attr("data-original-title");
            menu_toggle.attr("data-original-title","Diese Funktion ist deaktiviert, weil Sie die Präferenz-Cookies nicht akzeptiert haben. Sie können Ihre Einwilligung hier ändern.")
                .on("click",function () {
                    renew_preferences_needed = true;
                    Cookiebot.renew();
                    window.addEventListener('CookiebotOnAccept',CookiebotOnAcceptCallback);
                });
        }
    } else {
        defineSidebarToggle();
    }

    function CookiebotOnDialogDisplayCallback (e) {
        if (renew_preferences_needed) {
            jQuery("#CybotCookiebotDialogBodyLevelButtonPreferences").prop("checked",true);
        }
        renew_preferences_needed = false;
    }

    function CookiebotOnAcceptCallback(e) {
        jQuery(".js-minimized-toggle").unbind("click").attr("data-original-title",tooltip);
        window.removeEventListener('CookiebotOnDialogDisplay',CookiebotOnDialogDisplayCallback);
        window.removeEventListener('CookiebotOnAccept',CookiebotOnAcceptCallback);
        initSidebarToggle();
    }

}

function defineSidebarToggle() {
    jQuery('.js-minimized-toggle').on("click", function(e) {
        e.preventDefault();

        jQuery('body').toggleClass('minimized');

        if (jQuery('body').hasClass('minimized')) {
            setCookie('west_navigation', 'minimized');
        } else {
            setCookie('west_navigation', 'not_minimized');
        }
    });
}

/**
 * initialize tooltips
 */
function initTooltip() {
    jQuery('[data-toggle="tooltip"]').tooltip();
    jQuery('.tooltip-unlink').on("click", function (e) {
        e.preventDefault();
    });
}

/**
 * initialize mobile navigation with sidr plugin
 */
function initMobileNavigation() {

    jQuery('#js-nav-mobile-toggle').sidr({
        side: 'left',
        displace: false
    });
}

/**
 * set panels inside container equal to largest panel
 */
function checkPanelsEqualHeight() {

    if (jQuery('.js-panels-equal').length > 0) {
        jQuery.each(jQuery('.js-panels-equal'), function(i, v) {
            var panelContainer = jQuery(this),
                panels = panelContainer.find('.panel'),
                maxHeight = 0;

            // find largest panel
            jQuery.each(panels, function(index, value) {
                jQuery(this).find('.panel-body').removeAttr('style');

                if (jQuery(this).height() > maxHeight) {
                    maxHeight = jQuery(this).height();
                }
            });

            // adjust height of other panels
            jQuery.each(panels, function(index, value) {
                if (jQuery(this).find('.panel-heading').length > 0) {
                    var padding = parseInt(jQuery(this).find('.panel-heading').css('paddingTop').replace('px', '')) + parseInt(jQuery(this).find('.panel-heading').css('paddingBottom').replace('px', ''));
                } else if (jQuery(this).find('.panel-footer').length > 0) {
                    var padding = parseInt(jQuery(this).find('.panel-footer').css('paddingTop').replace('px', '')) + parseInt(jQuery(this).find('.panel-footer').css('paddingBottom').replace('px', ''));
                }

                if (jQuery(this).height() < maxHeight) {
                    if (jQuery(this).find('.panel-heading').length == 0) {
                        jQuery(this).find('.panel-body').css({
                            'height': maxHeight - (jQuery(this).find('.panel-footer').height() + padding)
                        });
                    } else if (jQuery(this).find('.panel-footer').length == 0) {
                        jQuery(this).find('.panel-body').css({
                            'height': maxHeight - (jQuery(this).find('.panel-heading').height() + padding)
                        });
                    } else {
                        jQuery(this).find('.panel-body').css({
                            'height': maxHeight - (jQuery(this).find('.panel-heading').height() + jQuery(this).find('.panel-footer').height() + (padding * 2))
                        });
                    }
                }
            });
        });
    };
}
