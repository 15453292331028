/**
 * This file is depricated. Please refer to module.multiaccount.30.js.
 */



/** 
 * saves the managed users of a (sales) user and refreshes the item
 * 
 * @param userid
 * @return void
 */
function multiaccount_save_managed_users(userid) {
	jQuery.post('/multiaccount/ajax.php',
		'action=save_managed_users&user_id='+userid+'&'+jQuery('#' + 'managed_users_form_'+userid).serialize(),
		function(r) {
			multiaccount_refresh_user(userid);
		}
	);
}


/**
 * saves multiaccount settings to the database
 */
function multiaccount_save_settings() {

	jQuery('#marker_settings').load('/multiaccount/settings.php',
		urlParamsToObject('action=save&' + jQuery('#' + 'settings_form').serialize()));
}


/**
 * refreshes the multiaccount settings item
 * @return
 */
function multiaccount_refresh_settings() {
	jQuery('#marker_settings').load('/multiaccount/settings.php',
		urlParamsToObject('action=refresh_settings'));
}


/**
 * adds a freelancer link to the multiaccount
 * 
 * @param profile_id
 * @return
 */
function multiaccount_add_freelancer_link(profile_id)
{
	jQuery('#freelancer_list').load('/multiaccount/ajax.php',
		urlParamsToObject('action=add_freelancer_link&profile_id='+profile_id));
}


/**
 * removes a freelancer link from a multiaccount
 * 
 * @param link_id
 * @return
 */
function multiaccount_delete_freelancer_link(link_id)
{
	if (confirm("Möchten Sie die Verbindung zu diesem Freelancer wirklich löschen?")) {
		jQuery.post('/multiaccount/ajax.php',
			'action=delete_freelancer_link&link_id='+link_id,
			function(r) {
				jQuery('#freelancer_link_'+link_id).remove();
			}
		);
	}
}



/**
 * set the link from a company to a freelancer either visible or invisible
 * 
 * @param link_id
 * @return void
 */
function multiaccount_toggle_freelancer_link_visibility(link_id)
{
	jQuery('#freelancer_link_'+link_id).load('/multiaccount/ajax.php',
		urlParamsToObject('action=toggle_freelancer_link_visibility&link_id='+link_id));
}


/**
 * refreshes a freelancer link item
 * 
 * @param link_id
 * @return void
 */
function multiaccount_refresh_freelancer_link(link_id)
{
	jQuery('#freelancer_link_' + link_id).load('/multiaccount/ajax.php',
		urlParamsToObject('action=refreh_freelancer_link&link_id=' + link_id));
}

/**
 * saves the right overrides for a specific multiaccount user
 * 
 * @param int
 * @return void
 */
function multiaccount_save_user_overrides(form_element_id, item_id) {
	jQuery('#' + item_id).load('/multiaccount/ajax.php',
		urlParamsToObject(jQuery('#' + form_element_id).serialize())
	);
}

/**
 * updates a specific multiaccount user's details
 * 
 * @param int
 * @return void
 */
function multiaccount_update_user(form_element_id, item_id) {

	jQuery('#' + item_id).load('/multiaccount/ajax.php',
		urlParamsToObject(jQuery('#' + form_element_id).serialize())
	);
				
}
/**
 * resets the passwort for a specific multiaccount user
 * 
 * @param int
 * @return void
 */
function multiaccount_reset_password(user_id) {
	if (confirm($T('multiaccount:reset_password_confirmation')))
	{
		jQuery.post("/multiaccount/ajax.php",
			'action=reset_password&user_id=' + user_id,
			function (r) {

				if (r == "FAILURE") {
					alert($T('multiaccount:reset_password_failure'));
				} else {
					alert($T('multiaccount:reset_password_success'));
					jQuery('#password_reset_' + user_id).html(r);
				}
			}
		);
	}
}





/**
 * refreshes a specific multiaccount user item
 * 
 * @param int
 * @return void
 */
function multiaccount_refresh_user(user_id)
{
	jQuery('#userdiv_'+user_id).load('/multiaccount/ajax.php?action=refresh_company_user&user_id='+user_id);
}


/**
 * deletes a specific multiaccount user
 * 
 * @param int
 * @return void
 */
function multiaccount_delete_user(user_id, parent_user_id) {
	if (confirm($T('multiaccount:delete_user_confirmation')))
	{
		jQuery.post("/multiaccount/ajax.php",
			'action=delete_company_user&user_id='+user_id,
			function(r) {
				if (r == "OK")
				{
					jQuery('#' + 'userdiv_'+user_id).remove();

					// now check if we have to eliminate the "create new user" thing
					jQuery.post('/multiaccount/ajax.php?action=get_number_of_remaining_company_users&user_id=' + parent_user_id,
						function (r) {
							var json = r;
							if (json['number_of_remaining_company_users'] > "0") {
								jQuery('#' + 'new_user_link').show();
								jQuery('#account_upgrade').hide();
							} else {
								jQuery('#new_user_link').hide();
								if (json['b_upgrade_possible']) {
									jQuery('#' + 'account_upgrade').show();
								}
							}
						}, 'json');

				}
				else
				{
					alert("this user can't be deleted by you!\n\n"+r);
				}
			}
		);
	}
}


/**
 * adds a new multiaccount user to the database
 * 
 * @return void
 */
function multiaccount_add_user(user_id) {
	// first of all check if the username and email are still available
	jQuery.post("/multiaccount/ajax.php", {
		method :'post',
		parameters :'action=check_username_and_email&' + jQuery('#' + 'user_create_form').serialize(),

		onSuccess : function(r) {

			if (r == "")
			{
				jQuery('#multiaccount_new_user').load('/multiaccount/ajax.php',
					urlParamsToObject('action=create_company_user&' + jQuery('#' + 'user_create_form').serialize()),
					function () {
						jQuery('#user_create_form').reset();
						jQuery('#form-box-data').hide();
						jQuery('#show-box-data').show();

						if (jQuery('#div_no_user_yet')) {
							jQuery('#div_no_user_yet').hide();
						}
					});
			}
			else
			{
				alert(r);
			}

		}
	});
}
