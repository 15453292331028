function customer_opinion_js(formId) {

	if(jQuery('#customer_opinion_text').val().trim() == ""){
		jQuery('.form-group.required').addClass('error');
		jQuery('#error_placeholder_customer_opinion_text').text("Das Feld darf nicht leer sein.");
		return false;
	} 

	var comment = jQuery('#customer_opinion_text').val();

	jQuery.post('/ajax.php',
		jQuery('#' + formId).serialize(),
		function (transport) {
			var response = transport;

			if(response.error) {
                jQuery('#customer_opinion_form_box').html(response['result']);
                jQuery('#customer_opinion_text').val(comment);
				if (response.error == 2) {
                    jQuery('#save').click();
				} else {
                    jQuery('.form-group.required').addClass('error');
                }
			} else {
				jQuery('#addReview').hide();
				jQuery('.form-group.required').removeClass('error');
				var message_text = "Vielen Dank für Ihr Feedback.<br> Bevor wir Kundenstimmen veröffentlichen, werden diese von uns geprüft. Ihr Feedback wird in Kürze an dieser Stelle verfügbar sein.";
				modal.show_message(message_text, '1000000', 'success', 'OK »', null, "window.location = '/main/customer_opinion.php'" );
			}
		}, 'json');

}