class Subscription {

    constructor() {
        this.url = "/account/ajax_subscription.php"; // the url of all actions
        this.formId = ""; // a form id; the content of the form will be send to the controller
        this.resultId = ""; // a div that holds a result information, in case of error for example
        this.displayId = ""; // a div that holds  dispaly information for a concrete action
        this.itemId = ""; // a div that holds the whole item
        this.endPriceId = "customer_end_price"; //
    }

    updateEndPrice() {
        jQuery("#" + this.endPriceId);
    }
}

/**
 * Updates all package prices.
 * @param number_of_users
 */
function account_update_prices(number_of_users)
{
	setSessionKey("number_of_users", number_of_users);
    jQuery('#marker_prices').load('/account/ajax.php',
        urlParamsToObject('action=update_prices&number_of_users=' + number_of_users)
    );
}

/**
 * Saves dynamically the selected package / price in the database.
 * We need this for the validation of the voucher code.
 */
function account_update_package(selected_package)
{
    jQuery.post('/account/ajax.php',
        'action=update_package&package=' + selected_package + '&backtooverview=' + jQuery('input[name=backtooverview]').val(),
        function (result) {
            jQuery('#' + 'marker_prices').html(result);
        });
}

function account_update_package_upgrade(selected_package)
{
    jQuery.post('/account/ajax.php',
        'action=update_package_upgrade&package=' + selected_package,
        function (result) {
            jQuery('#' + 'marker_package_single_license_selection').html(result);
        });
}




/**
 * perform the cancellation of a premium account
 *
 * @return void
 */
function account_perform_cancellation()
{
	jQuery.post('/account/ajax.php',
		'action=cancel_premium_account&' + jQuery('#cancellation_form').serialize(),
		function (r) {

			if (r != '1000' && r != '1001') {
				account_refresh_package();

				jQuery('#' + 'payment_message_title').html(r["message_title"]);
				jQuery('#' + 'payment_message_text').html(r["message_text"]);
				jQuery('#' + 'payment_message').show();
				modal.show_message("Ihre Mitgliedschaft wurde gekündigt.", 1000000, 'success');
				flScrollTo('#content_box_header');
			} else {
				jQuery('#' + 'cancellation_error_0').hide();
				jQuery('#' + 'cancellation_error_1').hide();

				jQuery('#cancellation_confirm + label').css('border', '1px solid #dcdcdc');
				jQuery('[id^="cancellation_reason"] + label').css('border', '1px solid #dcdcdc');

				if (r == "1000") {
					flScrollTo('#edit_package_steps');
					jQuery('#' + 'cancellation_error_0').show();
					jQuery('[id^="cancellation_reason"] + label').css('border', '1px solid #da4937');
				}

				if (r == "1001") {
					jQuery('#' + 'cancellation_error_1').show();
					flScrollTo('#cancellation_error_1');
					jQuery('#cancellation_confirm + label').css('border', '1px solid #da4937');
				}

				if (r == "1002") {
					jQuery('#' + 'cancellation_error_2').show();
					flScrollTo('#cancellation_error_2');
					jQuery('#cancellation_confirm + label').css('border', '1px solid #da4937');
				}
			}
		}, 'json');
}

/**
 * perform the cancellation of a premium account per email
 *
 * @return void
 */
function account_perform_schedule_cancellation()
{
	jQuery.post('/account/ajax.php',
		'action=account_perform_schedule_cancellation',
		function (r) {
			modal.show_message("Ihre Kündigung ist vorgemerkt.<br />Wir bedauern Ihre Entscheidung und werden Ihre Kündigung umgehend bearbeiten.", 1000000, 'success');
			account_refresh_package();
			flScrollTo('#content_box_header');
		});
}

/**
 * perform the upgrade of a premium account into a higher package
 *
 * @return void
 */
function account_perform_upgrade()
{
	jQuery.post('/account/ajax.php',
		'action=upgrade_premium_account&' + jQuery('#' + 'package_form').serialize(),
		function (r) {

			let onclick = '';
			if (r == "OK") {
				onclick = "window.location='/account/edit_payment_data.php'";
				modal.show_message($T("abo:create_text/upgrade_success"), 50000, 'success', 'ok', null, onclick);
				sleep(5000);
				document.location.href = '/account/edit_payment_data.php';
			} else if (r == "OK-NOT-VERIFIED") {
				onclick = "window.location='/account/edit_payment_data.php'";
				modal.show_message($T("abo:create_text/upgrade_success_not_verified"), 1000000, 'info', 'ok', null, onclick);
			} else {
				onclick = "window.location='/account/edit_payment_data.php'";
				modal.show_message($T("abo:create_text/upgrade_error"), 1000000, 'error', 'ok', null, onclick);
			}
		});
}


function account_upgrade_membership(selected_package, number_of_licenses) {

    var number_of_licenses = typeof number_of_licenses == "undefined" ? 0 : number_of_licenses;

	jQuery.post('/account/create.php',
		'action=upgrade_membership&abo_type_id=' + selected_package + '&number_of_licenses=' + number_of_licenses,
		function (result) {

			var result_json = result;
			jQuery('#marker_prices').html(result_json['html']);
		}, 'json');
}

function account_upgrade_licenses() {

    var number_of_licenses = jQuery('#additional-licence [name=number_of_licenses]').val();
    var selected_package   = jQuery(".package-option [name='package']:checked").val();

    account_upgrade_membership(selected_package, number_of_licenses);
}

function update_package_summary() {
	unregisterJqueryResponder();

	var abo_type_id = jQuery('input[name="package"]:checked').val();
	var package_changed = jQuery('input[name="previous-package"]').val() !== abo_type_id;

	jQuery('input[name="previous-package"]').val(abo_type_id);

	jQuery.ajax({
        url: '/account/ajax.php',
        type: 'POST',
        data: {
			action: 'update_package_summary',
			abo_type_id: abo_type_id,
			abo_price: jQuery('#promo_price_' + abo_type_id).val(),
			package_changed: package_changed,
			single_license_count: jQuery('#single_license_count').length ? jQuery('#single_license_count').val() : -1,
        },
        dataType: 'json',
        success: function (json) {
			if (json['error'] === 0) {
				jQuery('#ajax-create_package_summary').html(json['summary']);

				if (json['license'] !== undefined) {
					jQuery('#ajax-create_package_license').html(json['license']);
				}
			}
        }
    });
}


function paypal_action(action)
{
	jQuery.post('/account/ajax.php',
		'action=' + action,
		function (r) {
			json = r;

			if (json['error'] == 0) {
				jQuery('.paypal_cancel_btn').hide();
				window.onbeforeunload = function () {
				};
				window.location.href = '/account/edit_payment_data.php';
			} else {
				modal.show_message(json['result'], 1000000, 'error');
			}
		}, 'json');
}

function cancel_create_billing_agreement(abo_type_id) {

	jQuery('form[name="paypal_cancel_create_billing_agreement"] input[name="abo_type_id"]').val(abo_type_id);
	jQuery('form[name="paypal_cancel_create_billing_agreement"]').submit();
}
