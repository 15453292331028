class SearchFilter {

    constructor() {
        this.url = '/search/ajax.php';
    }

    /**
     * Show modal popup window
     * @param profile_id
     */
    show_blacklist_freelancer_profile_modal (profile_id) {

        var watchlist_icon = jQuery('#profile_blacklisted-' + profile_id);

        if (watchlist_icon.hasClass('red')) {
            modal.showFLPopup(
                'request',
                '/ajax/popup_template.php',
                'action=blacklist_profile_remove&i_profile_id=' + profile_id,
                'Freelancer Profil wieder anzeigen'
            );
        } else {
            modal.showFLPopup(
                'request',
                '/ajax/popup_template.php',
                'action=blacklist_profile&i_profile_id=' + profile_id,
                'Freelancer Profil ausblenden'
            );
        }
    }

    addCriteriaToBlacklist (criteriaType, type, objectId) {
        // to add criteria in the blacklist of the target profile
        var to_hide = 0;

        jQuery.post(this.url,
            {
                action: 'add_criteria_to_blacklist',
                criteria_type: criteriaType,
                type: type,
                object_id: objectId,
                hide: to_hide
            },
            function (transport) {

                var json = transport;
                if (json['error'] === 0) {

                    if (jQuery('#profile_view').length == 1) {
                        // we are on Freelnacer's list page

                        jQuery('.blacklist-ban').addClass('red');
                        modal.show_message(json["result_message"], 5000, "success");
                        jQuery('.blacklist-ban').attr("data-original-title", 'Profil wieder in der Freelancerliste anzeigen');
                        // window.location = '/network/ausschlussliste';

                    } else {

                        jQuery.each(json['hide_objects'], function (index, object_id) {
                            jQuery('#object_id_' + object_id).hide();
                        });

                        modal.show_message(json["result_message"], 5000, "success");
                    }

                }
            }, 'json');
    }

    deleteCriteriaFromBlacklist (id) {
        jQuery.post(this.url,
            'action=delete_criteria_from_blacklist&search_filter_criteria_id=' + id,
            function (transport) {
                var j = transport;

                if (j['error'] === 0) {

                    if (jQuery('#profile_view').length == 1) {
                        // we are on Freelnacer's list page

                        jQuery('.blacklist-ban').removeClass('red');
                        jQuery('.blacklist-ban').attr("data-original-title", 'Freelancer Profil ausblenden');
                        modal.show_message('Ihre Ausschlussliste wurde erfolgreich aktualisiert.', 5000, 'success');

                    } else {
                        // we are on blacklist page

                        jQuery('#filter_' + id).hide();
                        if (j['content'] != undefined) {
                            if (j['type'] == 2)
                                jQuery('#blacklist_company').html(j['content']);
                            else if (j['type'] == 1) {
                                jQuery('#blacklist_freelancer').html(j['content']);
                            }
                        }
                        modal.show_message('Ihre Ausschlussliste wurde erfolgreich aktualisiert.', 5000, 'success');
                        //window.location=document.URL;
                        return false;
                    }
                } else {
                    // alert(j['error_message']);
                }
            }, 'json');
    }

    deleteMarkedBlacklist (type) {
        var checkboxes = [],
            allCheckboxes = [],
            i = [];
        jQuery('.blacklist-checkbox_' + type + ':checked').each(function () {
            checkboxes.push(jQuery(this).val());
        });
        jQuery('.blacklist-checkbox_' + type).each(function () {
            allCheckboxes.push(jQuery(this).val());
        });

        if (checkboxes.length > 0) {
            jQuery.post(this.url, 
                'action=delete_criteria_from_blacklist_mass&filter_criteria_ids=' + checkboxes.join(','),
                function (transport) {
                    var j = transport;
                    if (j['error'] === 0) {
                        modal.show_message('Ihre Ausschlussliste wurde erfolgreich aktualisiert.', 5000, 'success');

                        checkboxes.map(function (item) {
                            jQuery('#filter_' + item).remove();
                        });
                    }
                }, 'json');

            jQuery('#blacklist_remove_all-' + type).addClass('disabled');
            jQuery('#blacklist-checkbox_all-' + type).prop('checked', false);
            if (checkboxes.length === allCheckboxes.length) {
                jQuery('#list_container_' + type).remove();
            }
        }
    }

    get_blacklist (type, target, force) {
        if (jQuery('#' + target).html() == "" || force == true) {
            jQuery.post(this.url,
                {
                    action: 'get_blacklist',
                    type: type
                },
                function (t) {
                    j = t;

                    if (j['error'] == 0) {
                        jQuery('#' + target).html(j['result']);
                    }

                    return false;
                }, 'json');
        }
    }

}

var search_filter = new SearchFilter();
