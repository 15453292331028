/**
 * Documentation can be found at http://bootstrapswitch.site
 */

window.addEventListener("load", function() {
    initBootstrapSwitch();
});

function initBootstrapSwitch() {
    if (jQuery('.js-freelance_checkbox_switch').length > 0) {
        jQuery('.js-freelance_checkbox_switch').bootstrapSwitch().unbind('switchChange.bootstrapSwitch').on('switchChange.bootstrapSwitch', function (event, state) {
            var switch_element = jQuery(this);
            var link = switch_element.attr("data-link");

            if (link != undefined) {
                jQuery.post(link, function (response) {
                    if (switch_element.hasClass('js-profile_status_switch') && response == true) {
                        toggle_profile_status(state);
                    }
                });
            }
        });
    }

    if (jQuery('.searchagent_toggle_js').length > 0) {
        jQuery('input[type="checkbox"].searchagent_toggle_js').bootstrapSwitch().unbind('switchChange.bootstrapSwitch').on('switchChange.bootstrapSwitch', function (event, state) {

            var self = jQuery(this);
            var link = jQuery(this).attr("data-link");
            if (link) {

                jQuery.post('/search/ajax.php',
                    {
                        action: 'searchagent_toggle_active',
                        searchagent_id: link
                    },
                    function (transport) {
                        var j = transport;

                        if (j["error"] == 1) {
                            self.bootstrapSwitch('state', false);
                            modal.show_message(j["error_message"], 1000000, "error");
                        }
                    }, 'json');
            }

        });
    }
}

function toggle_profile_status(state) {
    jQuery('.js-profile_status_switch').bootstrapSwitch('state', state, true);

    if (state === true) {
        jQuery('#profile_offline-alert').remove();

        jQuery('.sichtbar_menu')
            .attr('class', 'sichtbar_menu far fa-eye pull-right green-eye-open')
            .attr('alt', 'sichtbar')
            .attr('title', 'sichtbar');
    } else {
        jQuery('.sichtbar_menu')
            .attr('class', 'sichtbar_menu far fa-eye-slash pull-right warning-color')
            .attr('alt', 'nicht sichtbar')
            .attr('title', 'nicht sichtbar');
    }
}
