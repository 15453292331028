/**
 * Modal manager.
 */
function FL_Modal() {
    this.type                          = 'template';
    this.source                        = '';
    this.cssclass                      = '';
    this.args                          = null;
    this.title                         = '';
    this.block_page                    = false;
    this.modal                         = jQuery('#fl_modal');
    this.modal_header                  = jQuery('#fl_modal .modal-header');
    this.modal_dialog                  = jQuery('#fl_modal .modal-dialog');
    this.modal_container_content       = jQuery('#fl_modal .modal-body');
    this.modal_container               = jQuery('#fl_modal .modal-content');
    this.modal_message                 = jQuery('#fl_modal .modal-message');
    this.modal_message_text            = jQuery('#fl_modal .modal-message .message-text');
    this.modal_message_standard_button = jQuery('#fl_modal .modal-message button');
    this.modal_message_custom_button   = jQuery('#message_custom_button');
    this.modal_clock                   = 0;
    this.showingTimeout                = 0; // in millis; when the timeout is reached the close / hide action will be called

    this.is_ready                      = false;

    this.onClickSelector               = null;
    this.onClickMethod                 = null;

    var self = this;

    this.setTypeTemplate = function () {
        self.type = 'template';
    };

    this.setTypeRequest = function () {
        self.type = 'request';
    };

    this.setType = function (value) {
        self.type = value;
    };

    this.setSource = function (value) {
        self.source = value;
    };

    this.setCssclass = function (value) {
        self.cssclass = value;
    };

    this.setArgs = function (value) {
        self.args = value;
    };

    this.setTitle = function (value) {
        self.title = value;
    };

    this.setBlockPage = function (value) {
        self.block_page = value;
    };

    this.setShowingTimeout = function (timeout) {
        self.showingTimeout = timeout;
    };

    this.show_modal_container = function () {
        if (self.block_page) {
            jQuery('#fl_modal').modal().addClass(self.cssclass);
            self.modal_header.hide();
        } else {
            jQuery('#fl_modal').modal().addClass(self.cssclass);
            self.modal_header.show();
        }

        if (typeof self.onClickMethod == "function") {
            jQuery('#fl_modal '+self.onClickSelector).each(function (index, element) {
                jQuery(element).on('click', self.onClickMethod);
            });
        }

        self.modal_container.show();
        if (self.cssclass) {
            // https://stackoverflow.com/questions/12319171/how-to-handle-the-modal-closing-event-in-twitter-bootstrap
            jQuery('#fl_modal').on("hidden.bs.modal", function () {
                jQuery('#fl_modal').removeClass(self.cssclass).off("hidden.bs.modal");
            });
        }

        if (self.showingTimeout > 0) {
            window.setTimeout(function() {
                self.block_page = false;
                self.hide();
            }, self.showingTimeout);
        }
    };

    this.ready = function () {
        return new Promise(function (resolve, reject) {
            if (self.is_ready) {
                resolve();
                return;
            }
            var interval = setInterval(function () {
                if (self.is_ready) {
                    clearInterval(interval);
                    resolve();
                }
            },500);
        });
    };

    this.setCommonItems = function() {
        var modal_title = jQuery('#fl_modal .modal-title');

        modal_title.html(self.title);
        jQuery('.modal-dialog.vertical-align-center').addClass(self.cssclass);

        self.modal_message.hide();
    };

    /*
     * showString: displays the given string
     */
    this.showString = function() {
        self.update(self.source);
        self.show_modal_container();
    };

    /*
     * showTemplate: displays the given template identified by its name
     * NOTE: since release-7.6 we are using only type request
     */
    this.showTemplate = function() {
        var params = {template: self.source, args: self.args};

        jQuery.post("/ajax.php",
            {
                action: 'show_popup',
                args: JSON.stringify(params)
            },
            function (r) {
                var content = r;

                if (content["error"] == 0) {
                    self.update(content["content"]);
                    self.show_modal_container();
                } else {
                    var button = {label: content["button"]["label"], onclick: content["button"]["onclick"]};
                    self.show_message(content["error_message"], 1000000, content["error_type"], content["standard_button"], button);
                }
            }, 'json');
    };

    this.NOShowTemplate = function() {
        self.show_message("Function removed", 5000, "error", "Abbrechen");
    }

    /*
     * showRequest: displays the result of the given url
     */
    this.showRequest = function() {
        var url = self.source + '?' + self.args;

        jQuery.post(url, function (r) {
            var content = r;

            if (content["error"] == 0) {
                self.update(content["content"]);
                self.show_modal_container();
            } else {
                jQuery('#fl_modal').modal();

                var error_message         = typeof content["error_message"] == "undefined" ? "" : content["error_message"];
                var type                  = typeof content["error_type"] == "undefined" ? "error" : content["error_type"];
                var standard_button_label = typeof content["standard_button"] == "undefined" ? "" : content["standard_button"];
                var custom_button = "";

                if(content["button"] && content["button"]["label"] && content["button"]["onclick"]){
                    custom_button = {label: content["button"]["label"], onclick: content["button"]["onclick"]};
                }

                if (standard_button_label != "" && custom_button != "") {
                    self.show_message(error_message, 1000000, type, standard_button_label, custom_button);
                } else if (standard_button_label != "") {
                    self.show_message(error_message, 1000000, type, standard_button_label);
                } else {
                    self.show_message(error_message, 1000000, type);
                }

            }
        }, 'json');
    };

    this.show = function () {

        self.ready().then(function () {
            if (self.type == 'template') {
                // type template forbidden
                self.NOShowTemplate();
            } else if (self.type == 'request') {
                self.showRequest();
            } else if (self.type == 'string') {
                self.showString();
            }

            self.setCommonItems();

            clearTimeout(self.modal_clock);
        });

    };

    this.update = function (content) {
        self.modal_container_content.html(content);
    };

    this.hide = function () {
        self.ready().then(function () {
            jQuery('#fl_modal').modal('hide').removeClass(self.cssclass);
        });
    };

    this.isVisible = function () {
        return self.modal.is(":visible");
    };

    this.set_buttons_style = function (type) {
        if (type == "info") {
            self.modal_message_standard_button.addClass('btn-light');
            self.modal_message_custom_button.find('.btn').addClass('btn-default');
        }
    };

    /**
     * Shows a modal message
     * @param content                   the text message
     * @param timeout                   time in milliseconds to display the message
     * @param type                      default value is "info", "success" and "error" are the other valid values
     * @param standard_button_label     label for the button (by default is "ok")
     * @param custom_button             single button object or an array of button objects (attributes: label, onclick) to show if it's needed
     */
    this.show_message = function (content, timeout, type, standard_button_label, custom_button, onclick_standard_button) {
        self.ready().then(function () {
            if (timeout == undefined) {
                timeout = 5000;
            }

            if (type == undefined) {
                type = "info";
            }

            if (standard_button_label == undefined) {
                standard_button_label = "Ok";
            }

            if (onclick_standard_button == undefined) {
                onclick_standard_button = function () { self.hide(); };
            }

            if (self.block_page) {
                self.modal.modal({backdrop: 'static', keyboard: false});
            } else {
                self.modal.modal();
            }

            //Set default css class from framework.tpl
            jQuery('.modal-dialog.vertical-align-center').attr('class', 'modal-dialog vertical-align-center');

            self.modal_container.hide();
            self.modal_message.removeClass('success info error');
            self.modal_message.addClass(type);

            // reset all attributes which were set by previous dialog show (except class)
            $.each( modal.modal_dialog[0].attributes, function ( index, attribute ) {
                if (attribute !== undefined) {
                    if (attribute.name !== undefined && attribute.name !== null && attribute.name !== "class") {
                        modal.modal_dialog.removeAttr(attribute.name);
                    }
                }
            });

            if (typeof content === 'object') {
                self.modal_message_text.html(content.text);
                if (content.attr && typeof content.attr === 'object') {
                    for (const key in content.attr) {
                        modal.modal_dialog.attr(key, content.attr[key]);
                    }
                }
            } else {
                self.modal_message_text.html(content);
            }

            self.modal_message_standard_button.html(standard_button_label);
            self.modal_message_standard_button.unbind("click");
            if (typeof onclick_standard_button == "function") {
                self.modal_message_standard_button.on('click',onclick_standard_button);
            } else {
                self.modal_message_standard_button.on('click', function () {
                    eval(onclick_standard_button);
                });
            }

            self.modal_message_custom_button.empty();
            if (custom_button != undefined && custom_button != null) {
                if (!Array.isArray(custom_button)) {
                    custom_button = [custom_button];
                }
                for (let index = 0; index < custom_button.length; ++index) {
                    const custom_button_element = custom_button[index];
                    var custom_class="";
                    var next_class = "";
                    if (index > 0) {
                        next_class = " margin-left-sm";
                    }
                    var button = jQuery("<button>").text(custom_button_element.label).addClass("btn "+custom_button_element.custom_class+next_class).attr({name:"custom_"+index, id:"custom_"+index});
                    if (typeof custom_button_element.onclick == "function") {
                        button.on('click',custom_button_element.onclick);
                    } else {
                        button.on('click', function () {
                            eval(custom_button_element.onclick);
                        });
                    }
                    self.modal_message_custom_button.append(button);
                }
            }

            self.set_buttons_style(type);

            clearTimeout(self.modal_clock);
            self.modal_message.show();

            if (timeout > 0) {
                if (typeof onclick_standard_button == "function") {
                    self.modal_clock = setTimeout(onclick_standard_button, timeout);
                } else {
                    self.modal_clock = setTimeout(function () {
                        eval(onclick_standard_button);
                    }, timeout);
                }
            }
        });
    };


    /**
     * Shows a specialy formatted popup that uses content defined by type and source
     * @param type
     * @param source
     * @param args
     * @param title
     * @param cssclass
     */
    this.showFLPopup = function (type, source, args, title, cssclass, block_page) {
        self.setType(type);
        self.setSource(source);
        self.setArgs(args);
        self.setTitle(title);
        self.setCssclass(cssclass);
        self.setBlockPage(typeof block_page == 'undefined' ? false : block_page);
        self.show();

        return false;
    };

}

modal = new FL_Modal();

window.addEventListener("load", function() {
    jQuery('#fl_modal .close').on("click", function (e) {
        modal.hide();
    });

    jQuery(document).on("keypress", function (e) {
        if (modal.modal_message.is(':visible') && e.which === 13) {
            modal.hide();
        }
    });

    jQuery('body').on('click', '[fl-popup-source]', function (e) {
        var element  = jQuery(this);
        var type     = element.attr('fl-popup-source-type');
        var source   = element.attr('fl-popup-source');
        var cssclass = element.attr('fl-popup-cssclass');
        var args     = element.attr('fl-popup-args');
        var title    = element.attr('fl-popup-title');

        modal.setType(type);
        modal.setSource(source);
        modal.setCssclass(cssclass);
        modal.setArgs(args);
        modal.setTitle(title);
        modal.show();

        e.stopPropagation();

    });

    modal.is_ready = true;
});