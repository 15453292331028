class PaymentClient {

    /**
     * Constructs the object.
     *
     * @param publicKey the public API key
     * @param paymentFormElementId the ID of the HTML form element
     * @param paymentElementId the ID of the HTML element that is going to be used to display payment card details
     * @param paymentTypeElementName the name of the HTML element that defines what kind of payment method has been choosen, e.g. credit card or direct debit
     * @param submitButtonElementId the ID of the HTML element that submits the form
     * @param errorMessageElementId the ID of the HTML element where error message is contained
     * @param intentId the ID of
     */
    constructor(publicKey, paymentFormElementId, paymentElementId, paymentTypeElementName, cardHolderElementId,
                submitButtonElementId, errorMessageElementId, clientSecret, redirectUrl) {

        this.publicKey = publicKey;

        this.paymentForm = jQuery('#' + paymentFormElementId);
        this.paymentElement = jQuery('#' + paymentElementId);
        this.paymentType = jQuery('input[name="' + paymentTypeElementName + '"]');
        this.cardHolder = jQuery('#' + cardHolderElementId);
        this.submitButton = jQuery('#' + submitButtonElementId);
        this.errorMessage = jQuery('#' + errorMessageElementId);
        this.clientSecret = clientSecret;
        this.redirectUrl = redirectUrl;

        if (this.publicKey === undefined) {
            throw 'Missing required parameter publicKey';
        }

        if (this.clientSecret === undefined) {
            throw 'Missing required parameter clientSecret';
        }

        this.paymentForm.on('submit', function (event) {
            event.preventDefault();
        });

        if (typeof Stripe != 'undefined') {
            // TODO: Should have some warning if Stripe JS library has not been loaded correctly.
            this.stripe = Stripe(this.publicKey);
            this.elements = this.stripe.elements({clientSecret: this.clientSecret});
        }

        this.availableMethods = ['card', 'iban'];
        this.url = '/account/ajax.php';
        this.customer_id = null;
        this.iban = null;
        this.creditCard = null;
        this.errorUrl = '/account/create.php?action=error';
        this.isListenerDefined = false;
        this.error_message_card = 'Bitte geben Sie gültige Zahlungsdaten ein';
        this.error_message_sepa = 'Bitte geben Sie gültige Zahlungsdaten und Kontoinhaber ein und bestätigen Sie das SEPA-Lastschriftmandat.';
    }


    /**
     *
     * @param stye JSON object with style settings
     */
    showCreditCard(style) {
        if (this.creditCard == null) {
            this.creditCard = this.elements.create('payment');
            this.loadElements(this.creditCard, this.error_message_card);
        } else {
            this.loadElements(this.creditCard, this.error_message_card);
        }
        this.callDefineListener();
    }

    showIban(style, customer_id) {
        if (customer_id !== undefined) {
            this.customer_id = customer_id;
        }
        if (this.iban == null) {
            this.iban = this.elements.create('iban', {
                style: style,
                supportedCountries: ['SEPA']
            });
            this.iban.on('change', function () {
            });
            this.loadElements(this.iban, this.error_message_sepa);
        } else {
            this.loadElements(this.iban, this.error_message_sepa);
        }
        this.callDefineListener();
    }

    /**
     *
     * @param stripeElementObject
     * @privat
     */
    loadElements(stripeElementObject, error_message) {
        var self = this;
        self.errorMessage.hide();
        stripeElementObject.mount('#' + this.paymentElement.attr('id'));
        stripeElementObject.on('change', function (event) {
            self.errorMessage.hide();
            if (event.error) {
                self.errorMessage.text(error_message);
                self.errorMessage.show();
            }
        });
    }

    callDefineListener() {
        if (!this.isListenerDefined) {
            this.defineListener();
        }
    }

    defineListener() {
        var self = this;
        this.isListenerDefined = true;
        this.paymentForm.on('submit', function (event) {
            event.preventDefault();

            self.paymentForm.prop('disabled', true);
            self.submitButton.prop('disabled', true);

            // TODO: how can we get rid of this
            navigationCancel = 1; // very bad; didn't have time to refactor now

            var card = null;
            var selectedPaymentTypeId = self.paymentType.filter(':checked').val();
            if (selectedPaymentTypeId == 'existing') {
                window.location.href = self.redirectUrl;
            } else if (selectedPaymentTypeId == 2) {
                card = self.creditCard;

                // This API will be valid also for SEPA direct debit in future; currently ony credit cards are supported by handleCardSetup;
                // our code is though ready to be used
                self.stripe.confirmSetup({
                    elements: self.elements,
                    confirmParams: {return_url: window.location.protocol + "//" + window.location.host + self.redirectUrl},
                    redirect: "if_required"
                }).then(function (result) {
                    if (result.error) {
                        // Display error.message in your UI.
                        self.errorMessage.text(self.error_message_card);
                        self.errorMessage.show();
                        self.paymentForm.prop('disabled', false);
                        self.submitButton.prop('disabled', false);
                        return;
                    } else {
                        // The setup has succeeded. Display a success message.
                        self.updateCardData();
                    }
                }, function (error) {
                    console.log('Unexpected promise error.');
                });

            } else if (selectedPaymentTypeId == 3) {
                card = self.iban;

                // TODO: CARD OWNER as well as SEPA Mandate should be filled out to proceed, introduce check and error message.
                var cardHolderName = self.cardHolder.val();
                if (cardHolderName == '' || jQuery('#sepa_condition').prop("checked") == false) {
                    self.errorMessage.text(self.error_message_sepa);
                    self.errorMessage.show();
                    self.paymentForm.prop('disabled', false);
                    self.submitButton.prop('disabled', false);
                    return;
                }


                // SEPA payment requires email, as we do not want to share this user info, we are using our own non-existing email
                var email = "noreply";
                if (self.customer_id != null) {
                    email = self.customer_id;
                }
                email += "@freelance.de";

                self.stripe.confirmSepaDebitSetup(self.clientSecret, {
                    payment_method: {
                        sepa_debit: card,
                        billing_details: {
                            name: cardHolderName,
                            email: email
                        },
                    },
                }).then(function (result) {
                    if (result.error) {
                        // Display error.message in your UI.
                        self.errorMessage.text(self.error_message_sepa);
                        self.errorMessage.show();
                        self.paymentForm.prop('disabled', false);
                        self.submitButton.prop('disabled', false);
                        return;
                    } else {
                        // The setup has succeeded. Display a success message.
                        self.updateCardData();
                    }
                }, function (error) {
                    console.log('Unexpected promise error.');
                });

            } else if (selectedPaymentTypeId == 1 || selectedPaymentTypeId == 4) {
                self.updatePaymentProviderId();
            }

            return false;
        });
    }

    updatePaymentProviderId() {
        var selectedPaymentTypeId = this.paymentType.filter(':checked').val();
        var self = this;
        jQuery.ajax({
            type: 'POST',
            url: this.url,
            data: 'action=update_payment_provider_id&payment_provider_id=' + selectedPaymentTypeId,
            success: function (ajaxResult) {
                var response = new AjaxResponse(ajaxResult);
                if (!response.error) {
                    window.location.href = self.redirectUrl;
                } else {
                    self.showErrorPage();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    }

    updateCardData() {
        var selectedPaymentTypeId = this.paymentType.filter(':checked').val();
        var self = this;
        jQuery.ajax({
            type: 'POST',
            url: this.url,
            data: 'action=update_card_data&payment_provider_id=' + selectedPaymentTypeId,
            success: function (ajaxResult) {
                var response = new AjaxResponse(ajaxResult);
                if (!response.error) {
                    window.location.href = self.redirectUrl;
                } else {
                    self.showErrorPage();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    }

    updateIban(sourceId) {
        var self = this;
        jQuery.ajax({
            type: 'POST',
            url: this.url,
            data: 'action=update_iban&source_id=' + sourceId,
            success: function (ajaxResult) {
                var response = new AjaxResponse(ajaxResult);
                if (!response.error) {
                    window.location.href = self.redirectUrl;
                } else {
                    self.showErrorPage();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    }

    showErrorPage() {
        window.location.href = this.errorUrl;
    }

    updateSourceId(sourceId) {
        alert('source ID ' + sourceId + '; more');
        return;
    }

    payCreditCard(/*paymentMethodId,*/ subscriptionToken) {
        var self = this;
        jQuery.ajax({
            type: 'POST',
            url: this.url,
            data: 'action=create_subscription&subscription_token=' + subscriptionToken,
            success: function (ajaxResult) {
                var response = new AjaxResponse(ajaxResult);
                if (!response.error) {
                    window.location.href = self.redirectUrl;
                } else {
                    self.showErrorPage();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                self.showErrorPage();
            }
        });

        return false;
    }

    /**
     * @private
     * @test
     */
    click() {
        alert('ok');
    }
}
