class NotificationsServiceFactory {

    constructor(host, port) {
        this.eventSource = null;
        this.reconnect = 1000; //millisecon;
        this.reconnect_time = 0;
        this.host = '';
        this.port = '';
        this.error = false;
        this.retries = 0;
        this.max_retries = 3;
        this.lock = false;
        this.reschedule_after = 120 * 1000; // milliseconds to try to renew the event stream connectio;

        if (!host || !port) {
            console.log("Missing required args host or port: " + host + " ; " + port);
            return;
        }
        this.host = host;
        this.port = port;
    }

    /**
     * Init and keep listening
     * By default we start notificationsServiceFactory.listen() without any token therefore we get token from smarty $sse_my_channel
     * $sse_my_channel is cached for 5 min
     *
     * When token (cached/not cached) is not valid (token expires in Chat Service after 15min) it jump to block eventSource.addEventListener("error"
     * where we regenerate token by this function startChatServiceWithNewToken() and following functions are called
     *    - notificationsServiceFactory.terminate();
     *    - notificationsServiceFactory.listen(json.result);
     *
     *    after this call we start chat service with newly crated token and this token is again cached for 5 min in $sse_my_channel
     *
     *    HINT: to prevent token expiration issue we regenerate token after 5 min
     *    radyState Possible values are CONNECTING (0), OPEN (1), or CLOSED (2)
     */
    listen(token) {

        var self = this;

        if (!token) {
            // console.log('no token');
            return;
        }

        if (!this.eventSource) {
            this.eventSource = new EventSource(this.host + ":" + this.port + "/api/chat/listen/" + token);
        }

        if (new Date().getTime() < this.reconnect_time) {
            return;
        }

        this.eventSource.addEventListener("open", function (event) {
            // console.log('opening connection');
        });

        this.eventSource.addEventListener("message", function (event) {
            // console.log(event); console.log(event.data);
            new FL_NotificationDispatcher(event.data);
        });

        this.eventSource.addEventListener("error", function (event) {
            //self.tryReconnect();
        });

        window.setTimeout(function () {
            self.reschedule();
        }, this.reschedule_after);
    }

    reschedule() {
        if (!this.eventSource) {
            return;
        }

        this.tryReconnect();
    }

    tryReconnect() {
        var self = this;
        if (!self.lock) {
            self.lock = true;
            jQuery.when(self.startChatServiceWithNewToken()).always(function (event) {

                if (event && event.error == 0) {
                    self.error = false;
                } else {
                    self.error = true;
                    self.retries++;
                }

                if (self.error) {
                    if (self.retries >= self.max_retries) {
                        return;
                    }
                    self.increaseTimeout();
                    window.setTimeout(function () {
                        self.lock = false;
                        self.tryReconnect();
                    }, self.reconnect); // dynamically set timeout from 1 - 60 seconds
                    return;
                }

                self.terminate();
                self.listen(event.result);
                self.reconnect = 1000;
                self.retries = 0;
                self.lock = false;

            });
        }
    }

    terminate() {

        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null;
            delete window.eventSource;
        }

        // console.log('terminating');
    }

    increaseTimeout() {
        this.reconnect *= 2;
        if (this.reconnect >= 64000) {
            this.reconnect = 64000;
        }
        this.reconnect_time = new Date().getTime() + this.reconnect;
    }

    startChatServiceWithNewToken() {

        unregisterJqueryResponder();

        var dfd = jQuery.Deferred();

        jQuery.ajax({
            url: '/notification/ajax_create_notification_token.php',
            type: 'POST',
            data: {
                action: 'get_new_chat_service_token',
            },
            dataType: 'json',
            success: function (json) {
                dfd.resolve(json);
            },
            error: function () {
                dfd.resolve(null);
            },
            complete: function () {
            }
        });

        return dfd.promise();
    }
}
