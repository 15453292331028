//@todo -- do we still use it?

class CompanySelector {

    constructor(instanceName) {

        this.url = '/ajax/company_selector.php';
        this.loader = 'company_ajax_loader';

        this.instanceName = instanceName;
        this.templatePath = '';
        this.markerCode = '';

        this.time_to_wait_by_typing = 1500;
        this.typing_time = null;
        this.typing_handler = "";

        this.select_handler = "";
    }

    setTemplatePath(template) {
        this.templatePath = template;
    }

    getTemplatePath() {
        return this.templatePath;
    }

    setMarkerCode(marker) {
        this.markerCode = marker;
    }

    getMarkerCode() {
        return this.markerCode;
    }

    setTimeToWaitByTyping(time) {
        this.time_to_wait_by_typing = time;
    }

    getTimeToWaitByTyping() {
        return this.time_to_wait_by_typing;
    }

    setInstanceName(instanceName) {
        this.instanceName = instanceName;
    }

    getInstanceName() {
        return this.instanceName;
    }

    getLoader() {
        return this.loader;
    }

    setSelectHandler(function_name) {
        this.select_handler = function_name;
    }

    onKeyUp(function_name) {
        this.typing_handler = function_name;
        var start = false;

        if (this.typing_time == null) {
            start = true;
        }

        // refresh the time that shows that the user is still typing
        this.typing_time = (new Date()).getTime();

        if (start) {
            this.isStillTyping();
        }
    }

    isStillTyping() {
        // time to wait for the user after he has finished typing
        var time_to_wait = this.time_to_wait_by_typing;

        if (this.typing_time != null) {
            // the time now as unix timestamp
            var now = (new Date()).getTime();

            if (now - this.typing_time > time_to_wait) {
                // check what the user has typed
                if (this.typing_handler != "") {
                    try {
                        eval(this.typing_handler);
                    } catch (e) {
                        alert('The handler ' + this.typing_handler + ' threw an exception!');
                    }
                } else {
                    alert('The handler function is empty!');
                }
                this.typing_time = null;
            } else {
                // execute myself after 100 milli secs
                setTimeout(this.instanceName + ".isStillTyping()", 100);
            }
        }
    }

    searchForCompany(div_id, search_text) {
        if (search_text != "") {
            new jQuery.post(this.url,
                'action=search_for_company&search_text=' + search_text + '&template=' + this.getTemplatePath() + '&marker=' + this.getMarkerCode(),
                function (transport) {
                    var j = transport;
                    if (j['error'] == 0) {
                        jQuery('#' + div_id).show();
                        jQuery('#' + div_id).html(j['result']);
                    } else {
                        alert(j['error_message']);
                    }
                }, 'json');
        }
    }

    executeSelectHandler(value, description) {
        if (this.select_handler == "") {
            alert('No select handler defined!');
        } else {
            eval("" + this.select_handler + "(" + value + ", '" + description + "');");
        }
    }


    display(div_id) {
        jQuery("#" + div_id).load(this.url + '?action=get_application_company_template');
    }

    focus() {
        if (jQuery('#company_selection_text_field').length) {
            jQuery('#company_selection_text_field').focus();
        }
    }
}

var company_selector = new CompanySelector('company_selector');