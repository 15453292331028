function network_add_company_link(company_id) {

    jQuery('#watchlist_mycompanies_awaiting_confirmation').load('/network/ajax.php',
        {
            action: 'add_company_link',
            company_id: company_id
        }, function () {
            jQuery('#' + 'unconfirmed_div').show();
        });
}


function network_delete_company_link(link_id) {
    jQuery.post('/network/ajax.php',
        {
            action: 'delete_company_link',
            link_id: link_id
        },
        function (r) {
            jQuery('#' + 'company_link_' + link_id).remove();
        });
}

/**
 * Saves note for item in Watchlist (Merkliste)
 * @param id
 * @param type
 * @param form
 */
function watchlist_save_note(id, type, form) {
    jQuery.post('/network/ajax.php',
        {
            action: 'save_note',
            id: id,
            type: type,
            note: form.elements["note"].value
        },
        function (result) {
            var json = result;
            if (json['error'] === 0) {
                var note_button_id = "#note-button-freelancer_profile_";
                var note_text_id = "#note-text-freelancer_profile_";
                var popup_title_class = ".fl_popup_title_profile_";
                var see_note_id = "#freelancer_see_note_";
                if (type === "project") {
                    note_button_id = "#note-button-project_";
                    note_text_id = "#note-text-project_";
                    popup_title_class = ".fl_popup_title_project_";
                    see_note_id = "#project_see_note_";
                }

                jQuery(note_button_id + id).text(json['result_note_button']);
                jQuery(note_text_id + id).html(json['result_note_text']);
                jQuery(popup_title_class + id).attr("fl-popup-title", json['result_fl_popup_title']);
                jQuery(popup_title_class + id).attr("title", json['result_fl_popup_title']);
                jQuery(see_note_id + id).show();

                if (jQuery('#noteShort').length) {
                    location.reload();
                } else {
                    modal.show_message(json['result_message'], 5000, 'success');
                }

            }
        }, 'json');
}

/**
 * Delete a watchlist item
 * @param id
 * @param type
 */
function watchlist_delete_item(id, type) {
    jQuery.post('/network/watchlist.php',
        {
            action: 'delete_' + type,
            id: id
        },
        function (result) {
            var json = result;
            if (json['error'] === 0) {
                modal.show_message(json["result_message"], 50000, "success");
                jQuery('#marker_show_note_' + type + '_' + id).hide();
                jQuery('#number_of_items').html(json["i_number_of_items"]);
            }
        }, 'json');
}

function deleteMarkedWatchlist(type) {

    var checkboxes    = [];

    jQuery('.watchlist-checkbox_' + type + ':checked').each(function () {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0) {
        jQuery.post('/network/watchlist.php',
            {
                action: 'delete_' + type + "_mass",
                ids: checkboxes.join(',')
            },
            function (json) {
                if (json['error'] === 0 ) {

                    if (typeof json['html_watchlist_profile'] !== 'undefined') {
                        jQuery('#profile_favorite_list').html(json['html_watchlist_profile']);

                    } else if (typeof json['html_watchlist_project'] !== 'undefined') {
                        jQuery('#project_favorite_list').html(json['html_watchlist_project']);
                    }
                    jQuery('#number_of_items').html(json["i_number_of_items"]);
                    modal.show_message(json["result_message"], 5000, "success");

                    jQuery('#watchlist-checkbox').selectpicker('val', '');
                }
            }, 'json');
    }
}

/**
 * Show modal popup window for Watchlist (Merkliste) actions.
 * @param project_id
 * @returns {boolean}
 */
function watchlist_project_modal(project_id) {
    var title = "IHRE MERKLISTE";
    var watchlist_icon = jQuery('#project_marked-' + project_id).children('span');

    if (watchlist_icon.hasClass('bookmarked')) {
        title = "Gemerktes Projekt löschen";
    } else if (watchlist_icon.hasClass('fa-sticky-note')) {
        title = "Bereits beworben";
    }

    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=add_to_watchlist-modal_content&project_id=' + project_id,
        title,
        '');
}

/**
 * Adds a new project in the favorite projects list of the user
 * @param id
 * @param user_id
 * @param form
 */
function watchlist_add_project(id, user_id, form) {
    jQuery.post('/network/ajax.php',
        {
            action: 'watchlist_add_project',
            id: id,
            user: user_id,
            note: form.elements["note"].value
        },
        function (r) {
            var json = r;
            if (json['error'] === 0) {
                var menu = jQuery('#detail-quickbuttons');
                if (menu.length) {
                    menu.html(json['result_html']);
                } else {
                    var watchlist_icon = jQuery('#project_marked-' + id);
                    watchlist_icon.children('i').removeClass('grey');
                    watchlist_icon.children('i').addClass('bookmarked');
                    watchlist_icon.attr("data-original-title", 'Dieses Projekt von meiner Merkliste löschen');
                }

                modal.show_message(json['result_message'], 5000, 'success');
            }
        }, 'json');
}


/**
 * Deletes a project of the favorite projects list of the user
 * @param id
 * @param user_id
 */
function watchlist_delete_project(id, user_id) {
    jQuery.post('/network/ajax.php',
        {
            action: 'watchlist_delete_project',
            id: id,
            user: user_id
        },
        function (r) {
            var json = r;
            if (json['error'] === 0) {
                var menu = jQuery('#detail-quickbuttons');
                if (menu.length) {
                    menu.html(json['result_html']);
                } else {
                    var watchlist_icon = jQuery('#project_marked-' + id);
                    watchlist_icon.children('i').removeClass('bookmarked');
                    watchlist_icon.children('i').addClass('far');
                    watchlist_icon.attr("data-original-title", 'IHRE MERKLISTE');
                }

                modal.show_message(json['result_message'], 5000, 'success');
            }
        }, 'json');
}


/**
 * Show modal popup window for Watchlist (Merkliste) actions.
 * @param profile_id
 */
function show_watchlist_freelancer_profile_modal(profile_id) {
    var watchlist_icon = jQuery('#profile_marked-' + profile_id + ' i');

    if (watchlist_icon.hasClass('bookmarked')) {
        modal.showFLPopup(
            'request',
            '/ajax/popup_template.php',
            'action=watchlist_remove_profile&i_profile_id=' + profile_id,
            'IHRE MERKLISTE'
        );
    } else {
        modal.showFLPopup(
            'request',
            '/ajax/popup_template.php',
            'action=watchlist_add_profile&i_profile_id=' + profile_id,
            'IHRE MERKLISTE'
        );
    }
}

/**
 * Adds a new profile in the favorite profiles list of the user
 */
function watchlist_add_profile(profile_id) {
    var formData = new FormData(jQuery('#watchlist_freelancer_profile-form')[0]);

    jQuery.ajax({
        url: '/network/ajax.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json["error"] === 0) {
                var watchlist_icon = jQuery('#profile_marked-' + profile_id);
                watchlist_icon.children('i').addClass('bookmarked');
                watchlist_icon.attr("data-original-title", 'Dieses Profil von meiner Merkliste löschen');

                modal.show_message(json["message"], 5000, 'success');
            }
        }
    });
}

/**
 * Deletes a profile of the favorite profile list of the user
 */
function watchlist_remove_profile(profile_id) {
    jQuery.ajax({
        url: '/network/ajax.php',
        type: 'POST',
        data: {
            action: 'watchlist_remove_profile',
            profile_id: profile_id
        },
        dataType: 'json',
        success: function (json) {
            if (json["error"] === 0) {

                if (jQuery('.action-icon i.fa-bookmark').length) {
                    jQuery('#container_mark_as_favorite_' + profile_id ).html(json['favorite_icon_class_html']);
                }

                //will be removed in next refactoring - we should use everywhere container_mark_as_favorite_
                var watchlist_icon = jQuery('#profile_marked-' + profile_id);
                if (jQuery(watchlist_icon).length) {
                    watchlist_icon.children('i').removeClass('bookmarked');
                    watchlist_icon.attr("data-original-title", 'Dieses Profil auf meiner Merkliste setzen');
                }

                if (jQuery('#availability_alert_container_'+ profile_id)){
                    jQuery('#availability_alert_container_'+ profile_id).html(json['availability_alert_html']);
                }

                modal.show_message(json["message"], 5000, 'success');
            }
        }
    });
}

function load_next_blacklisted_companies(offset) {
    jQuery.post('/network/blacklist.php',
        'action=load_blacklisted_companies&offset=' + offset,
        function (t) {
            var j = t;

            if (j['error'] === 0) {
                jQuery('#company_blacklist').append(j['content']['list']);
                jQuery('#more_company_blacklist').html(j['content']['link']);
            }
        }, 'json');
}

function load_next_blacklisted_profiles(offset) {
    jQuery.post('/network/blacklist.php',
        'action=load_blacklisted_profiles&offset=' + offset,
        function (t) {
            var j = t;

            if (j['error'] == 0) {
                jQuery('#profiles_blacklist').append(j['content']['list']);
                jQuery('#more_profiles_blacklist').html(j['content']['link']);
            }
        }, 'json');
}

function get_favorite_projects(category, offset) {

    offset = typeof offset !== 'undefined' ? offset : 0;

    jQuery.post('/network/ajax.php',
        {
            action: 'get_favorite_projects',
            offset_projects: offset,
        },
        function (transport) {
            var r = transport;

            if (r["error"] == 0) {
                // modal.show_message('Here goes your search result', null, 'success');
                jQuery('#project_favorite_list').html(r['html_response']);
                jQuery('#pagination').html(r['html_pagination']);
                initBootstrapSelect();
            }
        }, 'json');
}

function get_favorite_profiles(category, offset) {

    offset = typeof offset !== 'undefined' ? offset : 0;

    jQuery.post('/network/ajax.php',
        {
            action: 'get_favorite_profiles',
            offset_profiles: offset,
        },
        function (transport) {
            var r = transport;

            if (r["error"] == 0) {
                // modal.show_message('Here goes your search result', null, 'success');
                jQuery('#profile_favorite_list').html(r['html_response']);
                jQuery('#pagination').html(r['html_pagination']);
                initBootstrapSelect();
            }
        }, 'json');
}

function enable_disable_bulk_actions_watchlist() {

    var checkboxes = [];
    jQuery('.watchlist-checkbox:checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0 ){
        jQuery('#toggle_bulk_actions #watchlist-checkbox').prop('disabled', false);
        jQuery('#toggle_bulk_actions #watchlist-checkbox').selectpicker('refresh');
        jQuery('#toggle_bulk_actions button').prop('disabled', false);
    } else {
        jQuery('#toggle_bulk_actions #watchlist-checkbox').prop('disabled', true);
        jQuery('#toggle_bulk_actions #watchlist-checkbox').selectpicker('refresh');
        jQuery('#toggle_bulk_actions button').prop('disabled', true);
    }
}

window.addEventListener("load", function() {
    initBulkActions();
});

function initBulkActions() {
    jQuery('#project_favorite_list, #profile_favorite_list').on("click", ".watchlist-checkbox", function (event) {
        enable_disable_bulk_actions_watchlist();
    });

    jQuery('#project_favorite_list, #profile_favorite_list').on("click", "#watchlist_bulk", function (event) {

        if (jQuery(this).prop('checked') == true) {
            jQuery('.watchlist-checkbox').prop('checked', true);
        } else {
            jQuery('.watchlist-checkbox').prop('checked', false);
        }
        enable_disable_bulk_actions_watchlist();
    });
}


function watchlist_bulk_action(name) {


    let action_value = jQuery('#' + name).val();

    if (action_value == "open_notes") {
        jQuery('.' + name + ':checked').each(function (index) {
            jQuery(this).parents('.watchlist_single_container').children('.panel-collapse.collapse').collapse('show');
        });

    }

    if (action_value == "close_notes") {
        jQuery('.' + name + ':checked').each(function (index) {
            jQuery(this).parents('.watchlist_single_container').children('.panel-collapse.collapse').collapse('hide');
        });
    }

    if (action_value == "mark_as_deleted") {

        if (jQuery('#profile_favorite_list').length) {
            deleteMarkedWatchlist('freelancer_profile');
        } else {
            deleteMarkedWatchlist('project');
        }
    }

    // reset the action select box
    jQuery('#' + name).selectpicker('val', '');
}

function watchlist_project_search(search_freetext, offset) {
    jQuery.post('/network/ajax.php',
        {
            action: 'project_search',
            search_freetext: search_freetext,
            offset: offset,
        },
        function (transport) {
            var r = transport;

            if (r["error"] == 0) {
                jQuery('#content_favorites_list').html(r['html_response']);
                jQuery('#pagination').html(r['html_pagination']);
                initBootstrapSelect();
                initBulkActions();
            }
        }, 'json');
}

function watchlist_profile_search(search_freetext, offset) {
    jQuery.post('/network/ajax.php',
        {
            action: 'profile_search',
            search_freetext: search_freetext,
            offset: offset,
        },
        function (transport) {
            var r = transport;

            if (r["error"] == 0) {
                jQuery('#content_favorites_list').html(r['html_response']);
                jQuery('#pagination').html(r['html_pagination']);
                initBootstrapSelect();
                initBulkActions();
            }
        }, 'json');
}
