/**
 * Datepicker documentation:
 * http://eonasdan.github.io/bootstrap-datetimepicker/
 */

window.addEventListener("load", function() {
    initDatePicker();
});

function initDatePicker() {

    var datePicker = jQuery('.datepicker');
    if (datePicker.length) {
        jQuery.each(datePicker, function (index, value) {
            createDateTimePicker(jQuery(value), {});
        });
    }

    var monthPicker = jQuery('.monthpicker');
    if (monthPicker.length) {
        jQuery.each(monthPicker, function (index, value) {
            createDateTimePicker(jQuery(value), {viewMode: 'months', format: 'MM/YYYY'});
        });
    }

    var yearPicker = jQuery('.yearpicker');
    if (yearPicker.length) {
        jQuery.each(yearPicker, function (index, value) {
            createDateTimePicker(jQuery(value), {viewMode: 'years', format: 'YYYY'});
        });
    }
}

function createDateTimePicker(item, options) {
    var defaultOptions = {
        viewMode: 'days',
        minDate: '1900-01-01',
        maxDate: '2050-01-01',
        locale: 'de',
        format: 'DD.MM.YYYY',
        useCurrent: false,
        ignoreReadonly: true,
        keepInvalid: true,
        showClear: true,
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar-alt',
            up: 'far fa-chevron-up',
            down: 'far fa-chevron-down',
            previous: 'far fa-chevron-left',
            next: 'far fa-chevron-right',
            today: 'far fa-crosshairs',
            clear: 'far fa-calendar-minus',
            close: 'far fa-times-circle'
        }
    };

    for (var key in options) {
        if (!options.hasOwnProperty(key)) continue;
        defaultOptions[key] = options[key];
    }

    jQuery(item).datetimepicker(defaultOptions);
}