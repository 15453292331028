class FL_NotificationDispatcher {

    constructor(json_message) {
        let message = JSON.parse(json_message);
        let message_type = message['id'];

        this.message_timestamp = message['date'];
        this.message_data = JSON.parse(message['data']);
        this.top_parent_message_id = jQuery("input[name='top_parent_message_id']").val();
        this.application_id = jQuery("input[name='application_id']").val();
        this.remotePartyTyping = false;

        this.setView();
        this.runNotification(message_type);
    }

    setView() {
        if (jQuery('#event_internal_messages').length) {
            this.page_view = 'internal_mail_dialog';
        } else if (jQuery('#event_internal_messages_list').length) {
            this.page_view = 'internal_mail_list';
        } else if (jQuery('#dashboard').length) {
            this.page_view = 'dashboard';
        } else if (jQuery('#event_application_list').length) {
            this.page_view = 'application_list';
        } else if (jQuery('#event_application').length) {
            this.page_view = 'application_dialog';
        } else {
            this.page_view = 'other';
        }
    }

    runNotification(message_type) {

        let res = message_type.split("|");
        message_type = res[2];

        if (message_type === 'SnippetUpdate') {
            this.eventSnippetUpdate();
        } else if (message_type === 'InternalMails') {

            if (this.page_view == 'internal_mail_dialog' && this.top_parent_message_id == this.message_data['top_parent_message_id']) {
                //user is currently on internal mail dialog, therefore we should display new message in dialog
                this.eventInternalMail_dialog();

            } else if (this.page_view == 'internal_mail_list') {
                //user is currently on internal mail list -- /mail/index.php
                this.eventInternalMail_list();

            } else if (this.page_view == 'dashboard') {
                //user is currently on dashboard
                this.eventDashboardInbox();
            } else {
                //user is currently somewhere else therefore just display badge
                this.eventInternalMail_other();
            }

        } else if (message_type == 'InternalMailsStatus') {

            if (this.page_view == 'internal_mail_dialog' && this.top_parent_message_id == this.message_data['top_parent_message_id']) {
                //user is currently on internal mail dialog, therefore just update status read/unread
                this.eventInternalMail_status();
            }

        } else if (message_type == 'InternalMailTyping') {

            if (this.page_view == 'internal_mail_dialog' && this.top_parent_message_id == this.message_data['top_parent_message_id']) {
                //user is currently on internal mail dialog, therefore just update status read/unread
                this.eventInternalMail_typing();
            }

        } else if (message_type == 'ApplicationHistory') {

            if (this.page_view == 'application_list') {
                this.eventApplication_list();
            }

            if (this.page_view == 'application_dialog' && this.application_id == this.message_data['application_id']) {
                //user is currently on application dialog, we should display new message in dialog
                this.eventApplication_dialog();
            } else {
                //user is currently somewhere else therefore just display badge
                this.eventApplication_other();
            }

        } else if (message_type == 'BadgeStatus') {
            this.eventBadge();
        } else if (message_type == 'SessionUpdate') {
            // if user session was just updated reschedule session update
            if (typeof window.session !== 'undefined') {
                window.session.resetSessionUpdateInterval();
            }
        }

    }

    eventInternalMail_dialog() {
        let cloned_message = jQuery('.conversation-scroll .conversation-left:last').clone();

        if (cloned_message.length) {
            //cloning was successful

            cloned_message.hide();

            //set property for new message
            cloned_message.find('.conversation-body-text').text(this.message_data['message_text']);
            cloned_message.find('.conversation-body-created').text(this.message_data['created']);
            cloned_message.attr('id', 'message_single_' + this.message_data['message_id']);

            jQuery('.conversation-scroll #last-comment').before(cloned_message);
            cloned_message.fadeIn(700);

            jQuery('.conversation-scroll').animate({scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")}, 700);

            //favicon notification change to favicon with red dot
            jQuery('#favicon').attr('href', '/images/icons/freelance-red-dot.png');

            let cloned_message_result = 'ok';
            let top_parent_message_id = this.message_data['top_parent_message_id'];
            mark_as_read(top_parent_message_id);

        } else {
            // we couldn't clone message as it's not in thread yet, this happens only when there is no reply from other party
            let cloned_message_result = 'failed';
            let top_parent_message_id = this.message_data['top_parent_message_id'];

            //ajax call to get all messages and display them
            jQuery.post('/mail/ajax.php',
                {
                    action: 'get_all_messages',
                    top_parent_message_id: top_parent_message_id
                },
                function (result) {

                    let j = result;

                    if (j['error'] == 0) {
                        jQuery('.app-converstation').html(j['html_response']);
                        jQuery('.conversation-scroll').animate({
                            scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")
                        }, 700);
                        mark_as_read(top_parent_message_id);
                    }
                }, 'json');
        }

        function mark_as_read(top_parent_message_id) {
            jQuery.post('/mail/ajax.php',
                {
                    action: 'mark_as_read',
                    list_of_mails: JSON.stringify({paramIds: [top_parent_message_id]})
                });
        }

    }

    eventApplication_list() {
        application_search('current', 0, true);
        this.eventApplicationBadge();
    }

    eventApplication_dialog() {
        jQuery.post('/application/ajax.php',
            {
                action: 'get_application_conversation',
                id: this.application_id
            },
            function (result) {
                let j = result;

                if (j['error'] == 0) {
                    jQuery('.conversation-scroll').html(j['html_response']);
                    jQuery('.conversation-scroll').animate({scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")}, 700);

                }
            }, 'json');
    }

    eventApplication_other() {
        this.eventApplicationBadge();
    }

    eventInternalMail_list() {
        get_internal_mails_js('inbox', 0);
        this.eventInternalMailBadge();
    }

    eventInternalMail_other() {
        this.eventInternalMailBadge();
    }

    eventInternalMail_status() {

        let status_text = this.message_data['created'] + " | " + this.message_data['message_status'] + ' <i class="primary-color far fa-check"></i>';
        let message_id = this.message_data['message_id'];

        setTimeout(function () {
            jQuery('#message_single_' + message_id + ' .conversation-body-created').html(status_text).hide(0).fadeIn('slow');
        }, 500);

    }

    eventInternalMail_typing() {
        if (!this.remotePartyTyping) {
            this.remotePartyTyping = true;
            let self = this;
            setTimeout(function () {
                jQuery('.loading_typing_notification').fadeOut();
                self.setRemotePartyTyping(false);
            }, 3000);
        }
        if (this.message_data['status'] == 'active') {
            jQuery('.loading_typing_notification').fadeIn();
        }
    }

    eventInternalMailBadge() {
        if (this.message_data['number_unread_messages'] == 0) {
            jQuery('.internal-mail-counter').text(this.message_data['number_unread_messages']).addClass('inbox-unread-zero');
        } else {
            jQuery('.internal-mail-counter').text(this.message_data['number_unread_messages']);
        }
    }

    eventApplicationBadge() {
        if (this.message_data['number_unread_messages'] == 0) {
            jQuery('.application-counter').text(this.message_data['application_unread_number']).addClass('inbox-unread-zero');
        } else {
            jQuery('.application-counter').text(this.message_data['application_unread_number']);
        }
    }

    eventDashboardInbox() {
        jQuery.post('/mail/ajax.php',
            {
                action: 'get_dashboard_internal_mail'
            },
            function (result) {
                let j = result;
                if (j['error'] == 0) {
                    jQuery('.dashboard .profile-inbox').html(j['html_response']);
                    checkPanelsEqualHeight();
                }
            }, 'json');
        this.eventInternalMailBadge();
    }

    eventBadge() {
        if (this.message_data['newNotificationsCount'] > 0) {
            jQuery('a.notifications-link').html('<i class="far fa-bell"></i><span class="notifcircle"></span>');
        } else {
            jQuery('a.notifications-link').html('<i class="far fa-bell"></i>');
        }
    }

    eventSnippetUpdate() {
        let snippet_updater = new SnippetUpdater(this.message_data);
        snippet_updater.updateSnippet();
    }

    setRemotePartyTyping(value) {
        this.remotePartyTyping = value;
    }

}


function push_typing_notifications(id_sse, channels, status, top_parent_message_id, url_channel) {

    // for (let i = 0; i < channels.length; i++) {
    //
    //     let message = {"id": id_sse, "sender": "freelance.de", "data": JSON.stringify({"status": status, "top_parent_message_id" : top_parent_message_id}), "channel": channels[i], "date": Date.now()};
    //
    //     unregisterJqueryResponder();
    //    
    //     jQuery.ajax({
    //         url: url_channel,
    //         type: 'post',
    //         dataType: 'json',
    //         contentType: 'application/json',
    //         success: function (data) {
    //             console.log(data);
    //         },
    //         data: JSON.stringify(message)
    //     });
    //
    // }

}
