/**
 * saves the company data and refreshes the according div
 */
function profile_company_save_data(goto_page)
{
    jQuery.post('/profile_company/create.php',
        'action=save_data&'+jQuery('#' + 'data_form_company').serialize(),
        function(result) {
            var j = result;
            if(j['error'] == 0)
            {
                if(goto_page == null)
                {
                    jQuery('.js-unset-editable').trigger('click');
                    jQuery('#marker_data').html(j['html']);

                    // modal.show_message('Das Profil wurde erfolgreich gespeichert.', 5000, 'success');
                    jQuery('#data_form_company input[type=submit]').after(show_success_sign());
                }
            }
            else
            {
                // Show the error
                jQuery('#marker_data').html(j['html']);
                flScrollTo('data_form_company');
            }
        }, 'json');

    var different_address = jQuery('#different_address').prop('checked');
    profile_staff_save_data(null, different_address);
    
}
