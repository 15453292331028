function profile_staff_save_data(goto_page, different_address){

    var elem;
    if (jQuery('.nav-aside-small').is(':visible')) {
        elem = "#step2pp_small";
    } else {
        elem = "#step2pp";
    }

    jQuery.post('/profile_staff/create.php',
        'action=save_data&' + jQuery('#data_form').serialize() + '&different_address=' + different_address,
        function (result) {
            var j = result;

            if (j['error'] == 0) {
                if (goto_page == null) {
                    jQuery('.js-unset-editable').trigger('click');
                    jQuery('#profile_personaldata').html(j['html']);
                    jQuery('#data_form input[type=submit]').after(show_success_sign());

                    if ((j['pp_profile'] == 'completed') && (jQuery(window).width() > 767) && jQuery('#step2pp').length > 0) {
                        jQuery('#step1pp i').hide();
                        //call intro.js
                        var intro = introJs();
                        intro.setOptions({
                            showBullets: false,
                            showButtons: false,
                            exitOnOverlayClick: true,
                            disableInteraction: false,
                            steps: [
                                {
                                    element: elem,
                                    intro: "Ihr Firmenprofil ist nun vollständig. Ab sofort können Sie Projekte ausschreiben. Die Option finden Sie hier.",
                                    position: 'right'
                                }
                            ]
                        });
                        intro.start();

                    }

                    if (j['pp_profile_promo'] == 'yes') {
                        // we need to refresh west navi
                        jQuery('#container_menu_contact_data_views').html(j['html_menu_contact_data_views']);
                    }

                } else if (goto_page == "gotoproject") {
                    // Goto project
                    window.location.href = "/project/create.php";
                } else if (goto_page == "gotoprojectlist") {
                    // Goto project
                    window.location.href = "/project/manage.php";
                } else if (goto_page == "gotocompanyprofile") {
                    // Goto project
                    window.location.href = "/profile_company/create.php";
                }
            } else {

                // Show the error
                jQuery('#profile_personaldata').html(j['html']);
                flScrollTo('profile_personaldata');

                if (different_address == true) {
                    jQuery('#different_address').prop('checked', true);
                    jQuery("#collapseOne").collapse('show');
                }
            }

        }, 'json');
}

/**
 * updates the picture marker for the company with the form fields
 */
function profile_company_update_picture(error_id)
{

    jQuery('#upload_messages [id^=error_]').each(function () {
        jQuery(this).hide();
    });

    if (error_id > 0)
    {
        if(jQuery('#error_'+error_id).length)
        {
            jQuery('#error_'+error_id).show();
        }

        if(jQuery('#upload_photo_submit_button').length)
        {
            jQuery('#upload_photo_submit_button').prop('disabled', false);
        }
    }
    else
    {
        jQuery('#photo_upload_info').hide();
        jQuery('#photo_upload_success').show();
        jQuery('#upload_photo_submit_button').hide();
        jQuery('#cancel_upload').val('schließen');

        // Update image in form
        profile_company_update_pictures_in_company_profile();
        window.setTimeout("modal_hide();", 6000);
    }
}

function profile_company_update_pictures_in_company_profile()
{
    //Update image in form
    jQuery.get("/profile_company/create.php?action=show_picture_form_show",
        function(r){
            jQuery("#marker_picture_show").html(r);
        }
    );
    jQuery.get("/profile_company/create.php?action=show_picture_form_edit",
        function(r){
            jQuery("#marker_picture_edit").html(r);
        }
    );
}

function profile_staff_recruiter_save_data() {

    jQuery.post('/profile_staff/recruiter.php', 
        'action=save_data&' + jQuery('#data_form_recruiter_profile').serialize(),
        function (result) {
            var j = result;

            if (j['error'] == 0) {
                jQuery('#container_recruiter_profile').html(j['html']);
                modal.show_message('Das Profil wurde erfolgreich gespeichert.', 5000, 'success');

            }
            else {
                // Show the error
                jQuery('#container_recruiter_profile').html(j['html']);
            }
        },'json');
}