window.addEventListener("load", function() {

    "use strict";

    /**
     * Mobile version Top Navigation
     */
    jQuery(".nav-top-mobile .nav .dropdown").on("click", function () {

        var clicked = jQuery(this);

        //1) Remove class active on all elements except one which has been clicked; down/up icon changed with CSS
        jQuery(".nav-top-mobile .nav .dropdown.active").not(clicked).removeClass("active");

        //2) Remove active class from Westside Navigation
        jQuery(".nav-mobile .list-group.active").removeClass("active").children("a.westnavi_toplevel").removeClass("active");

        clicked.toggleClass("active");
    });

    /**
     * Mobile version Westside Navigation
     */
    jQuery(".nav-mobile .list-group").on("click", function () {

        var clicked = jQuery(this);

        //1) Remove class active on all elements except one which has been clicked; down/up icon changed with CSS
        jQuery(".nav-mobile .list-group.active").not(clicked).removeClass("active").children("a.westnavi_toplevel").removeClass("active");

        //2) Remove active class from TopNavigation; down/up icon changed with CSS
        jQuery(".nav-top-mobile .nav .dropdown.active").removeClass("active");

        clicked.toggleClass("active").children("a.westnavi_toplevel").toggleClass("active");
    });



    /**
     * Get a whole href. For instance http://localhost:8081/main/newsletter.php
     * Then split it to 2 parts => 1) http:// and 2) /main/newsletter.php
     * Then highlight TopMenu, WestsideMenu
     **/
    var pathArray = window.location.href.split(window.location.host);

    var view_position = pathArray[1].indexOf("view=");

    if (view_position >= 0) {

        var view = pathArray[1].substr(view_position);
        var pos = view.indexOf('&');

        if (pos >= 0) {
            view = view.substr(5, pos - 5);
        } else {
            view = view.substr(5);
        }

        //Menu - west side
        jQuery('.nav-aside-section > a[fl-view="'+view+'"]').addClass('active west-menu-active-link').parents('.nav-mobile .list-group').addClass('active');

        //Menu - west side small = tablets
        jQuery('.nav-aside-small-sub > li > a[fl-view="'+view+'"]').addClass('primary-color').parents('.nav-aside-small-link').addClass('active');

        //Menu Top navigation
        jQuery('.dropdown-menu > li > a[fl-view="'+view+'"]').addClass('header-menu-active-link').parents('.dropdown').addClass('active');

    } else {
        //Menu - west side
        var west_side = jQuery('.nav-aside-section > a');
        jQuery.each(west_side, function (index, menu_link) {
            if (jQuery(menu_link).attr('href') === pathArray[1] || jQuery(menu_link).attr('href') === window.location.pathname) {
                jQuery(menu_link).addClass('active west-menu-active-link').parents('.nav-mobile .list-group').addClass('active');
            }
        });

        //Menu - west side small = tablets
        var west_side_small = jQuery('.nav-aside-small-sub > li > a');
        jQuery.each(west_side_small, function (index, menu_link) {
            if (jQuery(menu_link).attr('href') === pathArray[1] || jQuery(menu_link).attr('href') === window.location.pathname) {
                jQuery(menu_link).addClass('primary-color').parents('.nav-aside-small-link').addClass('active');
            }
        });

        //Menu Top navigation
        var top_navigation = jQuery('.dropdown-menu > li > a');
        jQuery.each(top_navigation, function (index, menu_link) {
            if (jQuery(menu_link).attr('href') === pathArray[1] || jQuery(menu_link).attr('href') === window.location.pathname) {
                jQuery(menu_link).addClass('header-menu-active-link').parents('.dropdown').addClass('active');
            }
        });
    }
});