/**
 * Saves the settings for Newsletter
 */
function newsletter_save_settings()
{
	jQuery.post('/main/newsletter.php',
		urlParamsToObject(jQuery('#settings_form').serialize()+'&action=save') ,
		function(t) {
			jQuery('#marker_settings').html(t);
		}
	);
}

function commercial_save_settings()
{
	jQuery.post('/main/commercial_emails.php',
		urlParamsToObject(jQuery('#settings_form').serialize()+'&action=save') ,
		function(t) {
			jQuery('#marker_settings').html(t);
		}
	);
}