/**
 * ...
 */
function account_check_payment_password() {

    jQuery.post('/account/ajax.php',
        'action=payment_password&' + jQuery('#form_payment_check_password').serialize(),
        function (transport) {
            var r = transport;

            jQuery('#marker_payment_data').html(r["content"]);

            if (r["error"] == 0) {
                new MaskedPassword(document.getElementById("cc_security_code"), '\u25CF');
            }

        }, 'json');
}

/**
 * Saves the login data and refreshes the according div
 */
function account_save_logindata() {
    jQuery.post('/account/settings.php',
        'action=save_login_information&' + jQuery('#login_information_form').serialize(),
        function (transport) {
            var r = transport;
            jQuery('#marker_login_information').html(r["content"]);

            checkPanelsEqualHeight(); // make both panels same height

            if (r["error"] == 0) {
                modal.show_message(r["success_msg"], '50000', 'success');
            }
        }, 'json');
}


function postlogin_consents() {

    jQuery.post('/checker/checker.php',
        jQuery('#postlogin_consents_form').serialize(),
        function (transport) {
            var r = transport;

            jQuery('#marker_postlogin_consents').html(r["html"]);

            if (r["error"] == 0) {
                jQuery('#marker_postlogin_consents').hide();
                var onclick = "window.location='" + r["redirectUrl"] + "'";
                modal.show_message('Ihre Einstellungen wurden erfolgreich übernommen.', '500000', 'success', 'ok', null, onclick);
            }
        }, 'json');
}


/**
 * executes log out on any mobile devices
 */
function account_kickout_mobile_devices() {
    jQuery.post('/account/settings.php',
        'action=kickout_mobile_devices'
    );
}


function account_change_abo_step2() {
    // get the radio value
    var radioval = jQuery("input:radio[name='package']:checked").val();
    var number_single_licenses = 0;

    if (jQuery("#number_single_licenses").length) {
        number_single_licenses = jQuery("#number_single_licenses").val();
    }

    if (radioval || number_single_licenses > 0) {
        jQuery('#canellation_area').hide();
        jQuery('#marker_payment_data').hide();
        jQuery('#marker_billing_address').hide();
        jQuery('#marker_invoice').hide();
        jQuery('#marker_membership_cancellation').hide();

        jQuery.post('/account/ajax.php',
            'action=get_abo_change_package&' + jQuery('#package_form').serialize(),
            function (transport) {
                var r = transport;

                if (r["error"] > 0) {
                    modal.show_message(r["result"], 1000000, 'error');
                } else {
                    jQuery('#edit_package_steps').html(r['result']);
                }
            }, 'json');

    } else if (radioval != undefined) {
        jQuery.post('/account/ajax.php',
            'action=revert_cancellation_of_premium_account',
            function (transport) {
                var r = transport;
                account_refresh_package();

                if (r["error"] != 0) {
                    modal.show_message(r["error_message"], 1000000, 'error');
                    flScrollTo('#content_box_header');
                } else {
                    modal.show_message('Ihre Kündigung wurde soeben rückgängig gemacht.	Die Mitgliedschaft verlängert sich am Ende der Laufzeit automatisch.', 5000, 'success');
                    flScrollTo('#content_box_header')
                }
            }, 'json');
    }
}

function cancel_membership() {

    jQuery.post('/account/ajax.php',
        'action=get_abo_change_package&package=cancel',
        function (transport) {
            var r = transport;

            if (r["error"] > 0) {
                modal.show_message(r["result"], 1000000, 'error');
            } else {
                jQuery('#cancellation_button').html(r['result']);
            }
        }, 'json');
}


function revert_cancel_membership() {

    jQuery.post('/account/ajax.php',
        'action=revert_cancellation_of_premium_account',
        function (transport) {
            var r = transport;
            account_refresh_package();

            modal.show_message('Ihre Kündigung wurde soeben rückgängig gemacht.	' +
                'Die Mitgliedschaft verlängert sich am Ende der Laufzeit automatisch.', 50000, 'success');
            flScrollTo('#marker_package');
        }, 'json');
}



/**
 * saves the payment information on the account page
 */
function account_save_billing_address() {

    jQuery.post('/account/edit_payment_data.php',
        'ajax=1&' + jQuery('#form_billing_address').serialize(),
        function (r) {
            jQuery('#marker_billing_address').html(r);
            if (jQuery('#marker_upgrade').length) {
                account_change_abo_step2();
            }
        });
}

function account_refresh_package() {

    jQuery('#marker_package').load('/account/ajax.php', urlParamsToObject('action=refresh_package'));
}

function show_hashed_password(password, hide_span_name, show_span_name) {
    jQuery.post('/account/ajax.php',
        'action=show_hashed_password&pass=' + encodeURI(password),
        function (transport) {
            // we receive here a json response
            var result_array = transport;
            if (result_array["error_id"] == 0) {
                jQuery('#hide_span_name').hide();
            }
            jQuery('#show_span_name').show();
            jQuery('#show_span_name').html(result_array["msg"]);
        }, 'json');
}


/**
 * Shows or hides the div which contains the vat id feld
 * @param country_id
 */
function checkCountryToShowVatBox(country_id) {
    jQuery.post('/account/ajax.php',
        'action=must_vat_id&country=' + country_id,
        function (transport) {
            var r = transport;
            if (r['must_vat_id'] == 1) {
                if (r['type'] == 'EU') {
                    jQuery('#ust_id_box').show();
                    jQuery('#ust_id_eu').show();
                } else {
                    jQuery('#ust_id_box').hide();
                    jQuery('#ust_id_eu').hide();
                }
            } else {
                jQuery('#ust_id_box').hide();
            }
        }, 'json');
}

/**
 * Shows the confirmation windows before the account is deleted.
 */
function account_confirm_deletion() {
    jQuery.ajax({
        url: '/account/ajax.php',
        type: 'POST',
        data: "action=confirm_account_deletion",
        async: true,
        success: function (result) {
            var response = new AjaxResponse(result);
            if (response.error == 0) {
                modal.setType('string');
                modal.setSource(response.html);
                modal.setTitle('Stammdaten');
                modal.show();
            }
        }
    });
}

/**
 * Deletes the account of the currently logged in user.
 */
function account_delete() {
    jQuery.ajax({
        url: '/account/ajax.php',
        type: 'POST',
        data: "action=delete_account",
        async: true,
        success: function (result) {
            var response = new AjaxResponse(result);

            if (response.error == 0) {

                modal.setType('string');
                modal.setSource(response.html);
                modal.setTitle('Stammdaten');
                modal.show();

                // logout the user after sometime
                window.setTimeout(function () {
                    document.location.href = '/logout.php';
                }, 1500);
            }
        }
    });
}


function customer_opinion_delete() {
    jQuery.post('/account/ajax.php',
        'action=delete_customer_opinion',
        function (transport) {
            var result = transport;
            if (result["error_id"] == 0) {

                jQuery.post('/account/settings.php',
                    'action=render_customer_opinion',
                    function (transport) {
                        var result = transport;
                        if (result["error_id"] == 0) {
                            jQuery("#marker_settings").html(result["html"]);
                            modal.show_message('Ihr Kundenstimme wurde erfolgreich gelöscht', '5000', 'success');
                        }
                    }, 'json');
            }
        }, 'json');
}


function force_login(key) {
    jQuery.post('/account/ajax.php',
        'action=force_login&key=' + key,
        function (transport) {
            var result = transport;
            if (result["error"] == 0) {
                document.location.href = result["url"];
            } else {
                modal.show_message("Ein Fehler ist aufgetreten. Das tut uns leid. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.", 1000000, 'error');
            }
        }, 'json');
}


jQuery('#marker_package').on('change', '#number_single_licenses', function() {
    if (jQuery(this).val() != 0) {
        FL_enable('#next');
    } else {
        FL_disable('#next');
    }
});


function show_form_membership_edit(key) {
    jQuery.post('/account/ajax.php',
        'action=show_form_membership_edit&key=' + key,
        function (transport) {
            var result = transport;
            jQuery('#edit_package').html(result['result_html']);
        }, 'json');
}
