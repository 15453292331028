/**
 * refreshes a part of the staff profile form
 *
 * @param div
 * @param marker
 * @return
 */
function forwarding_reload(div, marker) {
    jQuery('#' + div).load('/settings/forwarding.php?action=refresh&marker=' + marker);
}


function forwarding_change_internal_mail_form_div() {
    var myval = jQuery('#' + 'internal_mail_user_id').val();

    if (myval == "-1") {
        jQuery('#edit_internal_mail_disabled').hide();
        jQuery('#edit_internal_mail_short').hide();
        jQuery('#' + 'edit_internal_mail_long').show();
    }
    else if (myval == "0") {
        jQuery('#' + 'edit_internal_mail_disabled').show();
        jQuery('#edit_internal_mail_short').hide();
        jQuery('#edit_internal_mail_long').hide();
    }
    else {
        jQuery('#' + 'edit_internal_mail_short').load('/profile_staff/ajax.php?action=get_staff_profile_item_big&id=' + myval,
            function (r) {
                jQuery('#edit_internal_mail_disabled').hide();
                jQuery('#edit_internal_mail_long').hide();
                jQuery('#' + 'edit_internal_mail_short').show();
        });
    }
    return false;
}


function forwarding_save_internal_mail() {
    jQuery('#' + 'marker_internal_mail').load('/settings/forwarding.php',
        urlParamsToObject('action=save_internal_mail&' + jQuery('#internal_mail_form').serialize())
    );
}


function forwarding_save_application() {
    jQuery('#' + 'marker_application').load('/settings/forwarding.php',
        urlParamsToObject('action=save_application&' + jQuery('#application_form').serialize())
    );
}


function forwarding_change_application_form_div() {
    var myval = jQuery('#' + 'application_user_id').val();

    if (myval == "-1") {
        jQuery('#edit_application_disabled').hide();
        jQuery('#edit_application_short').hide();
        jQuery('#' + 'edit_application_long').show();
    }
    else if (myval == "0") {
        jQuery('#' + 'edit_application_disabled').show();
        jQuery('#edit_application_short').hide();
        jQuery('#edit_application_long').hide();
    }
    else {
        jQuery('#' + 'edit_application_short').load('/profile_staff/ajax.php?action=get_staff_profile_item_big&id=' + myval,
             function (r) {
                jQuery('#edit_application_disabled').hide();
                jQuery('#edit_application_long').hide();
                jQuery('#' + 'edit_application_short').show();
            });
    }
    return false;
}


// SKILLPLANET

/**
 * Connect skillplanet, name and email checkboxes.
 */
function toggle_skillplanet() {
    var skillplanetCheckbox = jQuery("#skillplanet");
    if (jQuery("#show_name").prop("checked") && jQuery("#show_email").prop("checked")) {
        skillplanetCheckbox.prop("disabled", false);
        skillplanetCheckbox.removeClass("disabled checkbox-readonly");
    }
    else {
        skillplanetCheckbox.prop("checked", false);
        skillplanetCheckbox.prop("disabled", true);
        skillplanetCheckbox.addClass("disabled checkbox-readonly");
    }
}

// HOLIDAY REPLACEMENT - ONLY PROJECT PROVIDER
function load_holiday_replacement_edit_form() {
    jQuery.post('/settings/holiday_replacement.php',
        {
            action: 'load_edit_form'
        },
        function (result) {
            var json = result;
            jQuery('#' + 'status-box').html(json['result_html']);
        }, 'json');
}

function load_holiday_status() {
    jQuery.post('/settings/holiday_replacement.php',
        {
            action: 'load_holiday_status'
        },
        function (result) {
            var json = result;
            jQuery('#' + 'status-box').html(json['result_html']);
        }, 'json');
}

function save_holiday_replacement_form() {
    jQuery.post('/settings/holiday_replacement.php',
        'action=save&' + jQuery('#holiday_replacement-form').serialize(),
        function (result) {
            var json = result;
            jQuery('#' + 'status-box').html(json['result_html']);
        }, 'json');
}

function load_default_holiday_replacement_text() {
    // The replacement person must be set.
    if (jQuery("#replacement_person").val() === "") {
        return false;
    }

    // The replacement start date must be set.
    if (jQuery("#replacement_duration_start").val() === "") {
        return false;
    }

    // The replacement end date must be set.
    if (jQuery("#replacement_duration_end").val() === "") {
        return false;
    }

    jQuery.post('/settings/holiday_replacement.php',
        'action=load_replacement_text&' + jQuery('#holiday_replacement-form').serialize(),
        function (result) {
            var json = result;
            jQuery('#autoresponder').val(json['result_html']);
        }, 'json');
}

function data_policy_upload_file() {
    var formData = new FormData(jQuery('#upload_data_policy_file')[0]);
    var xhr = new XMLHttpRequest();

    xhr.overrideMimeType("application/json");
    xhr.open('POST', '/application/settings.php', false);

    // Set up a handler for when the request finishes.
    xhr.onload = function () {
        if (xhr.status !== 200) {
            modal.show_message('Ein unerwarteter Fehler ist aufgetreten.');
        }
    };

    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
            var json = JSON.parse(xhr.responseText);

            jQuery('#photo_upload_info').hide();

            if (json['error'] == '0') {
                modal.show_message(json['message'], null, 'success');
            } else {
                modal.show_message(json['message'], 1000000, 'error');
            }
        }
    };

    xhr.send(formData);

    return false;
}