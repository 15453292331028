function imageCroppie(profile_id, type, photo_thumbnail) {
    // http://foliotek.github.io/Croppie/

    var croppie;

    var viewport = {
        width: 200,
        height: 200
    };

    var boundary = {
        width: 260,
        height: 260
    };

    if (type === "document") {
        viewport = {
            width: 82,
            height: 98
        };
    }

    function get_image_data(image) {
        return new Promise(function (resolved, rejected) {
            var i = new Image();
            i.onload = function() {
                resolved({width: i.width, height: i.height, image: image});
            };
            i.src = image;
        });
    }

    function count_init_zoom_for_cropie(imgData) {
        var zoom = 1;
        if (imgData.width > imgData.height) {
            if (imgData.width > viewport.width) zoom = viewport.height / imgData.height;
        } else {
            if (imgData.height > viewport.height) zoom = viewport.width / imgData.width;
        }
        return zoom;
    }

    function preview_image(image) {
        var formData = new FormData(jQuery('#photo_upload_form')[0]);

        jQuery.ajax({
            url: '/documents/photo_edit.php',
            type: 'POST',
            data: formData,
            dataType: 'json',
            contentType: false,
            cache: false,
            processData: false,
            success: function (json) {
                if (json['error'] === '') {
                    jQuery('#fl_modal .alert').hide();
                    get_image_data(json['img']).then(function (imgData) {
                        croppie.bind({
                            url: imgData.image,
                            // zoom: count_init_zoom_for_cropie(imgData)
                        });
                    });
                }
                else {
                    jQuery('#fl_modal .alert').html(json['error']).show();
                }
            }
        });
    }

    croppie = new Croppie(document.getElementById('croppie-photo'), {
        viewport: viewport,
        boundary: boundary,
        enableOrientation: true,
        enforceBoundary: true
    });

    croppie.bind({
        url: photo_thumbnail,
        zoom: 0.0001
    });

    jQuery('#photo').on('change', function () {
        preview_image(this.files[0]);
    });

    jQuery('.croppie-rotate').on('click', function () {
        croppie.rotate(parseInt(jQuery(this).data('deg')));
    });

    jQuery('#upload_photo_submit_button').on('click', function () {
        croppie.result({
            size: 'original',
            format: 'jpeg',
            backgroundColor: "#ffffff"
        }).then(function (base64Image) {
            jQuery.ajax({
                url: '/documents/photo_edit.php',
                type: 'POST',
                data: {
                    action: 'upload_photo',
                    profile_id: profile_id,
                    type: type,
                    data: base64Image
                },
                dataType: 'json',
                success: function (json) {
                    if (json['error'] === "") {
                        if (type === 'company') {
                            if (jQuery('.js-company-logo').length > 0) {
                                jQuery('.js-company-logo').parent('.photo-edit').replaceWith(json['result_html']);
                            }
                            modal.hide();
                        } else if (type === 'document') {
                            modal.showFLPopup('request','/profile_freelancer/documents.php','action=choose_thumbnail&newthumbnail=true&document_id='+json['result_document_id'],'Vorschaubild ändern','');
                        } else {
                            if (jQuery('.js-thumbnail').length > 0) {
                                jQuery('.js-thumbnail').parent('.photo-edit').replaceWith(json['result_html']);
                            }
                            if (jQuery('.js-small-thumbnail').length > 0) {
                                jQuery('.js-small-thumbnail').attr('src', json['result_small_thumbnail']);
                            }
                            modal.hide();
                        }

                    } else {
                        jQuery('#fl_modal .alert').html(json['error']).show();
                    }
                }
            });
        });
    });
}