class AutocompletionCity {

	constructor(city_field, area_field, behaviour, user_field1, user_field2, user_field3, user_field4, options) {

		options = typeof options !== 'undefined' ? options : {};

		this.autocompletionField = city_field;
		this.autocompletionArea = area_field;
		this.errorField = 'error-field-' + this.autocompletionField;
		this.userField1 = user_field1;
		this.userField2 = user_field2;
		this.userField3 = user_field3;
		this.userField4 = user_field4;
		this.behaviour = behaviour;
		this.typingTime = null;
		this.isShown = false;

		this.clickHandler = typeof options.clickHandler !== 'undefined' ? options.clickHandler : null;
		this.notFoundHandler = typeof options.notFoundHandler !== 'undefined' ? options.notFoundHandler : null;

		var self = this;

		jQuery( "#" + this.autocompletionField ).keyup(function(event) {
			if (Event.KEY_ESC == event.which) {
				self.typingTime = null;
				if (jQuery("#" + self.autocompletionArea).length) {
					jQuery("#" + self.autocompletionArea).hide();
					jQuery("#" + self.autocompletionArea).html('');
					return false;
				}
			}
		});

		jQuery(document.body).on('focus', function (event) {
			self.hideAutocompletion();
		});

		jQuery(document.body).on('click', function (event) {
			self.hideAutocompletion();
		});

	}


	/**
	 * handlerfor onkeyup, saves the last time when the user has anything typed
	 */
	cityTyping () {
		var start = false;

		jQuery("#" + this.autocompletionField).css({'background-color': 'white'});

		if (this.behaviour == 'search') {
			jQuery('#' + this.userField4).val('');
		}

		if (this.typingTime == null) {
			start = true;
		}

		// refresh the time that shows that the user is still typing
		this.typingTime = (new Date()).getTime();

		if (start) {
			if (jQuery("#" + this.errorField).length) {
				jQuery('[id=' + this.errorField + ']').each(function (i, element) {
					element.hide();
				});
			}
			this.isCityTyping();
		}
	}

	/**
	 * checks ifthe user is still typing
	 */
	isCityTyping () {
		// time to wait for the user after he has finished typing
		var timeToWait = 500;
		var self = this;

		if (this.typingTime != null) {
			// the time now as unix timestamp
			var now = (new Date()).getTime();

			if (now - this.typingTime >= timeToWait) {
				// check what the user has typed
				var res = this.getGeoDataCity();
				this.typingTime = null;
			} else {
				// execute myself after 100 msec
				setTimeout(function () {
					self.isCityTyping();
				}, 100);
			}
		}
	}

	/**
	 * gets the data through an ajax request
	 */
	getGeoDataCity () {
		var self = this;
		var autocompletionValue = jQuery('#' + this.autocompletionField).val();
		var autocompletionAreaElement = jQuery('#' + this.autocompletionArea);

		if (autocompletionAreaElement.length <= 0) {
			return false;
		}

		if (autocompletionValue === '') {
			autocompletionAreaElement.hide();

			return false;
		}

		jQuery.ajax({
			type: 'POST',
			url: '/project/ajax.php',
			data: {
				action: 'get_geo_data_city_completion',
				q: autocompletionValue,
				country_id: jQuery('#' + this.userField1).val(),
				behaviour: this.behaviour,
				auto_field_name: this.autocompletionField,
				area_field_name: this.autocompletionArea,
				field_name1: this.userField1,
				field_name2: this.userField2,
				field_name3: this.userField3,
				field_name4: this.userField4
			},
			dataType: 'json',
			success: function (json) {
				if (json['error'] == 0) {
					autocompletionAreaElement.show();
					autocompletionAreaElement.html(json['html_result']);
					self.isShown = true;

					if (self.clickHandler != null) {
						jQuery(".place-autocompleter a").on('click', function () {
							self.clickHandler();
						});
					}
				} else {
					autocompletionAreaElement.hide();
					autocompletionAreaElement.html('');
					self.isShown = false;

					if (self.notFoundHandler !== null) {
						self.notFoundHandler();
					}
				}
			}
		});
	}

	hideAutocompletion () {
		if (this.isShown) {
			jQuery('#' + this.autocompletionArea).hide();
			this.isShown = false;
		}
	}
}

function checkValuesToDisablePerimeter(distance_field, disable)
{
	if(disable == true)
	{
		jQuery('#' + distance_field).val(0);
		jQuery('#' + distance_field).prop('disabled', true);
	}
	else
	{
		jQuery('#' + distance_field).prop('disabled', false);
	}

	jQuery('.selectarea').selectpicker('refresh');
}

function checkValuesToShowHint(distance_field, country_field) {
	if(jQuery('#' + country_field).val() == 0)
	{
		jQuery('#' + distance_field).val(0);
		jQuery('#' + distance_field).prop('disabled', true);
	}
	else
	{
		jQuery('#' + distance_field).prop('disabled', false);
		jQuery('#' + distance_field).enabled = true;
	}
}

function toggleZipField(city)
{
	var country = jQuery('#country').val();
	var city_value = '';
	if (city !== null && city !== undefined) {
		city_value = city;
	}
	var zip_container = jQuery('#zip_container');
	var zipcode_city = jQuery('#zipcode_city');
	if (country == 76 || country == 12 || country == 198) {
		zip_container.hide();
		zipcode_city.attr("placeholder", "PLZ oder Ort oder Bundesland");
	} else {
		zip_container.show();
		zipcode_city.attr("placeholder", "Ort");
	}
	zipcode_city.val(city_value);
}

function addLocationToProject()
{
	var location_container = jQuery('#location_container'); 
	var zipcode = jQuery('#zipcode'); 
	var zipcode_city = jQuery('#zipcode_city'); 
	var project_zip_value = jQuery('#project_zip_fieldname').val();

	if(project_zip_value !== '' && project_zip_value !== '-') // data comes from autocompletion
	{
		var location_type = 'string';
		if(isNaN(zipcode_city.val()) === false)
		{
			location_type = 'numeric';
		}

		jQuery.post(
			'/project/ajax.php',
			{
				action: 'get_location_template',
				zip: project_zip_value,
				city: jQuery('#project_city_fieldname').val(),
				county: jQuery('#project_county_fieldname').val(),
				country: jQuery('#country').val(),
				type: location_type
			},
			function (json) {
				if (json['error'] === 0) {
					location_container.append(json['html']);
					jQuery('#project_zip_fieldname').val('');
				}
			},
			'json'
		);
	}
	else
	{
		var city_value = zipcode_city.val();
		var county_value = null;
		if(project_zip_value === '-')
		{
			city_value = null;
			county_value = zipcode_city.val();
		}

		if(zipcode_city.val() !== "" || zipcode.val() !== "") // data was introduced manually or a county was introduced automatically 
		{
			jQuery.post(
				'/project/ajax.php',
				{
					action: 'get_location_template',
					zip: zipcode.val(),
					city: city_value,
					county: county_value,
					country: jQuery('#country').val(),
					type: 'string'
				},
				function (json) {
					if (json['error'] === 0) {
						location_container.append(json['html']);
					}
				},
				'json'
			);
		}
	}

	zipcode.val('');
	zipcode_city.val('');
	zipcode_city.css('background-color', 'white');

	// remove error from input
	if (zipcode_city.hasClass("error")) {
		zipcode_city.siblings("span.error-text").remove();
		zipcode_city.removeClass("error");
	}
}

function updateButtonStatus(zip, zip_city, button_id)
{
	if(jQuery('#' + button_id).length)
	{
		if(jQuery('#' + 'country').val() == 76 || jQuery('#' + 'country').val() == 12 || jQuery('#' + 'country').val() == 198) {
			if(zip != '' || zip_city != '')  {
				jQuery('#' + button_id).prop('disabled', false);
			} else  {
				jQuery('#' + button_id).prop('disabled', true);
			}
		} else {
			jQuery('#' + button_id).prop('disabled', false);
		}
	}
}

function projectLocationAutocompletion(location_name, country_id, county_name, zip_id) {
	jQuery('#zipcode_city').val(location_name);
	jQuery('#project_city_fieldname').val(location_name);
	jQuery('#country').val(country_id);
	jQuery('#project_county_fieldname').val(county_name);
	jQuery('#project_zip_fieldname').val(zip_id);
}

function projectZipCodeKeyUpHandler(autocompleteObject, country)
{
	if(country == 76 || country == 12 || country == 198 || country == '') {
		autocompleteObject.cityTyping();
	}
}