var Maintest = function () {

    this.url = '/main/ajax_maintest.php';
    this.companyFieldNumber = 1;

    this.alert = function (text) {
        alert(text);
    };

    this.alertUrl = function () {
        alert(this.url);
    };

    this.showDiv = function (id_div) {
        jQuery('#' + 'div_firma').hide();
        jQuery('#' + 'div_ort').hide();
        jQuery('#' + 'div_skills').hide();
        jQuery('#' + 'div_stichworter').hide();
        jQuery('#' + 'div_projekttyp').hide();
        jQuery('#' + id_div).show();
    };

    this.getCompanyField = function () {
        jQuery.post(this.url,
            'action=get_company_field',
            function (response) {
                var json = response;

            }, 'json');
    };

    this.makeAjax = function () {
        jQuery.post(this.url,
            'action=get_some_text&active=1',
            function (response) {
                var json = response;
                if (json['error'] != 1) {
                    // everything is ok
                    //alert(json['some_text']);

                    jQuery('#ajax_test_div').html(json['some_text']);
                } else {
                    document.location.href = '';
                }
            }, 'json');
    };

    this.showUser = function (userId) {
        jQuery.post(this.url,
            'action=show_user&active=1&user_id=' + userId,
            function (response) {
                var json = response;
                if (json['error'] != 1) {
                    var text = json['id'] + '<br />';
                    text = text + json['email'] + '<br />';
                    text = text + json['active'] + '<br />';
                    text = text + json['companyname'] + '<br />';

                    jQuery('#user_detail').html(text);
                } else {
                    jQuery('#user_detail').html('error');
                    //document.location.href = '';
                }
            }, 'json');

    };

    this.showTabFirma = function () {
        jQuery.post(this.url,
            'action=show_tab_firma',
            function (response) {
                var json = response;
                if (json['error'] != 1) {
                    var text = json['content'];
                    jQuery('#div_filter').html(text);
                } else {
                    jQuery('#div_filter').html('error');
                }
            }, 'json');
    };
};

var maintest = new Maintest();