var TabMenu = function () {
	this.tabMenuDiv = 'tab_menu_main_container';
	this.mainContainerMarker = '<!-- ###TAB_MENU_MAIN_CONTAINER### -->';

	this.selectMenuItem = function (url) {
		if (jQuery('#' + this.tabMenuDiv).length()) {
			jQuery.post(url, function (transport) {
					try {
						var result = transport;
						var startPosition = result.indexOf(tabMenu.mainContainerMarker) + tabMenu.mainContainerMarker.length;
						var endPosition = result.lastIndexOf(tabMenu.mainContainerMarker);
						result = result.substring(startPosition, endPosition);

						jQuery('#' + tabMenu.tabMenuDiv).html(result);
					} catch (e) {
						alert('exception: ' + e.message);
					}
				});
		}

		return false;
	};

	this.selectActive = function () {

	};

	this.setActive = function () {
		alert('setActive');
	};

};

var tabMenu = new TabMenu();