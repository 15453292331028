/**
 * Placeholder parser.
 */
function FL_ItemParser(container_value) {
	this.container = container_value;
	
	this.executeCallback = function() {
		f = eval(this.evalCallback);
		return f();
	}
	
	this.displayAttrClass = function(element, attr_name, attr_value) {
		var elements = jQuery(element).find("[fl_class='" + attr_name + "']");
		
		if (elements !== undefined) {
			for (var j = 0; j < elements.length; j++) {
				jQuery(elements[j]).addClass(attr_value);
			}
		}
	}
	
	this.displayAttrValue = function(element, attr_name, attr_value) {
		var elements = jQuery(element).find("[fl_placeholder='" + attr_name + "']");
		
		if (elements !== undefined) {
			if (elements instanceof Array) {
				for (var j = 0; j < elements.length; j++) {
					elements[j].html(attr_value);
				}
			} else {
				elements.html(attr_value);
			}
		}
	}
	
	this.displayAttrHref = function(element, attr_name, attr_value) {
		var elements = jQuery(element).find("[fl_href='" + attr_name + "']");
		
		if (elements !== undefined) {
			if (elements instanceof Array) {
				for (var j = 0; j < elements.length; j++) {
					elements[j].attr('href', attr_value);
				}
			} else {
				elements.attr('href', attr_value);
			}
		}
	}
	
	this.displayAttrOnclick = function(element, attr_name, attr_value) {
		var self = this;
		var elements = jQuery(element).find("[fl_onclick='" + attr_name + "']");
		
		if (elements !== undefined) {
			var code = 'x = function() {' + attr_value + '};';
			
			for (var j = 0; j < elements.length; j++) {
				jQuery(elements[j]).on('click', function() {
					self.evalCallback = code;
					return self.executeCallback();
				});
			}
		}
	}

	this.displayAttrTooltip = function(element, attr_name, attr_value) {
		var elements = jQuery(element).find("[fl_tooltip='" + attr_name + "']");

		if (elements !== undefined) {
			for (var j = 0; j < elements.length; j++) {
				jQuery(elements[j]).attr("data-toggle", "tooltip");
				jQuery(elements[j]).attr("title", attr_value);
				jQuery(elements[j]).attr("data-placement", jQuery(elements[j]).attr("fl_tooltip_position"));
			}
		}
	}
}

FL_ItemParser.prototype.display = function() {
	var obj = this;
	jQuery("div[fl_container^='" + this.container + "']").each(function() {
		var attrs = this.attributes;
		
		for(var i = 0; i < attrs.length; i++) {
			var attr_name = attrs[i].nodeName;
			var attr_value = attrs[i].nodeValue;
			
			if (attr_name.indexOf('fl_value') === 0) {
				
				// set content
				obj.displayAttrValue(this, attr_name, attr_value);
				
			} else if (attr_name.indexOf('fl_class') === 0){
				
				// set style
				obj.displayAttrClass(this, attr_name, attr_value);
				
			} else if (attr_name.indexOf('fl_href') === 0){
				
				// set link
				obj.displayAttrHref(this, attr_name, attr_value);
			} else if (attr_name.indexOf('fl_onclick') === 0){
				
				// set link
				obj.displayAttrOnclick(this, attr_name, attr_value);
			} else if (attr_name.indexOf('fl_tooltip') === 0){

				// set tooltip
				obj.displayAttrTooltip(this, attr_name, attr_value);
			}
		}
	});
};

