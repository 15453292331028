function recruiting_freelancer_edit_save(profile_id)
{
	jQuery.post("/recruiting/ajax.php?action=save_user_profile", jQuery("#o_user_profile_" + profile_id).serialize(), function(r){
		a_data = jQuery.parseJSON(r);
		jQuery('#span_user_profile_availability_' + profile_id).html(a_data[0]);
		jQuery('#span_user_profile_ambit_' + profile_id).html(a_data[1]);
		jQuery('#span_user_profile_freetext_' + profile_id).html(a_data[2]);
		
		jQuery('#stored_user_addition_data_display_' + profile_id).show();
		jQuery('#stored_user_addition_data_form_' + profile_id).hide();	
	});
}

function recruiting_freelancer_edit(profile_id)
{
	jQuery('#stored_user_addition_data_form_' + profile_id).toggle();
}

function recruiting_freelancer_to_request(request_id, id, type, entry_id)
{	
	if(type== 1 || type == 0 && confirm("Sicher löschen?"))
	{
		
		//alert(request_id + '--' + id + '--' + type + '--' + entry_id);

		jQuery.post('/recruiting/ajax.php',
			'action=freelancer_to_request&request_id=' + request_id + '&id=' + id + '&type=' + type + '&search_id=' + jQuery('#hidden_search_id').val(),
			function (r) {

				if (jQuery('#search_result_id_' + id).length) {
					jQuery('#search_result_id_' + id).hide();
				}

				if (type == 1) {
					//alert("Gemerkt");
				}

				if (type == 0) {
					jQuery('#stored_' + entry_id).hide();
				}
			});
	}
}

function recruiting_freelancer_change_status(entry_id, status)
{
	a_color = new Array('#ffffff', '#f8c076', '#aee4ff', '#008000', '#ffb3b3', '#ffffff');

	jQuery.post('/recruiting/ajax.php',
		'action=freelancer_status&id=' + entry_id + "&status=" + status,
		function (r) {

			jQuery('#stored_' + entry_id).css("background-color", a_color[r.trim()]);
			jQuery('#stored_' + entry_id).css("color", '#333333');
			jQuery('#span_name_' + entry_id).css("color", '#333333');

			if (a_color[r.trim()] == '#008000') {
				jQuery('#stored_' + entry_id).css("color", '#ffffff');
				jQuery('#span_name_' + entry_id).css("color", '#ffffff');
			}
		});
}

function recruiting_category_delete()
{	
	if(confirm('Sicher löschen? ' + jQuery('#comment_category').val()))
	{
		jQuery.post('/recruiting/ajax.php',
			'action=category_delete&id=' + jQuery('#comment_category').val(),
			function (r) {
				jQuery("#comment_category option:selected").remove();
				jQuery("#comment_category").val(0);
				jQuery("#comment_category_delete_image").hide();
			});
	}
	
	return false;
}

function recruiting_category_change()
{
	jQuery('#comment_category_user').val(0);
	
	if(jQuery('#comment_category').val() != 0)
	{
		jQuery('#comment_category_user').hide();
		jQuery('#comment_category_user').val(0);
		jQuery("#comment_category_delete_image").show();
		jQuery('#category_new').val("");
		jQuery('#category_new').hide();
	}
	else
	{
		jQuery('#comment_category_user').hide();
		jQuery('#comment_category_user').val(0);
		jQuery("#comment_category_delete_image").hide();
		jQuery('#category_new').show();
	}
	
	if(jQuery('#comment_category').val() == 'user')
	{
		jQuery("#comment_category_delete_image").hide();
		jQuery('#comment_category_user').show();
		jQuery('#category_new').val("");
		jQuery('#category_new').hide();
	}
}


function recruiting_change_customer()
{
	jQuery.get("ajax.php?action=get_customer_data&id=" + jQuery('#customer_id').val(), function(r){
		a_data = jQuery.parseJSON(r);
		jQuery('#sender_email').val(a_data['contact_email']);
		jQuery('#sender_information').val(a_data['contact_information']);
		jQuery('#status_email_recipients').val((a_data['contact_email'] != '' ? a_data['contact_email'] + ',' : '') + a_data['contact_recipients']);
	});
}