/*
 * Shows or hides the extended search field and sets the hitten parameter to 
 * extended search on or off
 */
function toggle_extended_search_field(extended_search_box_id, search_extended_flag_id)
{
	// Is box hidden?
	if(jQuery("#"+extended_search_box_id).is(":visible") == false)
	{
		// Show the box and set flag to 1
		jQuery("#"+search_extended_flag_id).val("1");
		jQuery("#"+extended_search_box_id).show();

		// Hide search top button
		// jQuery("#search_simple").hide();

		// Hide top link
		jQuery("#link-extended-search").hide();
	}
	// Is box displayed?
	else if(jQuery("#"+extended_search_box_id).is(":visible") == true)
	{
		// Hide the box and set flag to 0
		jQuery("#"+search_extended_flag_id).val("0");
		jQuery("#"+extended_search_box_id).hide();

		// Show search top button
		// jQuery("#search_simple").show();

		// Show top link
		jQuery("#link-extended-search").show();

	}
}


/*
 * Shows or hides the extended search field and sets the hitten parameter to
 * extended search on or off
 */
function toggle_extended_search_field_teaser(extended_search_box_id, search_extended_flag_id)
{
	// Is box hidden?
	if(jQuery("#"+extended_search_box_id).is(":visible") == false)
	{
		// Show the box and set flag to 1
		jQuery("#"+search_extended_flag_id).val("1");
		jQuery("#"+extended_search_box_id).show();
	}
	// Is box displayed?
	else if(jQuery("#"+extended_search_box_id).is(":visible") == true)
	{
		// Hide the box and set flag to 0
		jQuery("#"+search_extended_flag_id).val("0");
		jQuery("#"+extended_search_box_id).hide();
	}
}


/*
 * Changes the value which is used by the search.
 * If possible, run the search again
 */
function change_sort_by_value_and_try_to_search_again()
{
	jQuery('#__search_sort_by').val(jQuery('#__search_sort_by_remote').val());
	if(jQuery('#__search_freetext').val() != "")
	{
		jQuery('#' + '__search').submit();
	}
}

/*
 * Changes the type of freelancer which is used by the search (with or without AC).
 * If possible, run the search again
 */
function change_profile_ac_and_try_to_search_again()
{
	jQuery('#__profile_ac').val(jQuery('#__profile_ac_remote').val());
	jQuery('#' + '__search').submit();
}

/*
 * Changes the value which is used by the search.
 * If possible, run the search again
 */
function change_project_age_and_try_to_search_again()
{
	jQuery('#__search_project_age').val(jQuery('#__search_project_age_remote').val());
	jQuery('#' + '__search').submit();
}

/*
 * Changes the value which is used by the search.
 * If possible, run the search again
 */
function change_project_start_date_and_try_to_search_again()
{
	jQuery('#__search_project_start_date').val(jQuery('#__search_project_start_date_remote').val());
	jQuery('#' + '__search').submit();
}

/*
 * Changes the value which is used by the search.
 * If possible, run the search again
 */
function change_availability_and_try_to_search_again()
{
	jQuery('#__search_profile_availability').val(jQuery('#__search_profile_availability_remote').val());
	jQuery('#' + '__search').submit();
}

/*
 * Changes the value which is used by the search.
 * If possible, run the search again
 */
function change_profile_update_and_try_to_search_again()
{
	jQuery('#__search_profile_update').val(jQuery('#__search_profile_update_remote').val());
	jQuery('#' + '__search').submit();
}

/*
 * Shows a hint if any of the words in array is found
 */
function check_profi_search_words(search_text)
{
	var profi_tokens = new Array(' or ', ' and ', ' oder ', ' und ', '(', ')');
	search_text = search_text.toLowerCase();
	for(i = 0; i < profi_tokens.length; i++)
	{
		if(search_text.indexOf(profi_tokens[i]) !== -1)
		{
			return true;
		}
	}

	return false;
}

/*
 * Checks if the hint for no profi searches must be shown and shows it if necessary
 */
function check_profi_search_hint(search_text, option, searchagent_id)
{
	if(searchagent_id == null)
	{
		var div_profi_hint = 'search_no_profi_hint';
		var div_match_hint = 'match_hint_';
		var radio_value = jQuery('input:radio[id=__freetext_modifier]:checked').val();
	}
	else
	{
		var div_profi_hint = 'search_no_profi_hint_'+searchagent_id;
		var div_match_hint = 'match_hint_' + searchagent_id + '_';
		var radio_value = jQuery('input:radio[id=__freetext_modifier_'+searchagent_id+']:checked').val();
	}

	if(radio_value != 'profi' && check_profi_search_words(search_text))
	{
		// Disable hint as we use PROFI as default
		//jQuery('#' + div_profi_hint).show();
	}
	else
	{
		jQuery('#' + div_profi_hint).hide();
	}

	if(option)
	{
		jQuery('div[id^='+div_match_hint+']').each(function(index, value){
			jQuery('#' + value).hide();
		});

		jQuery('#' + div_match_hint + option).show();
	}
}

function show_item_options_for_project()
{
	var list_of_ids = '';
	jQuery("div[id*='action_box_']").each(function(){
		var id = this.id.substring(11);
		list_of_ids += id+',';
	});

	if (list_of_ids != '') {

		jQuery.post("/search/ajax.php",
			'action=show_item_options_for_project&list_of_ids='+list_of_ids,
			function(r) {
				var result = r;
				if(result)
				{
					for(var i = 0; i < result.length; i++)
					{
						var project_id = result[i]["project_id"];
						jQuery("div[id*='action_box_"+project_id+"']").each(function(){
							this.html(result[i]["content"]);
						});
					}
				}
				jQuery('.tooltip').tooltip('hide');
			}, 'json');

	}
}

function show_item_options_for_profile()
{
	var list_of_ids = '';
	jQuery("div[id*='action_box_']").each(function(){
		var id = this.id.substring(11);
		list_of_ids += id+',';
	});

	if (list_of_ids != '') {

		jQuery.post("/search/ajax.php",
			'action=show_item_options_for_profile&list_of_ids='+list_of_ids,
			function(r) {
				var result = r;
				if(result)
				{
					for(var i = 0; i < result.length; i++)
					{
						var profile_id = result[i]["profile_id"];
						jQuery("div[id*='action_box_"+profile_id+"']").each(function(){
							this.html(result[i]["content"]);
						});
					}

					jQuery('.ttClass').tipsy({gravity: 's', html: true, fade: false});
					jQuery('.ttClassBottom').tipsy({gravity: 'n', html: true, fade: false});
					jQuery('.ttClassLeft').tipsy({gravity: 'e', html: true, fade: false});
				}
				jQuery('.tooltip').tooltip('hide');
			}, 'json');

	}
}

function get_project_links()
{
	jQuery("a[id*='project_link_']").each(function(){
		var id = this.id.substring(13);
		this.href = jQuery('#' + 'highlighting_'+id).val();
	});
}

function get_profile_links()
{
	jQuery("a[id*='profile_link_']").each(function(){
		var id = this.id.substring(13);
		this.href = jQuery('#' + 'highlighting_'+id).val();
		jQuery('#' + 'profile_photo_thumbnail_'+id).href = jQuery('#' + 'highlighting_'+id).val();
	});
}

function set_location_from_extended_location_search(city_field, country_field)
{
	var extended_value = "";
	var country_id = jQuery('#' + '__search_country_id').val();

	if(typeof city_field == 'undefined')
	{
		var city_field = '__search_city';
	}

	if(typeof country_field == 'undefined')
	{
		var country_field = '__search_country';
	}

	jQuery("input[id*='county_check_']").each(function(){
		if(jQuery(this).prop("checked"))
		{
			extended_value += ' "' + jQuery(this).val() + '"';
		}
	});

	jQuery("input[id*='zip_check_']").each(function(){
		if(jQuery(this).prop("checked"))
		{
			extended_value += ' ' + jQuery(this).val() + '*';
		}
	});

	jQuery('#' + city_field).val(extended_value);
	jQuery('#' + country_field).val(country_id);

	// Show the extended box and set flag to 1
	jQuery("#search_extended").val("1");
	jQuery("#extended_search_box").show();

	// Hide search top button
	jQuery("#search_simple").hide();

	// Hide top link
	jQuery("#link-extended-search").hide();

	modal.hide();
}

function update_extended_county_and_zipcode_area(country_id, div_id)
{
	jQuery.post("/search/ajax.php",
		'action=update_extended_county_and_zipcode_area&country_id='+country_id,
		function(r) {
			var result = r;
			if(result)
			{
				jQuery('#' + div_id).html(result["content"]);
			}
		}, 'json');
}

function toggle_search_project_marked(item_class_id, user_id) {
	var e = jQuery('#project_marked-' + item_class_id + ' span');

	if (e.hasClass('grey')) {
		watchlist_add_project(item_class_id, user_id, e);
	} else if (e.hasClass('bookmarked')) {
		watchlist_delete_project(item_class_id, user_id, e);
	}
}

function toggle_search_profile_marked(item_class_id, user_id) {
	var e = jQuery('#profile_marked-' + item_class_id + ' span');

	if (e.hasClass('grey')) {
		watchlist_add_profile(item_class_id, user_id, e);
	} else if (e.hasClass('bookmarked')) {
		watchlist_remove_profile(item_class_id, user_id, e);
	}
}

function save_search_as_searchagent() {
	jQuery.post('/mail/create.php',
		'call=ajax&' + jQuery('#data_form').serialize(),

		function (result) {
			var j = result;
			if (j['error'] == 0) {
				modal.show_message(j["result_message"], null, "success");
			} else {
				modal.html(j["content"]);
			}

		}, 'json');
}

function set_extended_search() {
	jQuery('#__search_city_perimeter').prop('disabled', false);
	if(jQuery('#project-search-extended').is(':visible') == true) {
		jQuery('#search_extended').val('1');
		jQuery('#__seo_search_extended').val('1');
	}
}

/**
 * Highlight words in Freelancer profile/Project detail, that were searched for using search engine.
 */
function detail_search_highlight(jquery_selector) {
	// Get params
	var results = new RegExp('[\\?/&]highlight=([^&#]*)').exec(window.location.href);
	var word_string = decodeURIComponent(results[1]).replace("*", "");
	var words = word_string.split(',');
	jQuery(jquery_selector).highlight(words);
}

function enable_disable_marked_company_action() {
    var checkboxes = [];
    jQuery('.blacklist-checkbox_company:checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });
    if (checkboxes.length > 0) {
        jQuery('#blacklist_remove_all-company').removeClass('disabled');
    } else {
        jQuery('#blacklist_remove_all-company').addClass('disabled');
    }
}

function show_blocking_popup_for_guest_user() {
	//modal_show('zum login', "", "das wäre der titel", "", true);
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=popup_block_guest_user',
		"",
		"",
		true
	);
}

function show_popup_partner_shopify() {
	modal.setShowingTimeout(10000);
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=popup_partner_shopify',
		"",
		"",
		true
	);
}



window.addEventListener("load", function() {
    jQuery('#company_blacklist').on("click", ".blacklist-checkbox_all", function () {
        if (jQuery(this).prop('checked') == true) {
            jQuery('.blacklist-checkbox_company').prop('checked', true);
        } else {
            jQuery('.blacklist-checkbox_company').prop('checked', false);
        }
        enable_disable_marked_company_action();
    });
    jQuery('#company_blacklist').on("click", ".blacklist-checkbox_company", function (event) {
        enable_disable_marked_company_action();
    });
});

function enable_disable_marked_fl_action() {
    var checkboxes = [];
    jQuery('.blacklist-checkbox_fl:checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });
    if (checkboxes.length > 0) {
        jQuery('#blacklist_remove_all-fl').removeClass('disabled');
    } else {
        jQuery('#blacklist_remove_all-fl').addClass('disabled');
    }
}


window.addEventListener("load", function() {
    jQuery('#profiles_blacklist').on("click", ".blacklist-checkbox_all", function () {
        if (jQuery(this).prop('checked') == true) {
            jQuery('.blacklist-checkbox_fl').prop('checked', true);
        } else {
            jQuery('.blacklist-checkbox_fl').prop('checked', false);
        }
        enable_disable_marked_fl_action();
    });
    jQuery('#profiles_blacklist').on("click", ".blacklist-checkbox_fl", function (event) {
        enable_disable_marked_fl_action();
    });
});

window.addEventListener("load", function() {
    var timeout;
    jQuery('.js-thumbnail-image').on('mouseenter', function () {
        var thumbnail = jQuery(this);
        timeout = setTimeout(function () {
            thumbnail.parent().append('<img class="thumbnail-image-zoomed" src="' + thumbnail.data('imageBig') + '"/>');
        }, 500);
    });
    jQuery('.js-thumbnail-image').on('mouseout', function () {
        clearTimeout(timeout);
        jQuery(this).parent().children('.thumbnail-image-zoomed').remove();
    });
});
