/**
 * Loads an application draft in the application text and subject fields
 */
function application_load_draft(project_id) {
    var draft_id = jQuery('#draft').val();
    if (draft_id === "") {
        draft_id = 0;
    }

    jQuery.ajax({
        type: 'POST',
        url: '/application/create.php',
        data: {
            action: 'get_draft_text',
            project_id: project_id,
            draft_id: draft_id
        },
        dataType: 'json',
        success: function (json) {
            if (json["error"] === 1) {
                jQuery('#create_application').html(json["html"]);
            }

            jQuery('#application_text').val(json["draft_text"]);
        }
    });
}


/**
 * saves the settings for application
 */
 function application_save_settings() {
	jQuery('#marker_settings').load('/application/settings.php','action=save&' + jQuery('#settings_form').serialize());
}


/**
 * Writes a new application history element to the database and updates the application element
 */
function application_write_new_message(app_id, text, subject, div_id, type, category, open_box_id) {

	var application_text = jQuery('textarea[name=application_text]').val();

	if (application_text != "") {

		jQuery.post('/application/ajax.php',
			'action=write_new_history_entry&' + jQuery('#' + 'dialog_' + app_id).serialize(),
			function (transport) {
				var r = transport;
				if (r["error"] == 0) {
					jQuery('.conversation-scroll').html(r['html_response']);
					jQuery('.conversation-reply textarea[name="application_text"]').val('');
					jQuery('.conversation-scroll').animate({scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")}, 700);
					modal.show_message('Ihre Nachricht wurde gesendet', null, 'success');
				}
			}, 'json');
	} else {
		modal.show_message("Bitte geben Sie eine Nachricht ein.", '10000', 'error');
	}

}


/**
 * deletes an application off the database and drops the div
 */
function application_delete_application(app_id) {

	var checkboxes = [app_id];

	jQuery.post('/application/ajax.php',
		{
			action: 'delete_application',
			list_of_applications: JSON.stringify({paramIds: checkboxes})
		},
		function (transport) {

			modal.show_message('Die Bewerbung wurde gelöscht.', null, 'success');

			var project_id = jQuery('input[name=checkbox_' + app_id + ']').attr('data-projectid');
			jQuery('#container_single_' + app_id).parent().parent().remove();

			if (jQuery('#project-application-list-' + project_id + ' .app-converstation').children().length == 0) {
				jQuery('#project_header_project_id_' + project_id).parent().hide();
			}

		}, 'json');
}



/**
 * makrs an application as withdrawn and drops the div
 */
function application_withdraw_application(app_id) {

	var checkboxes = [app_id];

	jQuery.post('/application/ajax.php',
		{
			action: 'withdraw_application',
			list_of_applications: JSON.stringify({paramIds: checkboxes}),
			withdraw_reason_id: 6,
		},
		function (transport) {

			modal.show_message('Die Bewerbung wurde archiviert und ist dort weiter abrufbar.', null, 'success');

			var project_id = jQuery('input[name=checkbox_' + app_id + ']').attr('data-projectid');
			jQuery('#container_single_' + app_id).parent().parent().remove();

			if (jQuery('#project-application-list-' + project_id + ' .app-converstation').children().length == 0) {
				jQuery('#project_header_project_id_' + project_id).parent().hide();
			}

		}, 'json');
}

function show_send_application_confirm(project_id) {
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=send_application_confirmation&project_id=' + project_id,
		'Bewerbung senden'
	);
}

function show_info_dynamic_matching() {
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=info_dynamic_matching',
		'Unser Matching'
	);
}

function show_info_mass_rejection_step1() {
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=info_mass_rejection_step1',
		'Sammelabsage'
	);
}

function show_info_mass_rejection_step2() {
	modal.showFLPopup(
		'request',
		'/ajax/popup_template.php',
		'action=info_mass_rejection_step2',
		'Sammelabsage'
	);
}

function dataLayerSendApplication(project_id) {
	var formId = "#application_form-project_" + project_id;
	var checkboxArray = jQuery(formId + ' .checkboxelement');
	var checkboxData = {};
	checkboxArray.each(function(index) {
		checkboxData[this.id] = this.checked;
	});
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ event_data: null });
	dataLayer.push({
		'event': "freelance.send_application_for_project",
		'event_type': "custom",
		'event_category': "freelancer",
		'event_data': {
			'checkbox': checkboxData
		}
	});
}

function send_application(project_id) {

    jQuery.post('/application/create.php',
		jQuery('#application_form-project_' + project_id).serialize(),
        function (json) {

            jQuery('#create_application').html(json['html']);

            if (json['error'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
				showDataLayerApplyToProject();
                jQuery('#menu_application').html(json['quick_action']);
                jQuery('#marker_application_sent').html(json['marker_application_sent']);
                jQuery('#project_marked-' + project_id).remove();
				// application on offered project page - hide action buttons
                if (jQuery("#offered-project-actions").length) {
					jQuery("#offered-project-actions").hide();
				}

            } else if (json['error'] == 1) {
				modal.hide();
            	//just re-render form with errors

			} else if (json['error'] == 2){

            	var errorMessage = 'Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren Support.';
				if (typeof json['message'] !== 'undefined') {
					errorMessage = json['message'];
				}
				modal.show_message(errorMessage, 0, 'danger');
            }
        },'json');
}

function application_check_filesize() {
    var filesize = 100000; // Take in account the freelance profile by default (100kB).
    jQuery('input:checkbox[name^=doc_]:checked').each(function (key, element) {
        filesize += parseInt(jQuery(element).attr('fl-data'));
    });

    if (filesize > 25600000) {
        jQuery('#complete_attachment_size_alert').show();
    } else {
        jQuery('#complete_attachment_size_alert').hide();
    }
}


function application_note(application_id, template)
{
	template = typeof template !== 'undefined' ? template : 'parent';
	
	jQuery.post('/application/ajax.php',
		 'action=update_withdraw_note&template=' + template + '&' + jQuery('#' + 'note_form_' + application_id).serialize(),
		function (transport) {
			var r = transport;
			
			if(r["error"] == 0)
			{
				modal.show_message('Notiz wurde gespeichert', null, 'success');
				jQuery('#container_single_' + application_id).html(r['html_response']);
			}
		}, 'json');
}


function application_favourite(application_id, template)
{
	template = typeof template !== 'undefined' ? template : 'parent';
	
	jQuery.post('/application/ajax.php',
		 'action=application_favourite&application_id=' + application_id + '&template=' + template,
		function (transport) {
			var r = transport;

			if(r["error"] == 0)
			{
				jQuery('#container_single_' + application_id).html(r['html_response']);
			}
		}, 'json');
}

/**
 * performs an action on selected mail items
 */
function application_bulk_action(name, proj_id = 0) {

	var checkboxes   = [];
	var i;

	if (proj_id > 0) {
		var select_id = '#select_' + proj_id;
	} else {
		var select_id = '#' + name;
	}

	var action_value = jQuery(select_id).val();

	//Get all checkboxes
	jQuery('.ch_' + proj_id + ':checked').each(function (index) {
		checkboxes.push(jQuery(this).val());
	});

	if (action_value === "mark_as_unread" && checkboxes.length > 0) {

		jQuery.post('/application/ajax.php',
			{
				action: 'application_mark_as_unread',
				list_of_applications: JSON.stringify({paramIds: checkboxes})
			},
			function (transport) {
				var json = transport;
				jQuery('.application-counter').text(json['number_of_unread_applications']);

				for (i = 0; i < checkboxes.length; i++) {
					jQuery('#application_title_' + checkboxes[i]).attr('class', 'unread');
				}
			}, 'json');
	}

    if (action_value === "mark_as_read" && checkboxes.length > 0) {

		jQuery.post('/application/ajax.php',
			{
				action: 'application_mark_as_read',
				list_of_applications: JSON.stringify({paramIds: checkboxes})
			},
			function (transport) {
				var json = transport;
				jQuery('.application-counter').text(json['number_of_unread_applications']);

				for (i = 0; i < checkboxes.length; i++) {
					jQuery('#application_title_' + checkboxes[i]).attr('class', '');

				}
			}, 'json');
    }

    
	if (action_value === "mark_as_archived") {

		let button = {label: "Archivieren", onclick: "mark_as_archived_and_perform_archive('checkbox-application'); modal.hide();"};
		modal.show_message('Möchten Sie die Auswahl dauerhaft archivieren?', 1000000, 'info', 'Abbrechen', button);
		
	}
	
	
	if (action_value === "mark_as_deleted") {

		let button = {label: "Löschen", onclick: "mark_as_deleted_and_perform_delete('checkbox-application'); modal.hide();"};
		modal.show_message('Möchten Sie die Auswahl dauerhaft löschen?', 1000000, 'info', 'Abbrechen', button);
		
	}

	if (action_value === "mass_rejection") {
		if (checkboxes.length > 0) {
			modal.showFLPopup(
				'request',
				'/ajax/popup_template.php',
				'action=mass_rejection_form' + '&project_id=' + proj_id + '&list_of_applications=' + JSON.stringify({paramIds: checkboxes}),
				'Absage senden'
			);
			return false;
		} else {
			modal.show_message('Select something');
			return false;
		}
	}
   
    // reset the action select box
	jQuery(select_id).selectpicker('val', '');

}


function mark_as_deleted_and_perform_delete(name) {

	var checkboxes = [];
	var i;

	//Get all checkboxes
	jQuery('.' + name + ':checked').each(function (index) {
		checkboxes.push(jQuery(this).val());
	});

	if (checkboxes.length > 0) {

		jQuery.post('/application/ajax.php',
			{
				action: 'delete_application',
				list_of_applications: JSON.stringify({paramIds: checkboxes})
			},
			function (transport) {
				var json = transport;

				for (i = 0; i < checkboxes.length; i++) {

					var project_id = jQuery('input[name=checkbox_' + checkboxes[i] + ']').attr('data-projectid');
					jQuery('#container_single_' + checkboxes[i]).parent().parent().remove();

					if (jQuery('#project-application-list-' + project_id + ' .app-converstation').children().length == 0) {
						jQuery('#project_header_project_id_' + project_id).parent().hide();
					}
				}
			}, 'json');
	}
}


function mark_as_archived_and_perform_archive(name) {

	var checkboxes   = [];
	var i;

	//Get all checkboxes
	jQuery('.' + name + ':checked').each(function (index) {
		checkboxes.push(jQuery(this).val());
	});

	if (checkboxes.length > 0) {

		jQuery.post('/application/ajax.php',
			{
				action: 'withdraw_application',
				list_of_applications: JSON.stringify({paramIds: checkboxes})
			},
			function (transport) {
				var json = transport;

				for (i = 0; i < checkboxes.length; i++) {

					var project_id = jQuery('input[name=checkbox_' + checkboxes[i] + ']').attr('data-projectid');
					jQuery('#container_single_' + checkboxes[i]).parent().parent().remove();

					if (jQuery('#project-application-list-' + project_id + ' .app-converstation').children().length == 0) {
						jQuery('#project_header_project_id_' + project_id).parent().hide();
					}
				}
			}, 'json');
	}
}

function application_search_keypress(e, category) {
	var key=e.keyCode || e.which;
	if (key==13){
		application_search(category);
	}
}

function application_search(category, offset, clear_search_field, search_field) {

	category = typeof category !== 'undefined' ? category : '';
	offset = typeof offset !== 'undefined' ? offset : 0;
	clear_search_field = typeof clear_search_field !== 'undefined' ? clear_search_field : false;
	search_field = typeof search_field !== 'undefined' ? search_field : '__search_freetext';

	let text = clear_search_field ? '' : jQuery('input[name=' + search_field + ']').val();
	let project_online = jQuery('select[name=project_online]').val();
	let project_sort = jQuery('select[name=project_sort]').val();
	let projects_for_user = jQuery('select[name=projects_for_user]').val();
	let data_policy_status = jQuery('select[name=data_policy_status]').val();
	var project_id = null;
	let one_project = jQuery('input[name=one_project]').val();
	if (one_project !== 'undefined') {
		project_id = jQuery('input[name=project_id]').val();
	}

	if (category == 'only_one_project') {
		project_id = jQuery('input[name=project_id]').val();
		jQuery('#archive').removeClass('active');
		jQuery('#data_policy').removeClass('active');
		jQuery('#project_to_freelancer').removeClass('active');
		jQuery('#current').removeClass('active');
	} else if (category == 'current') {
		jQuery('#archive').removeClass('active');
		jQuery('#data_policy').removeClass('active');
		jQuery('#project_to_freelancer').removeClass('active');
		jQuery('#current').addClass('active');
	} else if (category == 'archive') {
		jQuery('#current').removeClass('active');
		jQuery('#data_policy').removeClass('active');
		jQuery('#project_to_freelancer').removeClass('active');
		jQuery('#archive').addClass('active');
	} else {
		jQuery('#current').removeClass('active');
		jQuery('#archive').removeClass('active');
		jQuery('#project_to_freelancer').removeClass('active');
		jQuery('#data_policy').addClass('active');
	}

	jQuery.post('/application/ajax.php',
		{
			action: 'application_search',
			__search_text: text,
			category: category,
			offset: offset,
			project_online: project_online,
			project_sort: project_sort,
			projects_for_user: projects_for_user,
			data_policy_status: data_policy_status,
			project_id: project_id,
			one_project: one_project
		},
		function (transport) {
			var r = transport;

			if (r["error"] == 0) {
				// modal.show_message('Here goes your search result', null, 'success');

				if (category == 'current' || category == 'archive' || category == 'only_one_project') {
					jQuery('#form_and_results').html(r['html_response']);
					jQuery('#container_search_data_policy').hide();
					jQuery('#container_bulk').show();
					jQuery('#container_bulk_action').show();
				} else {

					jQuery('#container_all').html(r['html_response']);
					jQuery('#pagination').html(r['html_pagination']);
					jQuery('#container_search').hide();
					jQuery('#container_bulk').hide();
					jQuery('#container_bulk_action').hide();
				}

				// jQuery('#category').selectpicker('val', '');
				jQuery('#toggle_bulk_actions #checkbox-application').selectpicker('refresh');
				jQuery('#container_bulk_action').get(0).scrollIntoView();
				jQuery('input[name=__search_freetext]').val(text);
			}

		}, 'json');
}

function enable_disable_bulk_actions(proj_id = 0) {

	var checkboxes = [];
	if (proj_id > 0) {
		jQuery('.checkbox-application:checked').each(function (index) {
			var name = jQuery(this).prop('name');
			var name_id = name.match(/\d+/)[0];
			if (name_id === proj_id) {
				checkboxes.push(jQuery(this).val());
			}
		});
	} else {
		jQuery('.checkbox-application:checked').each(function (index) {
			checkboxes.push(jQuery(this).val());
		});
	}

	var s_hint = "";
	if (proj_id > 0) {
		el_name = "#select_" + proj_id;
		s_hint = "_" + proj_id;
	} else {
		el_name = "#checkbox-application";
	}

	if (checkboxes.length > 0 ){
		jQuery('#toggle_bulk_actions ' + el_name).prop('disabled', false);
		jQuery('#toggle_bulk_actions ' + el_name).selectpicker('refresh');
		jQuery('#toggle_bulk_actions #button' + s_hint).prop('disabled', false);
		if (jQuery('#toggle_bulk_actions ' + el_name).val() === "mass_rejection") {
			jQuery('#absagen-hint' + s_hint).removeClass("hidden");
		}
	} else {
		jQuery('#toggle_bulk_actions ' + el_name).prop('disabled', true);
		jQuery('#toggle_bulk_actions ' + el_name).selectpicker('refresh');
		jQuery('#toggle_bulk_actions #button' + s_hint).prop('disabled', true);
		if (!jQuery('#absagen-hint' + s_hint).hasClass("hidden")) {
			jQuery('#absagen-hint' + s_hint).addClass("hidden");
		}
	}

}

window.addEventListener("load", function() {

	jQuery('#form_and_results').on("click", ".checkbox-application", function (event) {

		enable_disable_bulk_actions();

		var projectid = jQuery(this).attr('data-projectid');

		var checkboxes_current_project_checked = [];
		jQuery('#project-application-list-'+projectid+' .checkbox-application:checked').each(function (index) {
			checkboxes_current_project_checked.push('checked');
		});

		var checkboxes_current_project = [];
		jQuery('#project-application-list-'+projectid+' .checkbox-application').each(function (index) {
			checkboxes_current_project.push('default');
		});

		if (checkboxes_current_project.length == checkboxes_current_project_checked.length) {
			jQuery('input[name=app_project_'+projectid+']').prop('checked', true);
		} else {
			jQuery('input[name=app_project_'+projectid+']').prop('checked', false);
		}
		
	});


	jQuery('#form_and_results').on("click", "#applications_bulk", function () {

		if (jQuery(this).prop('checked') == true) {
			jQuery('.checkbox-application').prop('checked', true);
			jQuery('.applications_project_bulk').prop('checked', true);
		} else {
			jQuery('.checkbox-application').prop('checked', false);
			jQuery('.applications_project_bulk').prop('checked', false);
		}
		enable_disable_bulk_actions();
	});


	jQuery('#form_and_results').on("click", ".applications_project_bulk", function () {

		var name = jQuery(this).prop('name');
		var proj_id = name.match(/\d+/)[0]; // extract proj_id from name

		if (jQuery(this).prop('checked') == true) {
			jQuery('.' + name).prop('checked', true);
		} else {
			jQuery('.' + name).prop('checked', false);
		}
		enable_disable_bulk_actions(proj_id);
	});

	//arrow up/down
	jQuery(".collapse").on("hide.bs.collapse", function () {
		jQuery("a[data-target='#" + jQuery(this).attr('id') + "']").children().replaceWith('<i class="far fa-angle-down"></i>');
	});
	jQuery(".collapse").on("show.bs.collapse", function () {
		jQuery("a[data-target='#" + jQuery(this).attr('id') + "']").children().replaceWith('<i class="far fa-angle-up"></i>');
	});
});

function confirm_accept_data_policy(id, text)
{
	let button = {label: 'Ja, einwilligen', onclick: 'accept_data_policy('+ id +'); modal.hide();'};
	modal.show_message(text, '1000000', 'info', 'Nein', button);
}

function accept_data_policy(id)
{
	jQuery.post('/application/ajax.php',
		 {
			action: 'accept_data_policy',
			id: id
		},
		function (transport) {
			var r = transport;

			if (r["error"] == 0) {
				jQuery('#data_policy_checkbox_' + id).hide();
				jQuery('#data_policy_info_' + id).show();
				jQuery('#data_policy_button_' + id).hide();
				jQuery('#data_policy_hint_' + id).hide();
			} else {
				modal.show_message(r["error_message"], '1000000', 'error');
			}
		}, 'json');
}

function confirm_revocation_application_data_policy(id)
{
	var button = {label: 'Ja', onclick: 'revoke_application_data_policy('+ id +'); modal.hide();'};
	modal.show_message($T('application:confirm_revocation_data_policy'), '1000000', 'info', 'Nein', button);
}

function revoke_application_data_policy(id)
{
	jQuery.post('/application/ajax.php',
		{
			action: 'revoke_application_data_policy',
			id: id
		},
		function (transport) {
			var r = transport;

			if (r["error"] == 0) {
				jQuery('#application_data_policy_' + id).html(r["content"]);
			}

		}, 'json');
}

function add_trash_button_to_freelancer_draft (el, el_id, modal_header) {
	let main_element = el.parentNode;
    let parent_button = false;
    jQuery(main_element)
		.parents()
		.map(function() {
            if (this.tagName.toLowerCase() === "button") { parent_button = true; }
        });
    if(!parent_button) {
        let content = jQuery(main_element).text();
        jQuery(main_element).html("<span style='float: right;'><i class='far fa-trash-alt' style='float:right;' onclick='confirm_freelancer_draft_deletion(" + el_id + ", \"" + modal_header + "\"); return false;'></i></span><span>" + content + "</span>");
    }
    return null;
}

function confirm_freelancer_draft_deletion (id, modal_header) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=confirm_freelancer_draft_delete&vorlage_id=' + id,
        modal_header,
        '');
}

function delete_freelancer_draft(id, message_template_deleted, please_select) {
	jQuery.post('/application/ajax.php',
		{
			action: 'delete_freelancer_draft',
			id: id
		},
		function (transport) {
			modal.show_message(message_template_deleted, null, 'success');
			jQuery('#draft').val(please_select);
			jQuery('.selectpicker').selectpicker();
			jQuery('#draft option[value="' + id + '"]').remove();
			jQuery('.selectpicker').selectpicker('refresh');
		}, 'json');
}

/**
 * click on send simple application on project
 *
 * @param project_id
 * @param confirmation_message
 * @param confirm_button_label
 * @param cancel_button_label
 */
function simple_application_click(project_id, confirmation_message, confirm_button_label, cancel_button_label) {
	var onclick = "send_simple_application('" + project_id + "'); modal.hide();";

	modal.show_message(confirmation_message, '1000000', 'info', cancel_button_label,
		{'label': confirm_button_label, 'onclick': onclick});
}

/**
 * send simple application submit
 *
 * @param project_id
 */
function send_simple_application(project_id) {

	jQuery.post("/application/create.php", {
		'action': 'send_simple_application',
		'project_id': project_id
	}, function (res) {
		if (res.error === 1) {
			modal.show_message(res.message, 5000, 'danger');
			return;
		} else if (res.error === 0) {
			modal.show_message(res.message, 5000, 'success');
			// replace the content of the box
			jQuery("#simple_application").replaceWith(res.html);
		}
	}, "json");
}
