
function PdfViewer(settings) {
    // set this object to container if it is defined
    if (jQuery.type(settings.container) !== 'undefined') {
        jQuery(settings.container).data('pdf-viewer', this);
    }

    var self = this;
    new Promise(function (resolve, reject) {
        if (typeof PDFJS === "undefined" && PdfViewer.prototype.pdfjsState === PdfViewer.prototype.PDFJS_NOT_LOADED) {
            // load PDFJS if it not loaded yet
            PdfViewer.prototype.pdfjsState = PdfViewer.prototype.PDFJS_LOADING;
            // save this instance for use out of this method context
            jQuery.getScript(PdfViewer.prototype.PDFJS_SRC, function () {
                PDFJS.workerSrc = PdfViewer.prototype.PDFJS_WORKER_SRC;
                PdfViewer.prototype.pdfjsState = PdfViewer.prototype.PDFJS_LOADED;
                resolve(settings);
            }).fail(function () {
                PdfViewer.prototype.pdfjsState = PdfViewer.prototype.PDFJS_LOADING_FAILED;
                reject("PdfViewer: Cannot load pdf.min.js");
            });
        } else if (typeof PDFJS !== "undefined" && PdfViewer.prototype.pdfjsState !== PdfViewer.prototype.PDFJS_LOADED) {
            // if PDFJS is defined but state is set to anything else then loaded, it is invalid state, set to loaded
            PdfViewer.prototype.pdfjsState = PdfViewer.prototype.PDFJS_LOADED;
            resolve(settings);
        } else {
            // some other instance is loading PDFJS, wait
            var interval = setInterval(function () {
                if (PdfViewer.prototype.pdfjsState === PdfViewer.prototype.PDFJS_LOADED) {
                    clearInterval(interval);
                    resolve(settings);
                } else if (PdfViewer.prototype.pdfjsState === PdfViewer.prototype.PDFJS_LOADING_FAILED) {
                    clearInterval(interval);
                    reject("PdfViewer: Cannot load pdf.min.js");
                }
            }, PdfViewer.prototype.TIMER);
        }
    }).then(function (settings) {
        self.init(settings);
    }).catch(function (err) {
        self.showError(err);
    });
}

// sources of PDFJS
PdfViewer.prototype.PDFJS_SRC = "/js/lib/pdfjs/pdf.min.js";
PdfViewer.prototype.PDFJS_WORKER_SRC = "/js/lib/pdfjs/pdf.worker.min.js";

// time in micro seconds for waiting to asynchronous actions
PdfViewer.prototype.TIMER = 200;

// default width of viewer
PdfViewer.prototype.DEFAULT_WIDTH = 300;

// possible states of PDFJS library
PdfViewer.prototype.PDFJS_NOT_LOADED = 0;
PdfViewer.prototype.PDFJS_LOADING = 1;
PdfViewer.prototype.PDFJS_LOADED = 2;
PdfViewer.prototype.PDFJS_LOADING_FAILED = 3;

// error flag
PdfViewer.prototype.error = false;
// error text, this text is shown to user if error occurred
PdfViewer.prototype.ERROR_TEXT = "Ein Fehler ist aufgetreten!";

// initial state of PDFJS library
PdfViewer.prototype.pdfjsState = PdfViewer.prototype.PDFJS_NOT_LOADED;

// this flag controls document downloading
PdfViewer.prototype.documentLoading = false;

PdfViewer.prototype.showError = function(e) {
    // set error flag, this viewer cannot do anything now
    this.error = true;
    // show error in console
    console.log(e);
    // show error to user if dom elements are already defined
    if (jQuery.type(this.dom) !== 'undefined') {
        if (jQuery.type(this.dom.loadingDiv) !== 'undefined') {
            this.dom.loadingDiv.hide();
        }
        if (jQuery.type(this.dom.controlDiv) !== 'undefined') {
            this.dom.controlDiv.hide();
        }
        if (jQuery.type(this.dom.viewDiv) !== 'undefined') {
            this.dom.viewDiv.hide();
        }
        if (jQuery.type(this.dom.errorDiv) !== 'undefined') {
            this.dom.errorDiv.show();
        }
    }
};

PdfViewer.prototype.init = function(settings) {
    try {
        // if there was error before, viewer is not able to work properly
        if (this.error) { throw "PdfViewer: Invalid state"; }

        this.parseSettings(settings);
        this.pdf = null;
        // draw viewer only if it is requested
        if (this.settings.showOnInit) {
            this.draw();
        }
    } catch (e) { this.showError(e); }
};

PdfViewer.prototype.parseSettings = function(settings) {
    // initialize dom objects of viewer
	this.dom = {
		canvas: jQuery('<canvas>'),
        loadingDiv: jQuery('<img>'),
        errorDiv: jQuery('<div>'),
        nextButton: jQuery('<i>'),
        prevButton: jQuery('<i>'),
        pageCurrentSpan: jQuery('<span>'),
        pageTotalSpan: jQuery('<span>'),
        pageCounterSpan: jQuery('<span>'),
        controlDiv: jQuery('<div>'),
        viewDiv: jQuery('<div>')
	};

	// initialize control flags
	this.controls = {
        pageCurrent: 1,
		pageTotal: 0,
        pageRenderingInProgres: false,
        documentLoadingInProgres: false,
        canvasCtx: null
    };

	// initialize of this viewer settings
	this.settings = {
		controlsHeight: 22,
		url: "",
        width: NaN,
        height: NaN,
        showPageCounter: true,
        showPageChanger: true,
        showOnInit: true
	};

    if (jQuery.type(settings.showControls) !== 'undefined') {
        // if showControls is defined, set visibility to both, page counter and changer
        this.settings.showPageCounter = (settings.showControls ? true : false);
        this.settings.showPageChanger = (settings.showControls ? true : false);
    } else {
        // if showControls is not defined, then visibility of page counter and changer can be set separately
        if (jQuery.type(settings.showPageCounter) !== 'undefined') {
            this.settings.showPageCounter = (settings.showPageCounter ? true : false);
        }
        if (jQuery.type(settings.showPageChanger) !== 'undefined') {
            this.settings.showPageChanger = (settings.showPageChanger ? true : false);
        }
	}

	// save container to object, it must be defined
    if (jQuery.type(settings.container) !== 'undefined') {
        this.dom.container = jQuery(settings.container);
    } else {
        throw "PdfViewer: No container defined";
    }

    // save url to pdf
    if (jQuery.type(settings.url) !== 'undefined') {
		this.settings.url = settings.url;
    }

    // save viewer width and height, if width and height parameters aren't defined, then set default width
    if (jQuery.type(settings.width) !== 'undefined') {
        this.settings.width = settings.width;
    } else if (jQuery.type(settings.height) === 'undefined') {
        this.settings.width = this.DEFAULT_WIDTH;
    }

    // save viewer height
    if (jQuery.type(settings.height) !== 'undefined') {
        this.settings.height = settings.height;
    }

    // save if viewer should be rendered on initialization
    if (jQuery.type(settings.showOnInit) !== 'undefined') {
        this.settings.showOnInit = settings.showOnInit;
    }
};

PdfViewer.prototype.isUrlSet = function() {
    if (typeof this.settings !== 'undefined') {
        return this.settings.url != "";
    }
    return false;
};

PdfViewer.prototype.setUrl = function(url) {
    // this method can solve error
    this.error = false;

    // reset pdf and url
    this.pdf = null;
    this.settings.url = url;
};

PdfViewer.prototype.loadPdf = function() {
    var self = this;
    return new Promise(function (resolve, reject) {
        // if there was error before, viewer is not able to work properly
        if (self.error) { reject("PdfViewer: Invalid state"); }
        // if there is no url to pdf, then pdf cannot be loaded
        if (!self.isUrlSet()) { reject("PdfViewer: No URL to pdf"); }

        self.controls.documentLoadingInProgres = true;

        var interval = setInterval(function () {
            if (PdfViewer.prototype.documentLoading === false && self.pdf === null) {
                PdfViewer.prototype.documentLoading = true;
                clearInterval(interval);
                resolve(true);
            } else if (self.pdf !== null) {
                resolve(false);
            }
        }, PdfViewer.prototype.TIMER);
    }).then(function (load) {
        if (load) {
            return PDFJS.getDocument(self.settings.url).then(function (pdf_doc) {
                PdfViewer.prototype.documentLoading = false;
                // save pdf
                self.pdf = pdf_doc;
                // reset document loading flag
                self.controls.documentLoadingInProgres = false;
                // save page count
                self.controls.pageTotal = self.pdf.numPages;
            }).catch(function (error) {
                PdfViewer.prototype.documentLoading = false;
                self.controls.documentLoadingInProgres = false;
                self.showError(error);
            });
        }
    }).catch(function (error) {
        self.showError(error);
    });
};

PdfViewer.prototype.draw = function() {
    // save this instance for use out of this method context
    var self = this;
    return new Promise(function () {
        // set rendering flag
        self.controls.pageRenderingInProgres = true;

        // create viewer on page
        self.dom.container.css({display: 'flex',width: self.settings.width,'text-align': 'center','justify-content': 'center','align-items': 'center'});
        self.dom.loadingDiv.attr("src", "/images/spinner.gif");
        self.dom.errorDiv.text(self.ERROR_TEXT);
        var iconCss = {cursor: 'pointer', color: '#808080', 'font-size': '21px', 'line-height': self.settings.controlsHeight + 'px', 'vertical-align': 'middle'};
        self.dom.nextButton.addClass('far fa-arrow-circle-right').css(iconCss);
        self.dom.prevButton.addClass('far fa-arrow-circle-left').css(iconCss);
        self.dom.pageCounterSpan.append(self.dom.pageCurrentSpan, "/", self.dom.pageTotalSpan).css({'line-height': self.settings.controlsHeight + 'px','vertical-align': 'middle'});
        self.dom.controlDiv.append(self.dom.prevButton, "&nbsp;&nbsp;", self.dom.pageCounterSpan, "&nbsp;&nbsp;", self.dom.nextButton).css({'height': self.settings.controlsHeight + 'px'});
        self.dom.viewDiv.append(self.dom.canvas, self.dom.controlDiv);
        self.dom.container.empty();
        self.dom.container.append(self.dom.errorDiv, self.dom.loadingDiv, self.dom.viewDiv);

        // if it is defined, set width of container
        if (!isNaN(self.settings.width)) {
            self.dom.container.width(self.settings.width);
            self.dom.loadingDiv.css('max-width', self.settings.width);
        }
        // if it is defined, set height of container
        if (!isNaN(self.settings.height)) {
            self.dom.container.height(self.settings.height);
            self.dom.loadingDiv.css('max-height', self.settings.height);
        }

        // show proper elements to user, according to viewer state
        self.showHideLoading();

        // set events on page changer
        self.dom.nextButton.on('click', function (event) {
            event.stopPropagation();
            self.showPage(self.controls.pageCurrent + 1);
        });
        self.dom.prevButton.on('click', function (event) {
            event.stopPropagation();
            self.showPage(self.controls.pageCurrent - 1);
        });

        // save canvas context
        self.controls.canvasCtx = self.dom.canvas.get(0).getContext('2d');

        return self.showPDF();
    }).catch(function (error) {
        self.showError(error);
    });
};

PdfViewer.prototype.showPDF = function() {
    var self = this;
    return new Promise(function (resolve, reject) {
        // if there was error before, viewer is not able to work properly
        if (self.error) { reject("PdfViewer: Invalid state"); }
        return self.loadPdf().then(function () {
            // set rendering flag
            self.controls.pageRenderingInProgres = true;
            // show proper elements to user, according to viewer state
            self.showHideLoading();
            // show first page of document
            return self.showPage(1);
        });
    }).catch(function (error) {
        self.showError(error);
    });
};

PdfViewer.prototype.showPage = function(page_number) {
    // check if requested page is in document, if not, do nothing
    if ((page_number > (this.controls.pageTotal) || page_number < 1) && this.controls.pageRenderingInProgres !== true) {
        return;
    }

    var self = this;
    new Promise(function (resolve, reject) {
        // if there was error before, viewer is not able to work properly
        if (self.error) { reject ("PdfViewer: Invalid state"); }

        // set rendering flag
        self.controls.pageRenderingInProgres = true;

        // show proper elements to user, according to viewer state
        self.showHideLoading();

        // save this instance for use out of this method context
        // Try to show requested page
        return self.pdf.getPage(page_number).then(function (page) {
            // requested page were successfully loaded, save current page number
            self.controls.pageCurrent = page_number;

            // count height of control buttons
            var controlHeight = (self.settings.showPageChanger || self.settings.showPageCounter ? self.settings.controlsHeight : 0);

            // get viewport of the page at required scale
            var viewport;
            if (isNaN(self.settings.height)) {
                // if height is not set, count by width
                viewport = page.getViewport(self.settings.width / page.getViewport(1).width);
            } else {
                // if height is set, count by height (it really doesn't matter by which dimension is scale counted but one dimension can be undefined in settings)
                viewport = page.getViewport((self.settings.height - controlHeight) / page.getViewport(1).height);
            }

            // resize container, if dimension is undefined or bigger in loaded document
            if (isNaN(self.settings.height) || viewport.height > (self.settings.height - controlHeight)) {
                self.dom.container.height(viewport.height + controlHeight);
            }
            if (isNaN(self.settings.width) || viewport.width > self.settings.width) {
                self.dom.container.width(viewport.width);
            }

            // set canvas size
            self.dom.canvas.get(0).width  = viewport.width;
            self.dom.canvas.get(0).height = viewport.height;

            // Render the page contents in the canvas
            return page.render({canvasContext: self.controls.canvasCtx, viewport: viewport});
        }).then(function () {
            // reset rendering flag
            self.controls.pageRenderingInProgres = false;
            // update page counter
            self.dom.pageCurrentSpan.text(self.controls.pageCurrent);
            self.dom.pageTotalSpan.text(self.controls.pageTotal);
            // show proper elements to user, according to viewer state
            self.showHideLoading();
        });
    }).catch(function (error) {
        self.showError(error);
    });
};

PdfViewer.prototype.showHideLoading = function() {
    // always hide error, it is shown only when error occurs
    this.dom.errorDiv.hide();

	if (this.controls.pageRenderingInProgres || this.documentLoadingInProgres) {
        // if rendering or loading is in progress, show loading and hide everything else
        this.dom.loadingDiv.show();
        this.dom.viewDiv.hide();
	} else {
	    // otherwise hide loading
        this.dom.loadingDiv.hide();

        // show page counter if it is set so
        if (this.settings.showPageCounter) {
            this.dom.pageCounterSpan.show();
        } else {
            this.dom.pageCounterSpan.hide();
        }

        // show page changer if it is set so
        if (this.settings.showPageChanger) {
            this.dom.prevButton.show();
            this.dom.nextButton.show();
        } else {
            this.dom.prevButton.hide();
            this.dom.nextButton.hide();
        }

        // show document
        this.dom.viewDiv.show();
	}
};