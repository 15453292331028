function project_to_freelancer_search(category, offset, clear_search_field, search_field) {

    jQuery('#archive').removeClass('active');
    jQuery('#data_policy').removeClass('active');
    jQuery('#project_to_freelancer').addClass('active');
    jQuery('#current').removeClass('active');

    jQuery.post('/project_to_freelancer/ajax.php',
        {
            action: 'project_to_freelancer_search',
            offset: offset
        },
        function (transport) {
            var r = transport;

            if (r.error == 0) {
                jQuery('#container_all').html(r.html_response);
                jQuery('#pagination').html(r.html_pagination);
                jQuery('#pagination').show();
                jQuery('#container_bulk_action').html(r.html_bulk_action);
                jQuery('#container_bulk_action').show();
            }

        }, 'json');

}
