class BannerClick {

    constructor() {
        this.url = '/banner/ajax.php';
        this.click = function (s_banner_id) {
            new jQuery.post("/banner_click/ajax.php",
                'action=banner_click_add&banner_id=' + s_banner_id + '&uri=' + escape(document.URL));
        };
    }
}

var bannerClick = new BannerClick();