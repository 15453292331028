var GoogleAccount = function () {
    this.url = '/google/ajax.php';

    /**
     * uploads a file to google and finally redirects the user
     */
    this.uploadFile = function (redirect_url, template_name) {
        // the default url to which the user is going to be redirected if everything went ok
        var url = redirect_url;

        jQuery.post(this.url,
            'action=upload_file&template_name=' + template_name,
            function (transport) {
                var j = transport;
                error_id = j['error_id'];

                jQuery('#loader').hide();

                if (j['error_id'] > 0) {
                    jQuery('#marker_success').hide();
                    jQuery('#marker_error').show();
                    jQuery('#marker_error_additional').html(j['error_message']);
                    jQuery('#marker_error_service_additional').html(j['error_message_service']);
                } else {
                    jQuery('#marker_error_additional').html('');
                    jQuery('#marker_error_service_additional').html('');

                    jQuery('#marker_error').hide();
                    jQuery('#marker_success').show();

                    // if we have got any url from the upload script
                    if (j['url'] != '') {
                        // then we redirect the user to it instead to the default url
                        url = j['url'];
                    }

                    window.setTimeout('googleAccount.redirect("' + url + '")', 1000);
                }
            }, 'json');
    };

    this.redirect = function (url) {
        document.location.href = url;
    };
};

var googleAccount = new GoogleAccount(); 