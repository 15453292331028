function extend_subscription_5_year_birthday()
{
	jQuery.post('/promotion/ajax.php',
		'action=extend_subscription_5_year_birthday',
		function (response) {
			var j = response;

			if (j.error == 1) {
				modal_show(j.html);
			} else {
				modal_show(j.html);
			}

		}, 'json');
}

var orderChecked = -1;

// Handles the promotion order process js sided
function order_promotion_send()
{
	jQuery.post("/promotion/ajax.php",
		'action=send_promotion&'+jQuery('#order_form').serialize(),
		function(r) {
			if(r == '' || r == '1000')
			{
				jQuery.post("/promotion/ajax.php?success=true",
					'action=send_promotion&'+jQuery('#' + 'order_form').serialize(),
					function(r) {
						if(r != '1000')
						{
							jQuery('#ajax-content').html(r);
							flScrollTo('#ajax-content');
						}
						else
						{
							jQuery('#order_error').show();
							flScrollTo('#ajax-content');
						}
					}, 'json');
			}
			else
			{
				jQuery('marker_order').html(r);
			}
		});
}


// Handles the checking/unchecking of the choosen promotion package
function order_check(id, length)
{
	jQuery('#order_choice').val(id);
	orderChecked = id;
}


function user_want_to_become_premium_from_postlogin(url)
{
	jQuery.post("/promotion/ajax.php",
		'action=send_become_premium_email&' + jQuery('#' + 'addressdata_form').serialize(),
		function (r) {
			if (url) {
				document.location.href = url;
			}
		});
}

// Send it insurance request
function send_insurance_request(s_type)
{
	var s_url = '/promotion/haftpflicht.php';
	if(s_type == 'it')
	{
		s_url = '/promotion/it-haftpflicht.php';
	}

	jQuery.post(s_url,
		jQuery('#' + 'data_form').serialize(),
		function (result) {

			var j = result;

			// Show the result
			jQuery('insurance_request').html(j['html']);

		}, 'json');
}

// Send email to promotion@freelance.de to communicate the user participates in drawing
function send_confirmation_email(s_url, s_email_content, s_div_id)
{
	jQuery.post(s_url,
		'action=send_confirmation_email&email_content=' + s_email_content,
		function (result) {
			var j = result;

			// Show the result
			jQuery(s_div_id).html(j);
		});
}

//Send email to haftpflicht@freelance.de with user's text
function send_liability_email(s_url, s_name, s_area, s_content, s_div_id)
{
	jQuery.post(s_url,
		'action=send_liability_email&name=' + s_name + '&area=' + s_area + '&content=' + s_content,
		function (result) {
			var j = result;

			jQuery(s_div_id).html(j);
		}, 'json');
}

//Send search service request
function send_search_service_request()
{
	jQuery.post('/promotion/searchservice.php',
		jQuery('#' + 'data_form').serialize(),
		function (result) {

			var j = result;
			jQuery('search_service_request').html(j['html']);

		}, 'json');
}