// Show mail form in profile or project
function mail_display_mail_form(display_in_this_element_id, mail_type, mail_key) {

    jQuery('#' + display_in_this_element_id).show();
    jQuery.post('/mail/create.php',
        {
            action: 'display_mail_form',
            type: mail_type,
            key: mail_key
        },
        function (result) {
            var j = result;

            if (j["error"] == 0) {
                jQuery('#' + display_in_this_element_id).html(j["content"]);
            }
        }, 'json');
}

// Submit the form and show the success box if done
function send_internal_mail() {
    jQuery.post('/mail/create.php',
        'action=send&' + jQuery('#data_form').serialize(),
        function (result) {
            var j = result;
            if (j['error'] == 0) {
                modal.show_message(j["result_message"], null, 'success');
                jQuery('#container_menu_contact_data_views').html(j["html_contact_data_views"]);
            } else {
                modal.update(j["content"]);
            }
        }, 'json');
}

function send_internal_message() {

    var cloned_message = jQuery('.conversation-scroll .conversation-right:last').clone();
    var cloned_message_result;

    if (cloned_message.length) {
        //cloning was successful

        cloned_message.hide();

        //set property for new message
        cloned_message.find('.conversation-body-text').html(jQuery('[name=internal_message_text]').val().replace(/\n/g, "<br />"));
        cloned_message.find('.conversation-body-created').text(''); //reset temporarily created time

        jQuery('.conversation-scroll #last-comment').before(cloned_message);
        cloned_message.fadeIn(700);

        jQuery('.conversation-scroll').animate({
            scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")
        }, 700);

        cloned_message_result = 'ok';

    } else {
        // we couldn't clone message as it's not in thread yet, this happens only when there is no reply from other party
        cloned_message_result = 'failed';

    }

    jQuery.post('/mail/ajax.php',
        'action=send&cloned_message_result=' + cloned_message_result + '&' + jQuery('#dialog_internal_message').serialize(),
        function (result) {

            var j = result;
            if (j['error'] == 0) {

                jQuery('[name=internal_message_text]').val('');

                if (cloned_message_result == 'ok') {
                    cloned_message.find('.conversation-body-created').html(j['created']);
                    cloned_message.attr('id', 'message_single_' + j['message_id']);
                } else {
                    // we couldn't clone message as it's not in thread yet, this happens only when there is no reply from other party
                    jQuery('.app-converstation').html(j['html_response']);
                    jQuery('.conversation-scroll').animate({
                        scrollTop: jQuery('.conversation-scroll').prop("scrollHeight")
                    }, 700);
                }
            } else {
                modal.show_message(j['error']['internal_message_text'][0], null, "error");
                cloned_message.hide();
            }

        }, 'json');
}


/**
 * shows a mail item in the mail display pane
 * and automatically marks it as read
 */
function mail_show_mail_in_pane(id) {

    // Unselect all selected mail items
    jQuery(".mail-item.selected").removeClass("selected");


    // Select the mail item which is being shown
    jQuery("#mailitem_" + id).removeClass("mail-item-unread");
    jQuery("#mailitem_" + id).addClass("selected");


    // get the mail text
    jQuery('#message-div').load('/mail/ajax.php?action=get_mail_content&mail_id=' + id,
        function () {
            jQuery('#message-div').show();
            mail_update_number_of_unread_mails();
        });

}


/**
 * save mail settings to the database
 */
function mail_save_settings() {
    jQuery('#marker_settings').load('/mail/settings.php?action=save&' + jQuery('#settings_form').serialize());
}


/**
 * save signature settings to the database
 */
function mail_save_settings_signature() {
    jQuery('#marker_settings_signature').load('/mail/settings.php?action=save_signature&' + jQuery('#settings_signature_form').serialize());
}


/**
 * save mail vacation settings to the database
 */
function mail_save_settings_vacation() {
    jQuery('#marker_settings_autoresponder').load('/mail/settings.php',
        urlParamsToObject('action=save_vacation&' + jQuery('#settings_vacation_form').serialize()));
}


/**
 * updates the number of unread mails in the westnavi
 */
function mail_update_number_of_unread_mails() {
    jQuery('.internal-mail-counter').load('/mail/ajax.php?action=get_number_of_unread_mails');
}


function sleep(ms) {
    var zeit = (new Date()).getTime();
    var stoppZeit = zeit + ms;
    while ((new Date()).getTime() < stoppZeit) {
    }
}


/**
 * saves a draft into the database and updates the draft_id field
 */
//@todo check it
function mail_start_update_draft() {

    // only do this if $('mailtext') exists, prevents errors
    if (jQuery('#mailtext').length) {
        new Ajax.PeriodicalUpdater('dummy', '/mail/ajax.php?action=do_nothing', {
            frequency: 120,
            function(r) {
                mail_update_draft();
            }
        });
    }
}


/**
 * saves a draft into the database and updates the draft_id field
 */
function mail_update_draft() {
    jQuery.post('/mail/ajax.php',
        {
            action: 'save_draft',
            receiver: jQuery('#receiver').val(),
            subject: jQuery('#subject').val(),
            mailtext: jQuery('#mailtext').val(),
            draft_id: jQuery('#draft_id').val()
        },
        function (r) {
            jQuery('#draft_id').val(r);
        });
}


function confirm_delete_single_mail(id) {
    var button = {label: 'Ja »', onclick: 'delete_single_mail(' + id + '); modal.hide();'};
    modal.show_message($T('internal_mail:question_delete_mail'), '1000000', 'info', 'Nein »', button);
}

function delete_single_mail(id) {

    var mail_items = [id];

    jQuery.post('/mail/ajax.php',
        {
            action: 'move_to_trash',
            list_of_mails: JSON.stringify({paramIds: mail_items})
        },
        function (transport) {
            mail_update_number_of_unread_mails();

            if (jQuery('#checkbox-internal-mail').length > 0) {
                // we are in mail/index
                jQuery('#container_single_' + id).parent().parent().hide();
            } else {
                // we are in mail/dialog
                window.location = '/mail/index.php';
            }
        });
}


function confirm_destroy_single_mail(id) {
    var button = {label: 'Ja »', onclick: 'destroy_single_mail(' + id + '); modal.hide();'};
    modal.show_message('Möchten Sie die ausgewählte Nachricht dauerhaft löschen?', '1000000', 'info', 'Nein »', button);
}

function destroy_single_mail(id) {

    var mail_items = [id];

    jQuery.post('/mail/ajax.php',
        {
            action: 'destroy',
            list_of_mails: JSON.stringify({paramIds: mail_items})
        },
        function (transport) {
            mail_update_number_of_unread_mails();
            jQuery('#container_single_' + id).parent().parent().hide();
        });
}

function confirm_restore_single_mail(id) {
    var button = {label: 'Ja »', onclick: 'restore_single_mail(' + id + '); modal.hide();'};
    modal.show_message('Möchten Sie die ausgewählte Nachricht wiederherstellen?', '1000000', 'info', 'Nein »', button);
}

function restore_single_mail(id) {

    var mail_items = [id];
    jQuery.post('/mail/ajax.php',
        {
            action: 'move_from_trash',
            list_of_mails: JSON.stringify({paramIds: mail_items})
        },
        function (transport) {

            if (jQuery('#checkbox-internal-mail').length > 0) {
                jQuery('#container_single_' + id).parent().parent().hide();
            } else {
                window.location = '/mail/index.php';
            }
        });
}

function toggle_favorite_single_mail(id) {

    var mail_items = [id];
    jQuery.post('/mail/ajax.php',
        {
            action: 'toggle_favorite_single_mail',
            list_of_mails: JSON.stringify({paramIds: mail_items})
        },
        function (transport) {
            jQuery('#container_single_' + id + ' .action-icons span[data-original-title=Markieren] .fa-bookmark').toggleClass('bookmarked');
            if (jQuery('#favorite').hasClass('active') && jQuery('#checkbox-internal-mail').length > 0) {
                jQuery('#container_single_' + id).parent().parent().hide();
            }
        });
}


function get_internal_mails_js(category, offset) {

    category = typeof category !== 'undefined' ? category : '';
    offset = typeof offset !== 'undefined' ? offset : 0;

    let sort_by = jQuery('select[name=internal_message_sort]').val();
    sort_by = typeof sort_by !== 'undefined' ? sort_by : 'latest_message';

    jQuery.post('/mail/ajax.php',
        {
            action: 'get_internal_mails_js',
            category: category,
            offset: offset,
            sort_by: sort_by
        },
        function (transport) {
            var r = transport;

            if (r["error"] == 0) {
                // modal.show_message('Here goes your search result', null, 'success');
                jQuery('#container_all').html(r['html_response']);
                jQuery('#pagination').html(r['html_pagination']);
                jQuery('#container_bulk_action').html(r['html_bulk_action']);

                jQuery('#toggle_bulk_actions #checkbox-internal-mail').selectpicker('refresh');
                jQuery('#container_bulk_action').get(0).scrollIntoView();
            }
        }, 'json');
}


function internal_mail_bulk_action(name) {

    var action_value = jQuery('#' + name).val();
    var checkboxes = [];
    var i;
    //Get all checkboxes
    jQuery('.' + name + ':checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (action_value == "mark_as_unread" && checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'mark_as_unread',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {

                mail_update_number_of_unread_mails();

                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#internal_mail_title_' + checkboxes[i]).attr('class', 'unread');
                }
            });
    }

    if (action_value == "mark_as_read" && checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'mark_as_read',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {

                mail_update_number_of_unread_mails();

                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#internal_mail_title_' + checkboxes[i]).attr('class', '');

                }
            });
    }

    if (action_value == "mark_as_favorite" && checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'toggle_favorite_single_mail',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {

                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#container_single_' + checkboxes[i] + ' .action-icons span[data-original-title=Markieren] .fa-bookmark').toggleClass('bookmarked');
                }
            });
    }


    if (action_value == "mark_as_deleted") {

        var button = {label: "löschen", onclick: "mark_as_deleted_and_perform_delete_mail('checkbox-internal-mail'); modal.hide();"};
        modal.show_message('Möchten Sie die Auswahl wirklich löschen?', 1000000, 'info', 'Abbrechen', button);

    } else if (action_value == "mark_as_restore") {

        var button = {label: "wiederherstellen", onclick: "mark_as_restored_and_perform_restore_mail('checkbox-internal-mail'); modal.hide();"};
        modal.show_message('Möchten Sie die Auswahl dauerhaft wiederherstellen?', 1000000, 'info', 'Abbrechen', button);

    } else if (action_value == "mark_as_destroy") {

        var button = {label: "endgültig löschen", onclick: "mark_as_destroyed_and_perform_destroy_mail('checkbox-internal-mail'); modal.hide();"};
        modal.show_message('Möchten Sie die Auswahl dauerhaft endgültig löschen?', 1000000, 'info', 'Abbrechen', button);
    }

    // reset the action select box
    jQuery('#' + name).selectpicker('val', '');

}

function mark_as_deleted_and_perform_delete_mail(name) {

    var checkboxes = [];
    var i;

    //Get all checkboxes
    jQuery('.' + name + ':checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'move_to_trash',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {
                mail_update_number_of_unread_mails();

                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#container_single_' + checkboxes[i]).parent().parent().hide();
                }
            });
    }
}


function mark_as_restored_and_perform_restore_mail(name) {

    var checkboxes = [];
    var i;

    //Get all checkboxes
    jQuery('.' + name + ':checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'move_from_trash',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {
                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#container_single_' + checkboxes[i]).parent().parent().hide();
                }
            });
    }
}


function mark_as_destroyed_and_perform_destroy_mail(name) {

    var checkboxes = [];
    var i;

    //Get all checkboxes
    jQuery('.' + name + ':checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0) {

        jQuery.post('/mail/ajax.php',
            {
                action: 'destroy',
                list_of_mails: JSON.stringify({paramIds: checkboxes})
            },
            function (transport) {
                for (i = 0; i < checkboxes.length; i++) {
                    jQuery('#container_single_' + checkboxes[i]).parent().parent().hide();
                }
            });
    }
}


function enable_disable_bulk_actions_internal_mail() {

    var checkboxes = [];
    jQuery('.checkbox-internal-mail:checked').each(function (index) {
        checkboxes.push(jQuery(this).val());
    });

    if (checkboxes.length > 0) {
        jQuery('#toggle_bulk_actions #checkbox-internal-mail').prop('disabled', false);
        jQuery('#toggle_bulk_actions #checkbox-internal-mail').selectpicker('refresh');
        jQuery('#toggle_bulk_actions button').prop('disabled', false);

    } else {
        jQuery('#toggle_bulk_actions #checkbox-internal-mail').prop('disabled', true);
        jQuery('#toggle_bulk_actions #checkbox-internal-mail').selectpicker('refresh');
        jQuery('#toggle_bulk_actions button').prop('disabled', true);
    }

}

window.addEventListener("load", function () {

    jQuery('#container_all').on("click", ".checkbox-internal-mail", function (event) {

        //single checkbox click
        enable_disable_bulk_actions_internal_mail();

    });


    jQuery('#container_bulk_action').on("click", "#internal_mail_bulk", function () {

        if (jQuery(this).prop('checked') == true) {
            jQuery('.checkbox-internal-mail').prop('checked', true);
        } else {
            jQuery('.checkbox-internal-mail').prop('checked', false);
        }
        enable_disable_bulk_actions_internal_mail();
    });
});