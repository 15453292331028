function freelancer_references_load() {
    jQuery('#panel_references_ajax').load('/profile_freelancer/edit/references.php', {action: 'load'}, function() {
        flScrollTo('panel_references');
    });
}

function toggle_more_references() {
    jQuery('#additional_references').toggle();
    jQuery('#show_additional_references').toggle();
    jQuery('#hide_additional_references').toggle();
}

function toggle_more_project_references() {
    jQuery('#additional_project_references').toggle();
    jQuery('#show_additional_project_references').toggle();
    jQuery('#hide_additional_project_references').toggle();
}

function reference_save() {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit/references.php',
        data: {
            action: 'save_reference',
            form: jQuery('#form_project_experience').serialize(),
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
                jQuery('#panel_references_ajax').html(json['result']['experience']);
                jQuery('#header_experience_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_experience"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
                show_confirm_reference(json['result']['reference_id'], 1, json['result']['profile_complete']);
            } else {
                jQuery('#project_experience').html(json['result']);
            }

            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function reference_open(reference_id) {
    jQuery.ajax({
        url: '/profile_freelancer/edit/references.php',
        data: {
            action: 'open_reference',
            id: reference_id,
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
            }

            jQuery('#project_experience').html(json['result']);
            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function add_reference() {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit/references.php',
        data: {
            action: 'add'
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_references_ajax').html(json['result_html']);
            flScrollTo('panel_references');
        }
    });
}

function edit_reference(id) {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit/references.php',
        data: {
            action: 'edit',
            id: id
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_references_ajax').html(json['result_html']);
            flScrollTo('panel_references');
        }
    });
}

function no_reference() {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit/references.php',
        data: {
            action: 'no_reference'
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_references').collapse('hide');
            jQuery('#panel_references_ajax').html(json['result_html']);

            freelancer_form_save('panel_references', json['profile_complete']);
        }
    });
}

function save_reference(form) {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit/references.php',
        data: jQuery(form).serialize(),
        dataType: 'json',
        success: function (json) {
            if (json['error'] === 1) {
                jQuery('#panel_references_ajax').html(json['result_html']);
            } else {
                jQuery('#profile_edit_header').html(json["profile_edit_header"]);

                if (json["reference_id"] > 0) {
                    show_confirm_reference(json["reference_id"], 1, json['profile_complete']);
                } else {
                    jQuery('#panel_references_ajax').html(json['result_html']);
                    flScrollTo('panel_references');

                    freelancer_form_save('panel_references', json['profile_complete']);
                }
            }
        }
    });
}

function without_confirmation(profile_complete) {
    freelancer_references_load();

    freelancer_form_save('panel_references', profile_complete);
}

function show_confirm_reference(id, save_without_button, profile_complete) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=confirm_reference&reference_id=' + id + '&save_without_button=' + save_without_button + '&profile_complete=' + profile_complete + '&active_tab=request',
        'Berufliche Station bestätigen lassen',
        ''
    );
}

function confirm_reference(id, profile_complete) {
    var formData = new FormData(jQuery('#reference_confirmation_form-' + id)[0]);

    jQuery.ajax({
        url: '/profile_freelancer/edit/references.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 1) {
                modal.update(json['result_html']);
            } else {
                if (typeof json['result_message'] != 'undefined' && json['result_message'] !== '') {
                    modal.show_message(json['result_message'], 5000, 'success');
                } else if (typeof json['message'] != 'undefined' && json['message'] !== '') {
                    modal.show_message(json['message'], 5000, 'success');
                }
                jQuery('#panel_references_ajax').html(json['result_html']);
                flScrollTo('panel_references');

                //freelancer_form_save('panel_references', profile_complete, json['result_message']);
            }
        }
    });
}

function delete_reference_confirmation_popup(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=confirm_delete&reference_id=' + id,
        'Löschung bestätigen',
        '');
}

function delete_reference(id) {
    jQuery.ajax({
        url: '/profile_freelancer/edit/references.php',
        type: 'POST',
        data: {
            action: 'delete',
            id: id
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                jQuery('#panel_references_ajax').html(json['result']['experience']);
                jQuery('#header_experience_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_experience"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);

                //jQuery('#reference_' + id).remove();
                modal.hide();
            } else {
                modal.show_message(json['message'], 5000, 'error');
            }
        }
    });
}

function add_reference_document(id) {
    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: {
            action: 'add',
            reference_id: id
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#panel_references_ajax').html(json['result_html']);
            flScrollTo('panel_references');
        }
    });
}

function upload_reference_document() {
    var formData = new FormData(jQuery('#document_upload-form')[0]);

    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error_code'] === 1) {
                jQuery('#document_upload_form').html(json['result']['error_html']);
            } else {
                jQuery('#panel_references_ajax').html(json['result']['result_html']);
                modal.show_message(json['result']['result_message'], 5000, 'success');
            }
        }
    });
}

function save_reference_document(id) {

    var formData = new FormData(jQuery('#edit_document_' + id)[0]);

    jQuery.ajax({
        url: '/profile_freelancer/documents.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === 0) {
                modal.show_message(json['result_message'], 5000, 'success');
                jQuery('#reference_document_' + id).html(json['result_html']);
            } else if (json['error'] === 1) {
                jQuery('#reference_document_' + id).html(json['result_html']);
                jQuery('#edit_document_' + id).show();
            } else if (json['error'] === 2) {
                jQuery('#alert_area-document_' + id).html(json['result_html']).show();
            }
        }
    });
}

function show_reference_detail(element) {
    var id = element.dataset.id;
    var position = element.dataset.position;
    var company_name = element.dataset.companyName;

    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=reference_detail&reference_id=' + id,
        position + ' | ' + company_name
    );
}

function show_contract_company_name_input(element) {
    if (element.value === 'agency') {
        jQuery('#contract_company_name').show();
    } else {
        jQuery('#contract_company_name').hide();
    }
}

function toggle_input_useable(checkbox_object_name, input_object_name) {
    var checkbox_object = jQuery('#' + checkbox_object_name);
    var input_object = jQuery('#' + input_object_name);

    if (checkbox_object.is(':checked')) {
        input_object.prop("disabled", true);
        input_object.addClass('disabled');
        input_object.css('background-color', '#f2f2f2');
        input_object.css('color', '#333333');
    } else {
        input_object.prop("disabled", false);
        input_object.removeClass('disabled');
        input_object.css('background-color', '#fff');
        input_object.css('color', '#333333');
    }
}

function toggle_input_visable(checkbox_object_name, input_object_name) {
    var checkbox_object = jQuery('#' + checkbox_object_name);
    var input_object = jQuery('#' + input_object_name);

    if (checkbox_object.is(':checked')) {
        input_object.show();
    } else {
        input_object.hide();
    }
}