function reset_field_values(a_selectors) {
	a_selectors.forEach(function(element) {
		jQuery(element).val('');
	});
}

function showPhoneCode(el) {
	var country_id = jQuery(el).val();
	jQuery.ajax({
		type: 'POST',
		url: '/ajax.php',
		data: {
			action: 'get_phone_code',
			country_id: country_id
		},
		dataType: 'json',
		success: function (json) {
			jQuery('#register_phone').val(json["phone_code"]);
		},
		error: function () {
			jQuery('#register_phone').val("");
		}
	});
}