class FL_PasswordStrengthMeter {
    inputElement;
    meterContainer;
    meterElement;
    infoElement;
    score = 0;
    classification = null;
    settings = {
        callback: {
            beforeMeasure: (score, classification) => {},
            afterMeasure: (score, classification) => {}
        },
        use: {
            meter: false,
            info: false
        },
        score: {
            character: 1,
            lowerCase: 3,
            upperCase: 3,
            special: 4,
            digit: 4
        },
        regex: {
            lowerCase: /[a-z]/,
            upperCase: /[A-Z]/,
            digit: /\d/,
            special: /[^a-zA-Z\d\s]/
        },
        classification: [
            {name: "weak", color:"#D53333"},
            {name: "medium", color:"#EB643D", min: 12},
            {name: "good", color:"#FFBE0B", min: 18},
            {name: "strong", color:"#698E49", min: 22},
        ]
    }
    animations = [];
    running = false;

    constructor(passwordInputId,meterContainerId,infoElementId) {
        try {
            this.inputElement = document.getElementById(passwordInputId);
            if (this.inputElement) {

                this.meterContainer = document.getElementById(meterContainerId);

                if (this.meterContainer) {
                    this.meterElement = document.createElement("div");
                    this.meterContainer.appendChild(this.meterElement);

                    if (this.meterElement) {
                        this.meterElement.style.height = "100%"
                        this.settings.use.meter = true;
                    }
                }

                if (infoElementId) {
                    this.infoElement = document.getElementById(infoElementId);
                    if (this.infoElement) {
                        this.settings.use.info = true;
                    }
                }

                this.inputElement.onkeyup = this.passwordChange;
                this.checkClassifications();
                this.show();
            }
        } catch (error) {
            this.error(error);
        }
    }

    measure = () => {
        try {
            let password = this.inputElement.value;

            // measure length of the string and take into account unique characters in the string
            // basic score is weighted average of length of password and number of unique characters
            // the number of unique characters has more weight (3) than the length of the password (1)
            // String.prototype.concat(...new Set(password)).length returns number of unique characters in this password
            this.score = this.settings.score.character*Math.floor((3*String.prototype.concat(...new Set(password)).length + password.length)/4);

            // add additional score for every type of character in this password
            if(password.match(this.settings.regex.lowerCase)) this.score += this.settings.score.lowerCase;
            if(password.match(this.settings.regex.upperCase)) this.score += this.settings.score.upperCase;
            if(password.match(this.settings.regex.digit)) this.score += this.settings.score.digit;
            if(password.match(this.settings.regex.special)) this.score += this.settings.score.special;

        } catch (error) {
            this.error(error);
        }
    }

    classify = () => {
        try {
            for (let i in this.settings.classification) {
                if (this.score >= this.settings.classification[i].min) {
                    this.classification = this.settings.classification[i];
                }
            }
        } catch (error) {
            this.error(error);
        }
    }

    show = () => {
        try {
            if (this.settings.use.meter && this.meterElement) {
                let newWidth = this.classification.width;
                if (this.score === 0) {
                    newWidth = "0%";
                }

                let newColor = this.classification.color;

                let oldWith = this.meterElement.style.width
                let oldColor = this.meterElement.style.backgroundColor
                if (this.animations.length > 0) {
                    let animation = this.animations[this.animations.length - 1]
                    oldWith = animation[animation.length - 1].width;
                    oldColor = animation[animation.length - 1].backgroundColor;
                }

                if (oldWith !== newWidth || oldColor !== newColor) {
                    this.meterElement.style.backgroundColor = newColor;
                    this.meterElement.style.width = newWidth;

                    this.animations.push([
                        {width: oldWith, backgroundColor: oldColor},
                        {width: newWidth, backgroundColor: newColor}
                    ]);

                    this.runAnimations();
                }
            }

            if (this.settings.use.info && this.infoElement) {
                this.infoElement.textContent = this.classification.name
            }
        } catch (error) {
            this.error(error);
        }
    }

    passwordChange = () => {
        try {
            this.settings.callback.beforeMeasure(this.score,this.classification);
            this.measure();
            this.classify();
            this.show();
            this.settings.callback.afterMeasure(this.score,this.classification);
        } catch (error) {
            this.error(error);
        }
    }

    setSetting = (type, object) => {
        try {
            if (typeof this.settings[type] != "undefined" && type !== "classification") {
                if (typeof object == "object") {
                    for (let i in this.settings[type]) {
                        if (typeof object[i] === typeof this.settings[type][i]) {
                            this.settings[type][i] = object[i];
                        }
                    }
                }
            }
        } catch (error) {
            this.error(error);
        }
    }

    getCurrentClassification = () => {
        return this.classification
    }

    getClassifications = () => {
        try {
            return this.settings.classification;
        } catch (error) {
            this.error(error);
        }
    }

    addClassification = (name, minimalScore, color, resetClassifications) => {
        try {
            if (resetClassifications === true) {
                this.settings.classification = []
            }
            if (typeof name == "string" && Number.isInteger(minimalScore) && this.isColor(color)) {
                this.removeClassificationsWithoutCheck(name);
                this.settings.classification.push({name: name, min: minimalScore, color: color});
                this.checkClassifications();
            }
        } catch (error) {
            this.error(error);
        }
    }

    removeClassifications = (name) => {
        try {
            this.removeClassificationsWithoutCheck(name);
            this.checkClassifications();
        } catch (error) {
            this.error(error);
        }
    }

    runAnimations = () => {
        try {
            if (this.settings.use.meter && this.animations.length > 0 && !this.running) {
                this.running = true;
                let animation = this.animations.shift()
                let self = this;
                this.meterElement.animate(animation, {duration: 500}).onfinish = function () {
                    self.running = false;
                    self.runAnimations();
                };
            }
        } catch (error) {
            this.error(error);
        }
    }

    removeClassificationsWithoutCheck = (name) => {
        try {
            this.settings.classification = this.settings.classification.filter(item => item.name !== name);
        } catch (error) {
            this.error(error);
        }
    }

    checkClassifications = () => {
        try {
            if (!(Array.isArray(this.settings.classification) && this.settings.classification.length > 0)) {
                this.settings.classification = [{}];
            }

            for (let i in this.settings.classification) {
                if (typeof this.settings.classification[i] != "object") {
                    this.settings.classification[i] = {};
                }
                if (!Number.isInteger(this.settings.classification[i].min)) {
                    this.settings.classification[i].min = 0;
                }
                if (typeof this.settings.classification[i].name != "string") {
                    this.settings.classification[i].name = "";
                }
                if (!this.isColor(this.settings.classification[i].color)) {
                    this.settings.classification[i].color = "#ffffff";
                }
            }

            this.settings.classification.sort(function (a ,b) {
                if (a.min < b.min) {
                    return -1;
                }
                if (a.min > b.min) {
                    return 1;
                }
                return 0;
            });

            this.settings.classification[0].min = 0;

            let score = 0;
            for (let i in this.settings.classification) {
                if (this.settings.classification[i].min <= score) {
                    this.settings.classification[i].min = score;
                    score++;
                } else {
                    score = this.settings.classification[i].min;
                }
                this.settings.classification[i].width = Math.ceil(100*((parseInt(i)+1)/this.settings.classification.length))+"%";
            }

            this.classification = this.settings.classification[0];
        } catch (error) {
            this.error(error)
        }
    }

    isColor = (color) => {
        try {
            let s = new Option().style;
            s.color = color;
            return /^#[0-9A-Fa-f]{6}$/i.test(color) || s.color === color;
        } catch (error) {
            this.error(error)
        }
    }

    error = (error) => {
        console.log(error);
    }
}